import { IMAGE_PROXY_URL } from './constants';

export interface ImageConversionArgs {
  blur?: number;
  dark?: number;
  width?: number;
  url?: string | null;
}

const FALLBACK_IMAGE = 'https://www.tv4.se/imageproxy-fallback.png';

const ImageProxy = {
  url: function ({ blur, dark, url, width }: ImageConversionArgs) {
    const imageUrl = url || FALLBACK_IMAGE;

    // 1) If it starts with "//", add "https:"
    const protocolFixed = imageUrl.match(/^\/\//) ? 'https:' + imageUrl : imageUrl;

    const encoded = encodeURI(protocolFixed);

    const finalURL = new URL(IMAGE_PROXY_URL);

    finalURL.searchParams.set('source', encoded);

    if (width !== undefined) finalURL.searchParams.set('width', String(width));
    if (blur !== undefined) finalURL.searchParams.set('blur', String(blur));
    if (dark !== undefined) finalURL.searchParams.set('dark', String(dark));

    return finalURL.toString();
  },
};

export { ImageProxy };
