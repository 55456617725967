import styled from 'styled-components';

import { type LinkFieldsFragment } from '@news/gql';
import { getYear } from '@news/lib';
import { GroupId, appendUtmTags } from '@news/tracking';

import { Typography } from 'components/Typography';
import { ScreenReader } from 'components/common';
import { BigArrow, Tv4LogoIcon } from 'components/icons';
import { GenericLink } from 'components/menu/linkHelpers';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import { TEST_ID } from 'lib/constants';
import type { StaticEntry } from 'shared/types';

import { BackToTopButton, EndSection, EndSectionContent, LogoWrapper, StyledFooter, StyledLink } from './style';

const footerLinks: (LinkFieldsFragment | StaticEntry)[] = [
  {
    id: 'archive',
    linkId: 'archive',
    __typename: 'Link',
    title: 'Arkiv',
    href: '/arkiv',
    targetType: 'InternalLink',
    icon: null,
  },
  {
    id: 'all-feeds',
    linkId: 'all-feeds',
    __typename: 'Link',
    title: 'Alla flöden',
    href: '/alla-floden',
    targetType: 'InternalLink',
    icon: null,
  },
  {
    __typename: 'ReactNode',
    id: 'ansvariga-utgivare',
    element: (
      <StyledLink
        className="grid-item responsible"
        href={appendUtmTags(
          'https://www.tv4play.se/faq#humany-tv4play=/1179/ansvariga-utgivare-foer-innehall-pa-tv4-play&humany-tv4-play=/g850-ansvariga-utgivare-foer-innehall-pa-tv4-play-och-tv4-se;phrase:ansvariga%20utgivare;take:10',
          'Link',
          false
        )}
        target="_blank"
      >
        Ansvariga utgivare
      </StyledLink>
    ),
  },
];

function Footer() {
  const currentRoute = useCurrentRoute();
  const paths = ['/korthet'];

  if (paths.some((path) => currentRoute.path.includes(path))) {
    return null;
  }

  const companyInfo = `© ${getYear()} TV4 | Org. nr. 556242-7152`;

  return (
    <StyledFooter as="footer" variant="body1" data-testid={`${TEST_ID.footer}`}>
      <ScreenReader as="span">Sidfot</ScreenReader>
      <EndSection>
        <LogoWrapper>
          <Tv4LogoIcon size={80} />
        </LogoWrapper>
        <EndSectionContent>
          <ul>
            {footerLinks.map((link, index) => (
              <li key={`footer-link-${link.id}`}>
                <GenericLink link={link} trackingName={GroupId.Footer} trackingIndex={index} />
              </li>
            ))}
          </ul>
          <Typography variant="body1" className="grid-item">
            {companyInfo}
          </Typography>
          <BackToTopButton
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="grid-item"
            aria-label="Tillbaka till toppen"
          >
            <span>Tillbaka till toppen </span>
            <span aria-hidden="true">
              <StyledBigArrow />
            </span>
          </BackToTopButton>
        </EndSectionContent>
      </EndSection>
    </StyledFooter>
  );
}

export { Footer };

const StyledBigArrow = styled(BigArrow)`
  transform: rotate(-45deg);
`;
