import { type SizeAndColor, cssSize } from './iconUtils';

export const ShareArrowIcon = ({ size = 24, color = 'currentColor' }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="share">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14H8.3492C6.13639 14 4.10506 15.1781 3 17.0671C2.91786 17.2076 2.84083 17.3519 2.76923 17.5H1.5V15C1.5 10.1972 5.262 6.2731 10 6.01365V2L18.5796 9.6263C18.8033 9.82519 18.8033 10.1748 18.5796 10.3737L10 18V14ZM11.5 14.6597V12.5H8.3492C6.32253 12.5 4.41842 13.2957 3.00782 14.6544C3.18218 10.8114 6.24925 7.72128 10.082 7.51141L11.5 7.43376V5.34027L16.7422 10L11.5 14.6597Z"
        fill={color}
      />
    </g>
  </svg>
);
