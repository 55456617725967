import { type SizeAndColor, cssSize } from './iconUtils';

export const UnMuteIcon = ({ size = 24, color = 'currentColor' }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="un-mute">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0702 3.5155L19.7772 4.22272C21.8394 6.28552 22.9979 9.08291 22.9979 11.9997C22.9979 14.9165 21.8394 17.7139 19.7772 19.7767L19.0702 20.4839L17.6558 19.0699L18.3628 18.3627C20.05 16.675 20.9979 14.3862 20.9979 11.9997C20.9979 9.61324 20.05 7.32447 18.3628 5.63672L17.6558 4.9295L19.0702 3.5155ZM11.4332 4.09841C11.7797 4.26493 12 4.61531 12 4.99972V18.9997C12 19.3841 11.7797 19.7345 11.4332 19.901C11.0867 20.0675 10.6755 20.0207 10.3753 19.7806L5.64922 15.9997H2C1.44772 15.9997 1 15.552 1 14.9997V8.99972C1 8.44743 1.44772 7.99972 2 7.99972H5.64922L10.3753 4.21885C10.6755 3.97871 11.0867 3.93189 11.4332 4.09841ZM10 7.08034L6.6247 9.78059C6.44738 9.92244 6.22707 9.99972 6 9.99972H3V13.9997H6C6.22707 13.9997 6.44738 14.077 6.6247 14.2188L10 16.9191V7.08034ZM15.5402 7.0455L16.2472 7.75272C17.372 8.87788 18.0039 10.4037 18.0039 11.9947C18.0039 13.5857 17.372 15.1115 16.2472 16.2367L15.5402 16.9439L14.1258 15.5299L14.8328 14.8227C15.5827 14.0726 16.0039 13.0554 16.0039 11.9947C16.0039 10.9341 15.5827 9.91683 14.8328 9.16672L14.1258 8.4595L15.5402 7.0455Z"
        fill={color}
      />
    </g>
  </svg>
);
