import type { PageEvent } from './page';

export const GroupId = {
  ContextMenu: 'context-menu',
  SortMenu: 'sort-menu',
  BreakingNewsFeed: 'breaking-news-feed',
  BreakingNewsModule: 'breaking-news-module',
  PrimaryTabMenuDesktop: 'primary-tab-menu-desktop',
  PrimaryTabMenuMobile: 'primary-tab-menu-mobile',
  SecondaryTabMenu: 'secondary-tab-menu',
  ProgramMenu: 'program-menu',
  Footer: 'footer',
  EmphasizedArticleGroup: 'emphasized-article-group',
  DrawerMenu(section: number) {
    return `drawer-menu-section-${section}` as const;
  },
  DrawerMenuLinkHierarchy(section: number, index: number) {
    return `drawer-menu-section-${section}-${index}` as const;
  },
  FrequentlyAskedQuestions(id: string) {
    return `frequently-asked-questions-${id}` as const;
  },
  RelatedArticle(id: string) {
    return `related-article-${id}` as const;
  },
  Feed(id: string) {
    return `feed-${id}` as const;
  },
  MediaSlider(id: string) {
    return `media-slider-${id}` as const;
  },
} as const;

export type TGroupId = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof typeof GroupId]: (typeof GroupId)[K] extends (...args: any[]) => infer R ? R : (typeof GroupId)[K];
}[keyof typeof GroupId];

export const StaticItemId = {
  Logo: 'logo',
  RedBanner: 'red-banner',
  RouterLink(group: string, index: number) {
    return `router-link-${group}-${index}`;
  },
};

export type ItemGroupData = {
  /** If this is part of a group of items fill with the group ID, e.g. `drawer_menu` or `footer_links */
  item_group_id: TGroupId | null;
  /** The index for the item within the group it belongs to */
  item_group_index: string | null;
};

export type ItemData = ItemGroupData & {
  /** If available, the ID for this item. Could also be a static ID like `menu_logo` */
  item_id: string;
  /** Type of item, e.g. `article`, `related_article`, `video_asset`, can usually be fetched by __typename */
  item_type: string;
  /** Title of the entity, as displayed to the user */
  item_title: string | null;
  /** If this is an external link, e.g. to tv4play.se, this should be filled with the URL */
  item_target_url: string | null;
  /** This is the tags (feeds) that this entry belongs to */
  item_tags: string | null;
};

export type ItemEvent = Omit<PageEvent, 'event'> &
  ItemData & {
    event: 'item_click' | 'item_impression';
  };
