import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ArticleGroupContentItem: ['ArticleGroupArticle', 'ArticleGroupBlock'],
    ContextMenuItem: ['ExternalLink', 'FeedLink', 'InternalLink'],
    FeaturedArticleAsset: ['Image', 'VideoPlayerData'],
    FeedItem: [
      'Article',
      'ArticleGroup',
      'BreakingNewsModule',
      'LiveReport',
      'MediaSlider',
      'ShortsSlider',
      'SportResultsModule',
      'Upsell',
      'VideoAsset',
      'VideoPlayerData',
    ],
    LinkGroupItem: ['Link', 'LinkHierarchy'],
    LinkedNewsFeedItem: ['Article', 'VideoAsset', 'VideoPlayerData'],
    NewsFeedItem: [
      'Article',
      'ArticleGroup',
      'BreakingNewsModule',
      'LiveReport',
      'MediaSlider',
      'ShortsSlider',
      'SportResultsModule',
      'Upsell',
      'VideoAsset',
    ],
    NewsFeedItemType: ['Article', 'VideoAsset'],
    NodeDataUnion: [
      'BalanceOfPowerData',
      'EditorialBannerData',
      'ExtendedVideoAssetData',
      'FactBoxData',
      'HyperLinkData',
      'ImageData',
      'ImageSliderData',
      'PollData',
      'SportStandingsTableData',
      'VideoPlayerData',
      'YouTubeData',
    ],
    Related: ['RelatedArticle', 'VideoPlayerData'],
    SportResultsByLeague: ['FootballResults', 'IceHockeyResults'],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
};

export enum AdditionReason {
  AutoImported = 'AutoImported',
  CuratedLocked = 'CuratedLocked',
  CuratedNotLocked = 'CuratedNotLocked',
}

export enum AutoPopulateFeedOptions {
  Both = 'Both',
  No = 'No',
  OnlyContentful = 'OnlyContentful',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum Category {
  News = 'News',
  Other = 'Other',
  Person = 'Person',
  Program = 'Program',
}

export enum EventStatus {
  Finished = 'FINISHED',
  Live = 'LIVE',
  Unhandled = 'UNHANDLED',
  Upcoming = 'UPCOMING',
}

export enum FeedItemType {
  Article = 'Article',
  RelatedArticle = 'RelatedArticle',
  VideoAsset = 'VideoAsset',
}

export enum FeedType {
  Category = 'CATEGORY',
  Subcategory = 'SUBCATEGORY',
  Tag = 'TAG',
}

export enum GameLocation {
  Away = 'AWAY',
  Home = 'HOME',
  Total = 'TOTAL',
}

export enum ImagePlacement {
  Left = 'LEFT',
  Top = 'TOP',
}

export enum Layout {
  Column = 'COLUMN',
  Row = 'ROW',
}

export enum League {
  Hockeyallsvenskan = 'HOCKEYALLSVENSKAN',
  Shl = 'SHL',
}

export enum LinkedNewsFeedItemType {
  Article = 'Article',
  VideoAsset = 'VideoAsset',
  VideoPlayerData = 'VideoPlayerData',
}

export enum MatchStatus {
  Active = 'ACTIVE',
  AwaitingKickOff = 'AWAITING_KICK_OFF',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  FinishedConfirmedByOneSource = 'FINISHED_CONFIRMED_BY_ONE_SOURCE',
  FinishedConfirmedByTwoSources = 'FINISHED_CONFIRMED_BY_TWO_SOURCES',
  Interrupted = 'INTERRUPTED',
  NotDecided = 'NOT_DECIDED',
  NotStarted = 'NOT_STARTED',
  Postponed = 'POSTPONED',
  PostponedUndecided = 'POSTPONED_UNDECIDED',
  WalkOver = 'WALK_OVER',
}

export enum MediaPanelItemType {
  Movie = 'MOVIE',
  Series = 'SERIES',
}

export enum Platform {
  All = 'ALL',
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export enum PlatformParameter {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export enum Ratio {
  Ratio_1x1 = 'Ratio_1x1',
  Ratio_2x3 = 'Ratio_2x3',
  Ratio_4x5 = 'Ratio_4x5',
  Ratio_9x16 = 'Ratio_9x16',
  Ratio_16x9 = 'Ratio_16x9',
}

export enum ResultsFilter {
  Finished = 'FINISHED',
  Live = 'LIVE',
  Unhandled = 'UNHANDLED',
  Upcoming = 'UPCOMING',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum VideoAssetType {
  Clip = 'CLIP',
  Episode = 'EPISODE',
}

export type VideoSearchInput = {
  nids: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<Scalars['String']['input']>;
};

export type ArticleFieldsFragment = {
  __typename: 'Article';
  id: string;
  title: string;
  slug: string | null;
  publishedDateTime: string | null;
  humanPublishedDateTime: string | null;
  humanUpdatedDateTime: string | null;
  updatedDateTime: string | null;
  live: boolean | null;
  lead: string | null;
  articleLeadRichText: any | null;
  liveReportId: string | null;
  bylineName: string | null;
  bylineEmail: string | null;
  isOpinionPiece: boolean;
  body: any | null;
  metaTitle: string | null;
  metaDescription: string | null;
  ogTitle: string | null;
  ogDescription: string | null;
  isAdsHidden: boolean;
  isSponsoredArticle: boolean;
  sponsoredByBanner: boolean;
  showPobInFeed: boolean;
  showElectionMap: boolean;
  byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
  featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
  data: Array<{
    __typename: 'NodeData';
    id: string;
    data:
      | { __typename: 'BalanceOfPowerData'; id: string }
      | {
          __typename: 'EditorialBannerData';
          bannerTitle: string;
          bannerText: string;
          buttonText: string;
          buttonLink: string;
          bannerImage: {
            __typename: 'EditorialBannerImageAsset';
            title: string | null;
            description: string | null;
            url: string;
          } | null;
        }
      | {
          __typename: 'ExtendedVideoAssetData';
          headline: string | null;
          text: string | null;
          isQuote: boolean | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'FactBoxData';
          title: string | null;
          body: any | null;
          data: Array<{
            __typename: 'NodeData';
            id: string;
            data:
              | { __typename: 'BalanceOfPowerData' }
              | {
                  __typename: 'EditorialBannerData';
                  bannerTitle: string;
                  bannerText: string;
                  buttonText: string;
                  buttonLink: string;
                  bannerImage: {
                    __typename: 'EditorialBannerImageAsset';
                    title: string | null;
                    description: string | null;
                    url: string;
                  } | null;
                }
              | {
                  __typename: 'ExtendedVideoAssetData';
                  headline: string | null;
                  text: string | null;
                  isQuote: boolean | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | { __typename: 'FactBoxData' }
              | { __typename: 'HyperLinkData'; url: string | null }
              | {
                  __typename: 'ImageData';
                  image: {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  } | null;
                }
              | {
                  __typename: 'ImageSliderData';
                  images: Array<{
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  }>;
                }
              | { __typename: 'PollData'; directLink: string }
              | { __typename: 'SportStandingsTableData' }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  metadataId: string | null;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | {
                  __typename: 'YouTubeData';
                  videoId: string | null;
                  playlistId: string | null;
                  startPositionInSeconds: number | null;
                };
          }>;
        }
      | { __typename: 'HyperLinkData'; url: string | null }
      | {
          __typename: 'ImageData';
          image: {
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          } | null;
        }
      | {
          __typename: 'ImageSliderData';
          images: Array<{
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          }>;
        }
      | { __typename: 'PollData'; directLink: string }
      | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
      | {
          __typename: 'VideoPlayerData';
          id: string;
          metadataId: string | null;
          caption: string | null;
          thumbnail: string | null;
          thumbnailByline: string | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'YouTubeData';
          videoId: string | null;
          playlistId: string | null;
          startPositionInSeconds: number | null;
        };
  }>;
  metadata: {
    __typename: 'ArticleMetadata';
    id: string;
    wordCount: number | null;
    linkCount: number | null;
    imageCount: number | null;
    videoCount: number | null;
  } | null;
  featuredAsset:
    | {
        __typename: 'Image';
        id: string;
        url: string | null;
        byline: string | null;
        caption: string | null;
        ratio: Ratio | null;
        linkUrl: string | null;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | null;
  related: Array<
    | {
        __typename: 'RelatedArticle';
        id: string;
        title: string;
        slug: string | null;
        publishedDateTime: string | null;
        humanPublishedDateTime: string | null;
        humanUpdatedDateTime: string | null;
        updatedDateTime: string | null;
        lead: string | null;
        articleLeadRichText: any | null;
        liveReportId: string | null;
        bylineName: string | null;
        bylineEmail: string | null;
        body: any | null;
        isAdsHidden: boolean;
        isSponsoredArticle: boolean;
        byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
        featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
        metadata: {
          __typename: 'ArticleMetadata';
          id: string;
          wordCount: number | null;
          linkCount: number | null;
          imageCount: number | null;
          videoCount: number | null;
        } | null;
        data: Array<{
          __typename: 'NodeData';
          id: string;
          data:
            | { __typename: 'BalanceOfPowerData'; id: string }
            | {
                __typename: 'EditorialBannerData';
                bannerTitle: string;
                bannerText: string;
                buttonText: string;
                buttonLink: string;
                bannerImage: {
                  __typename: 'EditorialBannerImageAsset';
                  title: string | null;
                  description: string | null;
                  url: string;
                } | null;
              }
            | {
                __typename: 'ExtendedVideoAssetData';
                headline: string | null;
                text: string | null;
                isQuote: boolean | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | {
                __typename: 'FactBoxData';
                title: string | null;
                body: any | null;
                data: Array<{
                  __typename: 'NodeData';
                  id: string;
                  data:
                    | { __typename: 'BalanceOfPowerData' }
                    | {
                        __typename: 'EditorialBannerData';
                        bannerTitle: string;
                        bannerText: string;
                        buttonText: string;
                        buttonLink: string;
                        bannerImage: {
                          __typename: 'EditorialBannerImageAsset';
                          title: string | null;
                          description: string | null;
                          url: string;
                        } | null;
                      }
                    | {
                        __typename: 'ExtendedVideoAssetData';
                        headline: string | null;
                        text: string | null;
                        isQuote: boolean | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | { __typename: 'FactBoxData' }
                    | { __typename: 'HyperLinkData'; url: string | null }
                    | {
                        __typename: 'ImageData';
                        image: {
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        } | null;
                      }
                    | {
                        __typename: 'ImageSliderData';
                        images: Array<{
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        }>;
                      }
                    | { __typename: 'PollData'; directLink: string }
                    | { __typename: 'SportStandingsTableData' }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        metadataId: string | null;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | {
                        __typename: 'YouTubeData';
                        videoId: string | null;
                        playlistId: string | null;
                        startPositionInSeconds: number | null;
                      };
                }>;
              }
            | { __typename: 'HyperLinkData'; url: string | null }
            | {
                __typename: 'ImageData';
                image: {
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  byline: string | null;
                  caption: string | null;
                  ratio: Ratio | null;
                  linkUrl: string | null;
                } | null;
              }
            | {
                __typename: 'ImageSliderData';
                images: Array<{
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  byline: string | null;
                  caption: string | null;
                  ratio: Ratio | null;
                  linkUrl: string | null;
                }>;
              }
            | { __typename: 'PollData'; directLink: string }
            | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | {
                __typename: 'YouTubeData';
                videoId: string | null;
                playlistId: string | null;
                startPositionInSeconds: number | null;
              };
        }>;
        featuredAsset:
          | {
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            }
          | {
              __typename: 'VideoPlayerData';
              id: string;
              metadataId: string | null;
              caption: string | null;
              thumbnail: string | null;
              thumbnailByline: string | null;
              asset: {
                __typename: 'VideoAsset';
                id: string;
                metadataId: string | null;
                slug: string | null;
                title: string;
                description: string | null;
                image: string | null;
                type: string | null;
                duration: number | null;
                humanDuration: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanBroadcastShortDateTime: string | null;
                ratio: Ratio | null;
                live: boolean | null;
                program: { __typename: 'Program'; nid: string } | null;
              } | null;
            }
          | null;
        feedOrigins: Array<{
          __typename: 'FeedOrigin';
          type: FeedType;
          id: string;
          slug: string | null;
          title: string | null;
        }>;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
  >;
  feedOrigins: Array<{
    __typename: 'FeedOrigin';
    type: FeedType;
    id: string;
    slug: string | null;
    title: string | null;
  }>;
};

export type ArticleGroupArticleFieldsFragment = {
  __typename: 'ArticleGroupArticle';
  id: string;
  slug: string | null;
  title: string;
  live: boolean | null;
  lead: string | null;
  publishedDateTime: string | null;
  humanPublishedDateTime: string | null;
  updatedDateTime: string | null;
  humanUpdatedDateTime: string | null;
  liveReportId: string | null;
  bylineName: string | null;
  bylineEmail: string | null;
  isOpinionPiece: boolean;
  isSponsoredArticle: boolean;
  featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
  feedOrigins: Array<{
    __typename: 'FeedOrigin';
    type: FeedType;
    id: string;
    slug: string | null;
    title: string | null;
  }>;
  byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
  featuredAsset:
    | {
        __typename: 'Image';
        id: string;
        url: string | null;
        caption: string | null;
        byline: string | null;
        linkUrl: string | null;
        ratio: Ratio | null;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        title: string;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          title: string;
          slug: string | null;
          description: string | null;
          live: boolean | null;
          type: string | null;
          image: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | null;
};

export type ArticleGroupBlockArticleFieldsFragment = {
  __typename: 'ArticleGroupArticle';
  id: string;
  slug: string | null;
  title: string;
  live: boolean | null;
  lead: string | null;
  publishedDateTime: string | null;
  humanPublishedDateTime: string | null;
  updatedDateTime: string | null;
  humanUpdatedDateTime: string | null;
  liveReportId: string | null;
  bylineName: string | null;
  bylineEmail: string | null;
  isSponsoredArticle: boolean;
  feedOrigins: Array<{
    __typename: 'FeedOrigin';
    type: FeedType;
    id: string;
    slug: string | null;
    title: string | null;
  }>;
  featuredAsset:
    | {
        __typename: 'Image';
        id: string;
        url: string | null;
        caption: string | null;
        byline: string | null;
        linkUrl: string | null;
        ratio: Ratio | null;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        title: string;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          title: string;
          slug: string | null;
          description: string | null;
          live: boolean | null;
          type: string | null;
          image: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | null;
};

export type ArticleGroupFieldsFragment = {
  __typename: 'ArticleGroup';
  id: string;
  title: string;
  isEmphasized: boolean;
  content: Array<
    | {
        __typename: 'ArticleGroupArticle';
        id: string;
        slug: string | null;
        title: string;
        live: boolean | null;
        lead: string | null;
        publishedDateTime: string | null;
        humanPublishedDateTime: string | null;
        updatedDateTime: string | null;
        humanUpdatedDateTime: string | null;
        liveReportId: string | null;
        bylineName: string | null;
        bylineEmail: string | null;
        isOpinionPiece: boolean;
        isSponsoredArticle: boolean;
        featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
        feedOrigins: Array<{
          __typename: 'FeedOrigin';
          type: FeedType;
          id: string;
          slug: string | null;
          title: string | null;
        }>;
        byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
        featuredAsset:
          | {
              __typename: 'Image';
              id: string;
              url: string | null;
              caption: string | null;
              byline: string | null;
              linkUrl: string | null;
              ratio: Ratio | null;
            }
          | {
              __typename: 'VideoPlayerData';
              id: string;
              title: string;
              caption: string | null;
              thumbnail: string | null;
              thumbnailByline: string | null;
              asset: {
                __typename: 'VideoAsset';
                id: string;
                title: string;
                slug: string | null;
                description: string | null;
                live: boolean | null;
                type: string | null;
                image: string | null;
                duration: number | null;
                humanDuration: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanBroadcastShortDateTime: string | null;
                ratio: Ratio | null;
                program: { __typename: 'Program'; nid: string } | null;
              } | null;
            }
          | null;
      }
    | {
        __typename: 'ArticleGroupBlock';
        id: string;
        layout: Layout | null;
        imagePlacement: ImagePlacement | null;
        content: Array<{
          __typename: 'ArticleGroupArticle';
          id: string;
          slug: string | null;
          title: string;
          live: boolean | null;
          lead: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          updatedDateTime: string | null;
          humanUpdatedDateTime: string | null;
          liveReportId: string | null;
          bylineName: string | null;
          bylineEmail: string | null;
          isSponsoredArticle: boolean;
          feedOrigins: Array<{
            __typename: 'FeedOrigin';
            type: FeedType;
            id: string;
            slug: string | null;
            title: string | null;
          }>;
          featuredAsset:
            | {
                __typename: 'Image';
                id: string;
                url: string | null;
                caption: string | null;
                byline: string | null;
                linkUrl: string | null;
                ratio: Ratio | null;
              }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                title: string;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  title: string;
                  slug: string | null;
                  description: string | null;
                  live: boolean | null;
                  type: string | null;
                  image: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | null;
        } | null>;
      }
    | null
  >;
};

export type ArticleInFeedFieldsFragment = {
  __typename: 'Article';
  id: string;
  title: string;
  slug: string | null;
  live: boolean | null;
  lead: string | null;
  liveReportId: string | null;
  isOpinionPiece: boolean;
  metaTitle: string | null;
  metaDescription: string | null;
  ogTitle: string | null;
  ogDescription: string | null;
  isAdsHidden: boolean;
  isSponsoredArticle: boolean;
  showPobInFeed: boolean;
  byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
  featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
  metadata: {
    __typename: 'ArticleMetadata';
    id: string;
    wordCount: number | null;
    linkCount: number | null;
    imageCount: number | null;
    videoCount: number | null;
  } | null;
  featuredAsset:
    | {
        __typename: 'Image';
        id: string;
        url: string | null;
        byline: string | null;
        caption: string | null;
        ratio: Ratio | null;
        linkUrl: string | null;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | null;
  related: Array<
    | {
        __typename: 'RelatedArticle';
        id: string;
        title: string;
        slug: string | null;
        publishedDateTime: string | null;
        humanPublishedDateTime: string | null;
        humanUpdatedDateTime: string | null;
        updatedDateTime: string | null;
        lead: string | null;
        articleLeadRichText: any | null;
        liveReportId: string | null;
        bylineName: string | null;
        bylineEmail: string | null;
        body: any | null;
        isAdsHidden: boolean;
        isSponsoredArticle: boolean;
        byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
        featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
        metadata: {
          __typename: 'ArticleMetadata';
          id: string;
          wordCount: number | null;
          linkCount: number | null;
          imageCount: number | null;
          videoCount: number | null;
        } | null;
        data: Array<{
          __typename: 'NodeData';
          id: string;
          data:
            | { __typename: 'BalanceOfPowerData'; id: string }
            | {
                __typename: 'EditorialBannerData';
                bannerTitle: string;
                bannerText: string;
                buttonText: string;
                buttonLink: string;
                bannerImage: {
                  __typename: 'EditorialBannerImageAsset';
                  title: string | null;
                  description: string | null;
                  url: string;
                } | null;
              }
            | {
                __typename: 'ExtendedVideoAssetData';
                headline: string | null;
                text: string | null;
                isQuote: boolean | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | {
                __typename: 'FactBoxData';
                title: string | null;
                body: any | null;
                data: Array<{
                  __typename: 'NodeData';
                  id: string;
                  data:
                    | { __typename: 'BalanceOfPowerData' }
                    | {
                        __typename: 'EditorialBannerData';
                        bannerTitle: string;
                        bannerText: string;
                        buttonText: string;
                        buttonLink: string;
                        bannerImage: {
                          __typename: 'EditorialBannerImageAsset';
                          title: string | null;
                          description: string | null;
                          url: string;
                        } | null;
                      }
                    | {
                        __typename: 'ExtendedVideoAssetData';
                        headline: string | null;
                        text: string | null;
                        isQuote: boolean | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | { __typename: 'FactBoxData' }
                    | { __typename: 'HyperLinkData'; url: string | null }
                    | {
                        __typename: 'ImageData';
                        image: {
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        } | null;
                      }
                    | {
                        __typename: 'ImageSliderData';
                        images: Array<{
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        }>;
                      }
                    | { __typename: 'PollData'; directLink: string }
                    | { __typename: 'SportStandingsTableData' }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        metadataId: string | null;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | {
                        __typename: 'YouTubeData';
                        videoId: string | null;
                        playlistId: string | null;
                        startPositionInSeconds: number | null;
                      };
                }>;
              }
            | { __typename: 'HyperLinkData'; url: string | null }
            | {
                __typename: 'ImageData';
                image: {
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  byline: string | null;
                  caption: string | null;
                  ratio: Ratio | null;
                  linkUrl: string | null;
                } | null;
              }
            | {
                __typename: 'ImageSliderData';
                images: Array<{
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  byline: string | null;
                  caption: string | null;
                  ratio: Ratio | null;
                  linkUrl: string | null;
                }>;
              }
            | { __typename: 'PollData'; directLink: string }
            | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | {
                __typename: 'YouTubeData';
                videoId: string | null;
                playlistId: string | null;
                startPositionInSeconds: number | null;
              };
        }>;
        featuredAsset:
          | {
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            }
          | {
              __typename: 'VideoPlayerData';
              id: string;
              metadataId: string | null;
              caption: string | null;
              thumbnail: string | null;
              thumbnailByline: string | null;
              asset: {
                __typename: 'VideoAsset';
                id: string;
                metadataId: string | null;
                slug: string | null;
                title: string;
                description: string | null;
                image: string | null;
                type: string | null;
                duration: number | null;
                humanDuration: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanBroadcastShortDateTime: string | null;
                ratio: Ratio | null;
                live: boolean | null;
                program: { __typename: 'Program'; nid: string } | null;
              } | null;
            }
          | null;
        feedOrigins: Array<{
          __typename: 'FeedOrigin';
          type: FeedType;
          id: string;
          slug: string | null;
          title: string | null;
        }>;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
  >;
  feedOrigins: Array<{
    __typename: 'FeedOrigin';
    type: FeedType;
    id: string;
    slug: string | null;
    title: string | null;
  }>;
};

export type ArticleUrlDataFieldsFragment = {
  __typename: 'ArticleUrlData';
  id: string;
  title: string;
  slug: string;
  publishedAt: string;
  updatedAt: string;
};

export type NodeDataFieldsFragment = {
  __typename: 'NodeData';
  id: string;
  data:
    | { __typename: 'BalanceOfPowerData'; id: string }
    | {
        __typename: 'EditorialBannerData';
        bannerTitle: string;
        bannerText: string;
        buttonText: string;
        buttonLink: string;
        bannerImage: {
          __typename: 'EditorialBannerImageAsset';
          title: string | null;
          description: string | null;
          url: string;
        } | null;
      }
    | {
        __typename: 'ExtendedVideoAssetData';
        headline: string | null;
        text: string | null;
        isQuote: boolean | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | {
        __typename: 'FactBoxData';
        title: string | null;
        body: any | null;
        data: Array<{
          __typename: 'NodeData';
          id: string;
          data:
            | { __typename: 'BalanceOfPowerData' }
            | {
                __typename: 'EditorialBannerData';
                bannerTitle: string;
                bannerText: string;
                buttonText: string;
                buttonLink: string;
                bannerImage: {
                  __typename: 'EditorialBannerImageAsset';
                  title: string | null;
                  description: string | null;
                  url: string;
                } | null;
              }
            | {
                __typename: 'ExtendedVideoAssetData';
                headline: string | null;
                text: string | null;
                isQuote: boolean | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | { __typename: 'FactBoxData' }
            | { __typename: 'HyperLinkData'; url: string | null }
            | {
                __typename: 'ImageData';
                image: {
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  byline: string | null;
                  caption: string | null;
                  ratio: Ratio | null;
                  linkUrl: string | null;
                } | null;
              }
            | {
                __typename: 'ImageSliderData';
                images: Array<{
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  byline: string | null;
                  caption: string | null;
                  ratio: Ratio | null;
                  linkUrl: string | null;
                }>;
              }
            | { __typename: 'PollData'; directLink: string }
            | { __typename: 'SportStandingsTableData' }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | {
                __typename: 'YouTubeData';
                videoId: string | null;
                playlistId: string | null;
                startPositionInSeconds: number | null;
              };
        }>;
      }
    | { __typename: 'HyperLinkData'; url: string | null }
    | {
        __typename: 'ImageData';
        image: {
          __typename: 'Image';
          id: string;
          url: string | null;
          byline: string | null;
          caption: string | null;
          ratio: Ratio | null;
          linkUrl: string | null;
        } | null;
      }
    | {
        __typename: 'ImageSliderData';
        images: Array<{
          __typename: 'Image';
          id: string;
          url: string | null;
          byline: string | null;
          caption: string | null;
          ratio: Ratio | null;
          linkUrl: string | null;
        }>;
      }
    | { __typename: 'PollData'; directLink: string }
    | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | {
        __typename: 'YouTubeData';
        videoId: string | null;
        playlistId: string | null;
        startPositionInSeconds: number | null;
      };
};

type NodeDataUnionFields_BalanceOfPowerData_Fragment = { __typename: 'BalanceOfPowerData'; id: string };

type NodeDataUnionFields_EditorialBannerData_Fragment = {
  __typename: 'EditorialBannerData';
  bannerTitle: string;
  bannerText: string;
  buttonText: string;
  buttonLink: string;
  bannerImage: {
    __typename: 'EditorialBannerImageAsset';
    title: string | null;
    description: string | null;
    url: string;
  } | null;
};

type NodeDataUnionFields_ExtendedVideoAssetData_Fragment = {
  __typename: 'ExtendedVideoAssetData';
  headline: string | null;
  text: string | null;
  isQuote: boolean | null;
  asset: {
    __typename: 'VideoAsset';
    id: string;
    metadataId: string | null;
    slug: string | null;
    title: string;
    description: string | null;
    image: string | null;
    type: string | null;
    duration: number | null;
    humanDuration: string | null;
    publishedDateTime: string | null;
    humanPublishedDateTime: string | null;
    humanBroadcastShortDateTime: string | null;
    ratio: Ratio | null;
    live: boolean | null;
    program: { __typename: 'Program'; nid: string } | null;
  } | null;
};

type NodeDataUnionFields_FactBoxData_Fragment = {
  __typename: 'FactBoxData';
  title: string | null;
  body: any | null;
  data: Array<{
    __typename: 'NodeData';
    id: string;
    data:
      | { __typename: 'BalanceOfPowerData' }
      | {
          __typename: 'EditorialBannerData';
          bannerTitle: string;
          bannerText: string;
          buttonText: string;
          buttonLink: string;
          bannerImage: {
            __typename: 'EditorialBannerImageAsset';
            title: string | null;
            description: string | null;
            url: string;
          } | null;
        }
      | {
          __typename: 'ExtendedVideoAssetData';
          headline: string | null;
          text: string | null;
          isQuote: boolean | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | { __typename: 'FactBoxData' }
      | { __typename: 'HyperLinkData'; url: string | null }
      | {
          __typename: 'ImageData';
          image: {
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          } | null;
        }
      | {
          __typename: 'ImageSliderData';
          images: Array<{
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          }>;
        }
      | { __typename: 'PollData'; directLink: string }
      | { __typename: 'SportStandingsTableData' }
      | {
          __typename: 'VideoPlayerData';
          id: string;
          metadataId: string | null;
          caption: string | null;
          thumbnail: string | null;
          thumbnailByline: string | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'YouTubeData';
          videoId: string | null;
          playlistId: string | null;
          startPositionInSeconds: number | null;
        };
  }>;
};

type NodeDataUnionFields_HyperLinkData_Fragment = { __typename: 'HyperLinkData'; url: string | null };

type NodeDataUnionFields_ImageData_Fragment = {
  __typename: 'ImageData';
  image: {
    __typename: 'Image';
    id: string;
    url: string | null;
    byline: string | null;
    caption: string | null;
    ratio: Ratio | null;
    linkUrl: string | null;
  } | null;
};

type NodeDataUnionFields_ImageSliderData_Fragment = {
  __typename: 'ImageSliderData';
  images: Array<{
    __typename: 'Image';
    id: string;
    url: string | null;
    byline: string | null;
    caption: string | null;
    ratio: Ratio | null;
    linkUrl: string | null;
  }>;
};

type NodeDataUnionFields_PollData_Fragment = { __typename: 'PollData'; directLink: string };

type NodeDataUnionFields_SportStandingsTableData_Fragment = {
  __typename: 'SportStandingsTableData';
  defaultLeague: League | null;
  leagues: Array<League>;
};

type NodeDataUnionFields_VideoPlayerData_Fragment = {
  __typename: 'VideoPlayerData';
  id: string;
  metadataId: string | null;
  caption: string | null;
  thumbnail: string | null;
  thumbnailByline: string | null;
  asset: {
    __typename: 'VideoAsset';
    id: string;
    metadataId: string | null;
    slug: string | null;
    title: string;
    description: string | null;
    image: string | null;
    type: string | null;
    duration: number | null;
    humanDuration: string | null;
    publishedDateTime: string | null;
    humanPublishedDateTime: string | null;
    humanBroadcastShortDateTime: string | null;
    ratio: Ratio | null;
    live: boolean | null;
    program: { __typename: 'Program'; nid: string } | null;
  } | null;
};

type NodeDataUnionFields_YouTubeData_Fragment = {
  __typename: 'YouTubeData';
  videoId: string | null;
  playlistId: string | null;
  startPositionInSeconds: number | null;
};

export type NodeDataUnionFieldsFragment =
  | NodeDataUnionFields_BalanceOfPowerData_Fragment
  | NodeDataUnionFields_EditorialBannerData_Fragment
  | NodeDataUnionFields_ExtendedVideoAssetData_Fragment
  | NodeDataUnionFields_FactBoxData_Fragment
  | NodeDataUnionFields_HyperLinkData_Fragment
  | NodeDataUnionFields_ImageData_Fragment
  | NodeDataUnionFields_ImageSliderData_Fragment
  | NodeDataUnionFields_PollData_Fragment
  | NodeDataUnionFields_SportStandingsTableData_Fragment
  | NodeDataUnionFields_VideoPlayerData_Fragment
  | NodeDataUnionFields_YouTubeData_Fragment;

export type SportStandingsTableFieldsFragment = {
  __typename: 'SportStandingsTableData';
  defaultLeague: League | null;
  leagues: Array<League>;
};

export type FactBoxDataFieldsFragment = {
  __typename: 'FactBoxData';
  title: string | null;
  body: any | null;
  data: Array<{
    __typename: 'NodeData';
    id: string;
    data:
      | { __typename: 'BalanceOfPowerData' }
      | {
          __typename: 'EditorialBannerData';
          bannerTitle: string;
          bannerText: string;
          buttonText: string;
          buttonLink: string;
          bannerImage: {
            __typename: 'EditorialBannerImageAsset';
            title: string | null;
            description: string | null;
            url: string;
          } | null;
        }
      | {
          __typename: 'ExtendedVideoAssetData';
          headline: string | null;
          text: string | null;
          isQuote: boolean | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | { __typename: 'FactBoxData' }
      | { __typename: 'HyperLinkData'; url: string | null }
      | {
          __typename: 'ImageData';
          image: {
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          } | null;
        }
      | {
          __typename: 'ImageSliderData';
          images: Array<{
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          }>;
        }
      | { __typename: 'PollData'; directLink: string }
      | { __typename: 'SportStandingsTableData' }
      | {
          __typename: 'VideoPlayerData';
          id: string;
          metadataId: string | null;
          caption: string | null;
          thumbnail: string | null;
          thumbnailByline: string | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'YouTubeData';
          videoId: string | null;
          playlistId: string | null;
          startPositionInSeconds: number | null;
        };
  }>;
};

export type FactBoxNodeDataFieldsFragment = {
  __typename: 'NodeData';
  id: string;
  data:
    | { __typename: 'BalanceOfPowerData' }
    | {
        __typename: 'EditorialBannerData';
        bannerTitle: string;
        bannerText: string;
        buttonText: string;
        buttonLink: string;
        bannerImage: {
          __typename: 'EditorialBannerImageAsset';
          title: string | null;
          description: string | null;
          url: string;
        } | null;
      }
    | {
        __typename: 'ExtendedVideoAssetData';
        headline: string | null;
        text: string | null;
        isQuote: boolean | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | { __typename: 'FactBoxData' }
    | { __typename: 'HyperLinkData'; url: string | null }
    | {
        __typename: 'ImageData';
        image: {
          __typename: 'Image';
          id: string;
          url: string | null;
          byline: string | null;
          caption: string | null;
          ratio: Ratio | null;
          linkUrl: string | null;
        } | null;
      }
    | {
        __typename: 'ImageSliderData';
        images: Array<{
          __typename: 'Image';
          id: string;
          url: string | null;
          byline: string | null;
          caption: string | null;
          ratio: Ratio | null;
          linkUrl: string | null;
        }>;
      }
    | { __typename: 'PollData'; directLink: string }
    | { __typename: 'SportStandingsTableData' }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | {
        __typename: 'YouTubeData';
        videoId: string | null;
        playlistId: string | null;
        startPositionInSeconds: number | null;
      };
};

export type BalanceOfPowerDataFieldsFragment = { __typename: 'BalanceOfPowerData'; id: string };

type FactBoxNodeDataUnionFields_BalanceOfPowerData_Fragment = { __typename: 'BalanceOfPowerData' };

type FactBoxNodeDataUnionFields_EditorialBannerData_Fragment = {
  __typename: 'EditorialBannerData';
  bannerTitle: string;
  bannerText: string;
  buttonText: string;
  buttonLink: string;
  bannerImage: {
    __typename: 'EditorialBannerImageAsset';
    title: string | null;
    description: string | null;
    url: string;
  } | null;
};

type FactBoxNodeDataUnionFields_ExtendedVideoAssetData_Fragment = {
  __typename: 'ExtendedVideoAssetData';
  headline: string | null;
  text: string | null;
  isQuote: boolean | null;
  asset: {
    __typename: 'VideoAsset';
    id: string;
    metadataId: string | null;
    slug: string | null;
    title: string;
    description: string | null;
    image: string | null;
    type: string | null;
    duration: number | null;
    humanDuration: string | null;
    publishedDateTime: string | null;
    humanPublishedDateTime: string | null;
    humanBroadcastShortDateTime: string | null;
    ratio: Ratio | null;
    live: boolean | null;
    program: { __typename: 'Program'; nid: string } | null;
  } | null;
};

type FactBoxNodeDataUnionFields_FactBoxData_Fragment = { __typename: 'FactBoxData' };

type FactBoxNodeDataUnionFields_HyperLinkData_Fragment = { __typename: 'HyperLinkData'; url: string | null };

type FactBoxNodeDataUnionFields_ImageData_Fragment = {
  __typename: 'ImageData';
  image: {
    __typename: 'Image';
    id: string;
    url: string | null;
    byline: string | null;
    caption: string | null;
    ratio: Ratio | null;
    linkUrl: string | null;
  } | null;
};

type FactBoxNodeDataUnionFields_ImageSliderData_Fragment = {
  __typename: 'ImageSliderData';
  images: Array<{
    __typename: 'Image';
    id: string;
    url: string | null;
    byline: string | null;
    caption: string | null;
    ratio: Ratio | null;
    linkUrl: string | null;
  }>;
};

type FactBoxNodeDataUnionFields_PollData_Fragment = { __typename: 'PollData'; directLink: string };

type FactBoxNodeDataUnionFields_SportStandingsTableData_Fragment = { __typename: 'SportStandingsTableData' };

type FactBoxNodeDataUnionFields_VideoPlayerData_Fragment = {
  __typename: 'VideoPlayerData';
  id: string;
  metadataId: string | null;
  caption: string | null;
  thumbnail: string | null;
  thumbnailByline: string | null;
  asset: {
    __typename: 'VideoAsset';
    id: string;
    metadataId: string | null;
    slug: string | null;
    title: string;
    description: string | null;
    image: string | null;
    type: string | null;
    duration: number | null;
    humanDuration: string | null;
    publishedDateTime: string | null;
    humanPublishedDateTime: string | null;
    humanBroadcastShortDateTime: string | null;
    ratio: Ratio | null;
    live: boolean | null;
    program: { __typename: 'Program'; nid: string } | null;
  } | null;
};

type FactBoxNodeDataUnionFields_YouTubeData_Fragment = {
  __typename: 'YouTubeData';
  videoId: string | null;
  playlistId: string | null;
  startPositionInSeconds: number | null;
};

export type FactBoxNodeDataUnionFieldsFragment =
  | FactBoxNodeDataUnionFields_BalanceOfPowerData_Fragment
  | FactBoxNodeDataUnionFields_EditorialBannerData_Fragment
  | FactBoxNodeDataUnionFields_ExtendedVideoAssetData_Fragment
  | FactBoxNodeDataUnionFields_FactBoxData_Fragment
  | FactBoxNodeDataUnionFields_HyperLinkData_Fragment
  | FactBoxNodeDataUnionFields_ImageData_Fragment
  | FactBoxNodeDataUnionFields_ImageSliderData_Fragment
  | FactBoxNodeDataUnionFields_PollData_Fragment
  | FactBoxNodeDataUnionFields_SportStandingsTableData_Fragment
  | FactBoxNodeDataUnionFields_VideoPlayerData_Fragment
  | FactBoxNodeDataUnionFields_YouTubeData_Fragment;

export type HyperLinkDataFieldsFragment = { __typename: 'HyperLinkData'; url: string | null };

export type ImageDataFieldsFragment = {
  __typename: 'ImageData';
  image: {
    __typename: 'Image';
    id: string;
    url: string | null;
    byline: string | null;
    caption: string | null;
    ratio: Ratio | null;
    linkUrl: string | null;
  } | null;
};

export type ImageSliderDataFieldsFragment = {
  __typename: 'ImageSliderData';
  images: Array<{
    __typename: 'Image';
    id: string;
    url: string | null;
    byline: string | null;
    caption: string | null;
    ratio: Ratio | null;
    linkUrl: string | null;
  }>;
};

export type YouTubeDataFieldsFragment = {
  __typename: 'YouTubeData';
  videoId: string | null;
  playlistId: string | null;
  startPositionInSeconds: number | null;
};

export type ExtendedVideoAssetDataFieldsFragment = {
  __typename: 'ExtendedVideoAssetData';
  headline: string | null;
  text: string | null;
  isQuote: boolean | null;
  asset: {
    __typename: 'VideoAsset';
    id: string;
    metadataId: string | null;
    slug: string | null;
    title: string;
    description: string | null;
    image: string | null;
    type: string | null;
    duration: number | null;
    humanDuration: string | null;
    publishedDateTime: string | null;
    humanPublishedDateTime: string | null;
    humanBroadcastShortDateTime: string | null;
    ratio: Ratio | null;
    live: boolean | null;
    program: { __typename: 'Program'; nid: string } | null;
  } | null;
};

export type PollDataFieldsFragment = { __typename: 'PollData'; directLink: string };

export type EditorialBannerDataFieldsFragment = {
  __typename: 'EditorialBannerData';
  bannerTitle: string;
  bannerText: string;
  buttonText: string;
  buttonLink: string;
  bannerImage: {
    __typename: 'EditorialBannerImageAsset';
    title: string | null;
    description: string | null;
    url: string;
  } | null;
};

export type BreakingNewsFieldsFragment = {
  __typename: 'BreakingNews';
  id: string;
  title: string | null;
  publishedAt: string | null;
  humanPublishedAt: string | null;
  firstPublishedAt: string | null;
  humanFirstPublishedAt: string | null;
  articleId: string | null;
  bylineName: string | null;
  bylineEmail: string | null;
  body: any | null;
  byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
  data: Array<{
    __typename: 'NodeData';
    id: string;
    data:
      | { __typename: 'BalanceOfPowerData'; id: string }
      | {
          __typename: 'EditorialBannerData';
          bannerTitle: string;
          bannerText: string;
          buttonText: string;
          buttonLink: string;
          bannerImage: {
            __typename: 'EditorialBannerImageAsset';
            title: string | null;
            description: string | null;
            url: string;
          } | null;
        }
      | {
          __typename: 'ExtendedVideoAssetData';
          headline: string | null;
          text: string | null;
          isQuote: boolean | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'FactBoxData';
          title: string | null;
          body: any | null;
          data: Array<{
            __typename: 'NodeData';
            id: string;
            data:
              | { __typename: 'BalanceOfPowerData' }
              | {
                  __typename: 'EditorialBannerData';
                  bannerTitle: string;
                  bannerText: string;
                  buttonText: string;
                  buttonLink: string;
                  bannerImage: {
                    __typename: 'EditorialBannerImageAsset';
                    title: string | null;
                    description: string | null;
                    url: string;
                  } | null;
                }
              | {
                  __typename: 'ExtendedVideoAssetData';
                  headline: string | null;
                  text: string | null;
                  isQuote: boolean | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | { __typename: 'FactBoxData' }
              | { __typename: 'HyperLinkData'; url: string | null }
              | {
                  __typename: 'ImageData';
                  image: {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  } | null;
                }
              | {
                  __typename: 'ImageSliderData';
                  images: Array<{
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  }>;
                }
              | { __typename: 'PollData'; directLink: string }
              | { __typename: 'SportStandingsTableData' }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  metadataId: string | null;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | {
                  __typename: 'YouTubeData';
                  videoId: string | null;
                  playlistId: string | null;
                  startPositionInSeconds: number | null;
                };
          }>;
        }
      | { __typename: 'HyperLinkData'; url: string | null }
      | {
          __typename: 'ImageData';
          image: {
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          } | null;
        }
      | {
          __typename: 'ImageSliderData';
          images: Array<{
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          }>;
        }
      | { __typename: 'PollData'; directLink: string }
      | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
      | {
          __typename: 'VideoPlayerData';
          id: string;
          metadataId: string | null;
          caption: string | null;
          thumbnail: string | null;
          thumbnailByline: string | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'YouTubeData';
          videoId: string | null;
          playlistId: string | null;
          startPositionInSeconds: number | null;
        };
  } | null> | null;
};

export type BroadcastFieldsFragment = {
  __typename: 'Broadcast';
  id: string;
  title: string;
  start: string;
  end: string;
  synopsis: {
    __typename: 'Synopsis';
    id: string;
    brief: string | null;
    long: string | null;
    medium: string | null;
    short: string | null;
  } | null;
};

export type EmphasizedArticleGroupFieldsFragment = {
  __typename: 'ArticleGroup';
  id: string;
  title: string;
  isEmphasized: boolean;
  content: Array<
    | {
        __typename: 'ArticleGroupArticle';
        id: string;
        slug: string | null;
        title: string;
        live: boolean | null;
        lead: string | null;
        publishedDateTime: string | null;
        humanPublishedDateTime: string | null;
        updatedDateTime: string | null;
        humanUpdatedDateTime: string | null;
        liveReportId: string | null;
        bylineName: string | null;
        bylineEmail: string | null;
        isOpinionPiece: boolean;
        isSponsoredArticle: boolean;
        featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
        feedOrigins: Array<{
          __typename: 'FeedOrigin';
          type: FeedType;
          id: string;
          slug: string | null;
          title: string | null;
        }>;
        byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
        featuredAsset:
          | {
              __typename: 'Image';
              id: string;
              url: string | null;
              caption: string | null;
              byline: string | null;
              linkUrl: string | null;
              ratio: Ratio | null;
            }
          | {
              __typename: 'VideoPlayerData';
              id: string;
              title: string;
              caption: string | null;
              thumbnail: string | null;
              thumbnailByline: string | null;
              asset: {
                __typename: 'VideoAsset';
                id: string;
                title: string;
                slug: string | null;
                description: string | null;
                live: boolean | null;
                type: string | null;
                image: string | null;
                duration: number | null;
                humanDuration: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanBroadcastShortDateTime: string | null;
                ratio: Ratio | null;
                program: { __typename: 'Program'; nid: string } | null;
              } | null;
            }
          | null;
      }
    | {
        __typename: 'ArticleGroupBlock';
        id: string;
        layout: Layout | null;
        imagePlacement: ImagePlacement | null;
        content: Array<{
          __typename: 'ArticleGroupArticle';
          id: string;
          slug: string | null;
          title: string;
          live: boolean | null;
          lead: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          updatedDateTime: string | null;
          humanUpdatedDateTime: string | null;
          liveReportId: string | null;
          bylineName: string | null;
          bylineEmail: string | null;
          isSponsoredArticle: boolean;
          feedOrigins: Array<{
            __typename: 'FeedOrigin';
            type: FeedType;
            id: string;
            slug: string | null;
            title: string | null;
          }>;
          featuredAsset:
            | {
                __typename: 'Image';
                id: string;
                url: string | null;
                caption: string | null;
                byline: string | null;
                linkUrl: string | null;
                ratio: Ratio | null;
              }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                title: string;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  title: string;
                  slug: string | null;
                  description: string | null;
                  live: boolean | null;
                  type: string | null;
                  image: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | null;
        } | null>;
      }
    | null
  >;
};

export type ExternalLinkFieldsFragment = { __typename: 'ExternalLink'; id: string; url: string; title: string };

export type FeatureFlagFieldsFragment = { __typename: 'FeatureFlag'; id: string; key: string; active: boolean };

export type FeedLinkFieldsFragment = { __typename: 'FeedLink'; id: string; slug: string; title: string };

export type FeedOriginFieldsFragment = {
  __typename: 'FeedOrigin';
  id: string;
  slug: string | null;
  title: string | null;
};

export type FeedOriginWithTypeFieldsFragment = {
  __typename: 'FeedOrigin';
  type: FeedType;
  id: string;
  slug: string | null;
  title: string | null;
};

export type HeroFieldsFragment = {
  __typename: 'Hero';
  id: string;
  title: string | null;
  image: string | null;
  heroImage: string | null;
  description: string | null;
  shortDescription: string | null;
  twitterUrl: string | null;
  facebookUrl: string | null;
  instagramUrl: string | null;
  program: { __typename: 'HeroProgram'; nid: string | null; id: string | null } | null;
};

export type ImageFieldsFragment = {
  __typename: 'Image';
  id: string;
  url: string | null;
  byline: string | null;
  caption: string | null;
  ratio: Ratio | null;
  linkUrl: string | null;
};

export type InternalLinkFieldsFragment = { __typename: 'InternalLink'; id: string; title: string; url: string };

export type ListAllNewsFeedsFieldsFragment = {
  __typename: 'FeedUrlData';
  id: string;
  slug: string;
  title: string;
  updatedAt: string;
  publishedAt: string;
};

export type LiveReportFieldsFragment = {
  __typename: 'LiveReport';
  id: string;
  liveReportId: string | null;
  title: string;
  text: string | null;
};

export type MediaPanelItemFieldsFragment = {
  __typename: 'MediaPanelItem';
  id: string;
  title: string;
  type: MediaPanelItemType;
  link: string;
  image: { __typename: 'Image'; url: string | null; ratio: Ratio | null };
};

export type MediaSliderFieldsFragment = {
  __typename: 'MediaSlider';
  id: string;
  title: string;
  nordicCmsPanelId: string;
};

export type NewsFeedForPluginFieldsFragment = { __typename: 'NewsFeedForPlugin'; id: string; title: string };

export type PaginatedNewsFeedFieldsFragment = {
  __typename: 'PaginatedNewsFeed';
  id: string;
  slug: string | null;
  redirect: string | null;
  title: string | null;
  metaTitle: string | null;
  metaDescription: string | null;
  ogTitle: string | null;
  ogDescription: string | null;
  ogImage: string | null;
  main16x9Annotated: string | null;
  category: Category | null;
  publishedAt: string | null;
  modifiedAt: string | null;
  hero: {
    __typename: 'Hero';
    id: string;
    title: string | null;
    image: string | null;
    heroImage: string | null;
    description: string | null;
    shortDescription: string | null;
    twitterUrl: string | null;
    facebookUrl: string | null;
    instagramUrl: string | null;
    program: { __typename: 'HeroProgram'; nid: string | null; id: string | null } | null;
  } | null;
  emphasizedArticleGroup: {
    __typename: 'EmphasizedArticleGroup';
    content: Array<{
      __typename: 'ArticleGroup';
      id: string;
      title: string;
      isEmphasized: boolean;
      content: Array<
        | {
            __typename: 'ArticleGroupArticle';
            id: string;
            slug: string | null;
            title: string;
            live: boolean | null;
            lead: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            updatedDateTime: string | null;
            humanUpdatedDateTime: string | null;
            liveReportId: string | null;
            bylineName: string | null;
            bylineEmail: string | null;
            isOpinionPiece: boolean;
            isSponsoredArticle: boolean;
            featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
            feedOrigins: Array<{
              __typename: 'FeedOrigin';
              type: FeedType;
              id: string;
              slug: string | null;
              title: string | null;
            }>;
            byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
            featuredAsset:
              | {
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  caption: string | null;
                  byline: string | null;
                  linkUrl: string | null;
                  ratio: Ratio | null;
                }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  title: string;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    title: string;
                    slug: string | null;
                    description: string | null;
                    live: boolean | null;
                    type: string | null;
                    image: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | null;
          }
        | {
            __typename: 'ArticleGroupBlock';
            id: string;
            layout: Layout | null;
            imagePlacement: ImagePlacement | null;
            content: Array<{
              __typename: 'ArticleGroupArticle';
              id: string;
              slug: string | null;
              title: string;
              live: boolean | null;
              lead: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              updatedDateTime: string | null;
              humanUpdatedDateTime: string | null;
              liveReportId: string | null;
              bylineName: string | null;
              bylineEmail: string | null;
              isSponsoredArticle: boolean;
              feedOrigins: Array<{
                __typename: 'FeedOrigin';
                type: FeedType;
                id: string;
                slug: string | null;
                title: string | null;
              }>;
              featuredAsset:
                | {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    caption: string | null;
                    byline: string | null;
                    linkUrl: string | null;
                    ratio: Ratio | null;
                  }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    title: string;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      title: string;
                      slug: string | null;
                      description: string | null;
                      live: boolean | null;
                      type: string | null;
                      image: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | null;
            } | null>;
          }
        | null
      >;
    }>;
  } | null;
  feed: {
    __typename: 'PaginatedFeed';
    pagingData: { __typename: 'PagingData'; currentPage: number | null; nextPage: number | null } | null;
    items: Array<
      | {
          __typename: 'Article';
          id: string;
          title: string;
          slug: string | null;
          live: boolean | null;
          lead: string | null;
          liveReportId: string | null;
          isOpinionPiece: boolean;
          metaTitle: string | null;
          metaDescription: string | null;
          ogTitle: string | null;
          ogDescription: string | null;
          isAdsHidden: boolean;
          isSponsoredArticle: boolean;
          showPobInFeed: boolean;
          byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
          featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
          metadata: {
            __typename: 'ArticleMetadata';
            id: string;
            wordCount: number | null;
            linkCount: number | null;
            imageCount: number | null;
            videoCount: number | null;
          } | null;
          featuredAsset:
            | {
                __typename: 'Image';
                id: string;
                url: string | null;
                byline: string | null;
                caption: string | null;
                ratio: Ratio | null;
                linkUrl: string | null;
              }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | null;
          related: Array<
            | {
                __typename: 'RelatedArticle';
                id: string;
                title: string;
                slug: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanUpdatedDateTime: string | null;
                updatedDateTime: string | null;
                lead: string | null;
                articleLeadRichText: any | null;
                liveReportId: string | null;
                bylineName: string | null;
                bylineEmail: string | null;
                body: any | null;
                isAdsHidden: boolean;
                isSponsoredArticle: boolean;
                byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
                featuredFeed: {
                  __typename: 'FeedOrigin';
                  id: string;
                  slug: string | null;
                  title: string | null;
                } | null;
                metadata: {
                  __typename: 'ArticleMetadata';
                  id: string;
                  wordCount: number | null;
                  linkCount: number | null;
                  imageCount: number | null;
                  videoCount: number | null;
                } | null;
                data: Array<{
                  __typename: 'NodeData';
                  id: string;
                  data:
                    | { __typename: 'BalanceOfPowerData'; id: string }
                    | {
                        __typename: 'EditorialBannerData';
                        bannerTitle: string;
                        bannerText: string;
                        buttonText: string;
                        buttonLink: string;
                        bannerImage: {
                          __typename: 'EditorialBannerImageAsset';
                          title: string | null;
                          description: string | null;
                          url: string;
                        } | null;
                      }
                    | {
                        __typename: 'ExtendedVideoAssetData';
                        headline: string | null;
                        text: string | null;
                        isQuote: boolean | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | {
                        __typename: 'FactBoxData';
                        title: string | null;
                        body: any | null;
                        data: Array<{
                          __typename: 'NodeData';
                          id: string;
                          data:
                            | { __typename: 'BalanceOfPowerData' }
                            | {
                                __typename: 'EditorialBannerData';
                                bannerTitle: string;
                                bannerText: string;
                                buttonText: string;
                                buttonLink: string;
                                bannerImage: {
                                  __typename: 'EditorialBannerImageAsset';
                                  title: string | null;
                                  description: string | null;
                                  url: string;
                                } | null;
                              }
                            | {
                                __typename: 'ExtendedVideoAssetData';
                                headline: string | null;
                                text: string | null;
                                isQuote: boolean | null;
                                asset: {
                                  __typename: 'VideoAsset';
                                  id: string;
                                  metadataId: string | null;
                                  slug: string | null;
                                  title: string;
                                  description: string | null;
                                  image: string | null;
                                  type: string | null;
                                  duration: number | null;
                                  humanDuration: string | null;
                                  publishedDateTime: string | null;
                                  humanPublishedDateTime: string | null;
                                  humanBroadcastShortDateTime: string | null;
                                  ratio: Ratio | null;
                                  live: boolean | null;
                                  program: { __typename: 'Program'; nid: string } | null;
                                } | null;
                              }
                            | { __typename: 'FactBoxData' }
                            | { __typename: 'HyperLinkData'; url: string | null }
                            | {
                                __typename: 'ImageData';
                                image: {
                                  __typename: 'Image';
                                  id: string;
                                  url: string | null;
                                  byline: string | null;
                                  caption: string | null;
                                  ratio: Ratio | null;
                                  linkUrl: string | null;
                                } | null;
                              }
                            | {
                                __typename: 'ImageSliderData';
                                images: Array<{
                                  __typename: 'Image';
                                  id: string;
                                  url: string | null;
                                  byline: string | null;
                                  caption: string | null;
                                  ratio: Ratio | null;
                                  linkUrl: string | null;
                                }>;
                              }
                            | { __typename: 'PollData'; directLink: string }
                            | { __typename: 'SportStandingsTableData' }
                            | {
                                __typename: 'VideoPlayerData';
                                id: string;
                                metadataId: string | null;
                                caption: string | null;
                                thumbnail: string | null;
                                thumbnailByline: string | null;
                                asset: {
                                  __typename: 'VideoAsset';
                                  id: string;
                                  metadataId: string | null;
                                  slug: string | null;
                                  title: string;
                                  description: string | null;
                                  image: string | null;
                                  type: string | null;
                                  duration: number | null;
                                  humanDuration: string | null;
                                  publishedDateTime: string | null;
                                  humanPublishedDateTime: string | null;
                                  humanBroadcastShortDateTime: string | null;
                                  ratio: Ratio | null;
                                  live: boolean | null;
                                  program: { __typename: 'Program'; nid: string } | null;
                                } | null;
                              }
                            | {
                                __typename: 'YouTubeData';
                                videoId: string | null;
                                playlistId: string | null;
                                startPositionInSeconds: number | null;
                              };
                        }>;
                      }
                    | { __typename: 'HyperLinkData'; url: string | null }
                    | {
                        __typename: 'ImageData';
                        image: {
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        } | null;
                      }
                    | {
                        __typename: 'ImageSliderData';
                        images: Array<{
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        }>;
                      }
                    | { __typename: 'PollData'; directLink: string }
                    | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        metadataId: string | null;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | {
                        __typename: 'YouTubeData';
                        videoId: string | null;
                        playlistId: string | null;
                        startPositionInSeconds: number | null;
                      };
                }>;
                featuredAsset:
                  | {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    }
                  | {
                      __typename: 'VideoPlayerData';
                      id: string;
                      metadataId: string | null;
                      caption: string | null;
                      thumbnail: string | null;
                      thumbnailByline: string | null;
                      asset: {
                        __typename: 'VideoAsset';
                        id: string;
                        metadataId: string | null;
                        slug: string | null;
                        title: string;
                        description: string | null;
                        image: string | null;
                        type: string | null;
                        duration: number | null;
                        humanDuration: string | null;
                        publishedDateTime: string | null;
                        humanPublishedDateTime: string | null;
                        humanBroadcastShortDateTime: string | null;
                        ratio: Ratio | null;
                        live: boolean | null;
                        program: { __typename: 'Program'; nid: string } | null;
                      } | null;
                    }
                  | null;
                feedOrigins: Array<{
                  __typename: 'FeedOrigin';
                  type: FeedType;
                  id: string;
                  slug: string | null;
                  title: string | null;
                }>;
              }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
          >;
          feedOrigins: Array<{
            __typename: 'FeedOrigin';
            type: FeedType;
            id: string;
            slug: string | null;
            title: string | null;
          }>;
        }
      | {
          __typename: 'ArticleGroup';
          id: string;
          title: string;
          isEmphasized: boolean;
          content: Array<
            | {
                __typename: 'ArticleGroupArticle';
                id: string;
                slug: string | null;
                title: string;
                live: boolean | null;
                lead: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                updatedDateTime: string | null;
                humanUpdatedDateTime: string | null;
                liveReportId: string | null;
                bylineName: string | null;
                bylineEmail: string | null;
                isOpinionPiece: boolean;
                isSponsoredArticle: boolean;
                featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
                feedOrigins: Array<{
                  __typename: 'FeedOrigin';
                  type: FeedType;
                  id: string;
                  slug: string | null;
                  title: string | null;
                }>;
                byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
                featuredAsset:
                  | {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      caption: string | null;
                      byline: string | null;
                      linkUrl: string | null;
                      ratio: Ratio | null;
                    }
                  | {
                      __typename: 'VideoPlayerData';
                      id: string;
                      title: string;
                      caption: string | null;
                      thumbnail: string | null;
                      thumbnailByline: string | null;
                      asset: {
                        __typename: 'VideoAsset';
                        id: string;
                        title: string;
                        slug: string | null;
                        description: string | null;
                        live: boolean | null;
                        type: string | null;
                        image: string | null;
                        duration: number | null;
                        humanDuration: string | null;
                        publishedDateTime: string | null;
                        humanPublishedDateTime: string | null;
                        humanBroadcastShortDateTime: string | null;
                        ratio: Ratio | null;
                        program: { __typename: 'Program'; nid: string } | null;
                      } | null;
                    }
                  | null;
              }
            | {
                __typename: 'ArticleGroupBlock';
                id: string;
                layout: Layout | null;
                imagePlacement: ImagePlacement | null;
                content: Array<{
                  __typename: 'ArticleGroupArticle';
                  id: string;
                  slug: string | null;
                  title: string;
                  live: boolean | null;
                  lead: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  updatedDateTime: string | null;
                  humanUpdatedDateTime: string | null;
                  liveReportId: string | null;
                  bylineName: string | null;
                  bylineEmail: string | null;
                  isSponsoredArticle: boolean;
                  feedOrigins: Array<{
                    __typename: 'FeedOrigin';
                    type: FeedType;
                    id: string;
                    slug: string | null;
                    title: string | null;
                  }>;
                  featuredAsset:
                    | {
                        __typename: 'Image';
                        id: string;
                        url: string | null;
                        caption: string | null;
                        byline: string | null;
                        linkUrl: string | null;
                        ratio: Ratio | null;
                      }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        title: string;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          title: string;
                          slug: string | null;
                          description: string | null;
                          live: boolean | null;
                          type: string | null;
                          image: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | null;
                } | null>;
              }
            | null
          >;
        }
      | { __typename: 'BreakingNewsModule'; id: string }
      | { __typename: 'LiveReport'; id: string; liveReportId: string | null; title: string; text: string | null }
      | { __typename: 'MediaSlider'; id: string; title: string; nordicCmsPanelId: string }
      | {
          __typename: 'ShortsSlider';
          id: string;
          title: string;
          items: Array<{
            __typename: 'ShortsSliderContent';
            id: string;
            title: string;
            metadataId: string;
            thumbnail: string;
            vimondId: string;
            duration: number | null;
            relativePublishDate: string | null;
          } | null> | null;
        }
      | { __typename: 'SportResultsModule'; id: string }
      | {
          __typename: 'Upsell';
          id: string;
          title: string;
          subtitle: string | null;
          backgroundColor: string | null;
          linkUrl: string;
          linkText: string;
          label: string | null;
          imageData: {
            __typename: 'UpsellImage';
            id: string;
            url: string;
            metadata: { __typename: 'UpsellImageMetadata'; blurHash: string | null; hexColor: string | null } | null;
          };
        }
      | {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        }
    >;
  } | null;
  sponsors: Array<{ __typename: 'Sponsor'; id: string; title: string; link: string; image: string }>;
};

export type RelatedArticleFieldsFragment = {
  __typename: 'RelatedArticle';
  id: string;
  title: string;
  slug: string | null;
  publishedDateTime: string | null;
  humanPublishedDateTime: string | null;
  humanUpdatedDateTime: string | null;
  updatedDateTime: string | null;
  lead: string | null;
  articleLeadRichText: any | null;
  liveReportId: string | null;
  bylineName: string | null;
  bylineEmail: string | null;
  body: any | null;
  isAdsHidden: boolean;
  isSponsoredArticle: boolean;
  byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
  featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
  metadata: {
    __typename: 'ArticleMetadata';
    id: string;
    wordCount: number | null;
    linkCount: number | null;
    imageCount: number | null;
    videoCount: number | null;
  } | null;
  data: Array<{
    __typename: 'NodeData';
    id: string;
    data:
      | { __typename: 'BalanceOfPowerData'; id: string }
      | {
          __typename: 'EditorialBannerData';
          bannerTitle: string;
          bannerText: string;
          buttonText: string;
          buttonLink: string;
          bannerImage: {
            __typename: 'EditorialBannerImageAsset';
            title: string | null;
            description: string | null;
            url: string;
          } | null;
        }
      | {
          __typename: 'ExtendedVideoAssetData';
          headline: string | null;
          text: string | null;
          isQuote: boolean | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'FactBoxData';
          title: string | null;
          body: any | null;
          data: Array<{
            __typename: 'NodeData';
            id: string;
            data:
              | { __typename: 'BalanceOfPowerData' }
              | {
                  __typename: 'EditorialBannerData';
                  bannerTitle: string;
                  bannerText: string;
                  buttonText: string;
                  buttonLink: string;
                  bannerImage: {
                    __typename: 'EditorialBannerImageAsset';
                    title: string | null;
                    description: string | null;
                    url: string;
                  } | null;
                }
              | {
                  __typename: 'ExtendedVideoAssetData';
                  headline: string | null;
                  text: string | null;
                  isQuote: boolean | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | { __typename: 'FactBoxData' }
              | { __typename: 'HyperLinkData'; url: string | null }
              | {
                  __typename: 'ImageData';
                  image: {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  } | null;
                }
              | {
                  __typename: 'ImageSliderData';
                  images: Array<{
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  }>;
                }
              | { __typename: 'PollData'; directLink: string }
              | { __typename: 'SportStandingsTableData' }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  metadataId: string | null;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | {
                  __typename: 'YouTubeData';
                  videoId: string | null;
                  playlistId: string | null;
                  startPositionInSeconds: number | null;
                };
          }>;
        }
      | { __typename: 'HyperLinkData'; url: string | null }
      | {
          __typename: 'ImageData';
          image: {
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          } | null;
        }
      | {
          __typename: 'ImageSliderData';
          images: Array<{
            __typename: 'Image';
            id: string;
            url: string | null;
            byline: string | null;
            caption: string | null;
            ratio: Ratio | null;
            linkUrl: string | null;
          }>;
        }
      | { __typename: 'PollData'; directLink: string }
      | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
      | {
          __typename: 'VideoPlayerData';
          id: string;
          metadataId: string | null;
          caption: string | null;
          thumbnail: string | null;
          thumbnailByline: string | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | {
          __typename: 'YouTubeData';
          videoId: string | null;
          playlistId: string | null;
          startPositionInSeconds: number | null;
        };
  }>;
  featuredAsset:
    | {
        __typename: 'Image';
        id: string;
        url: string | null;
        byline: string | null;
        caption: string | null;
        ratio: Ratio | null;
        linkUrl: string | null;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        } | null;
      }
    | null;
  feedOrigins: Array<{
    __typename: 'FeedOrigin';
    type: FeedType;
    id: string;
    slug: string | null;
    title: string | null;
  }>;
};

export type ScheduleFieldsFragment = {
  __typename: 'Schedule';
  id: string;
  metadataChannelId: string;
  name: string;
  displayName: string;
  day: string | null;
  broadcasts: Array<{
    __typename: 'Broadcast';
    id: string;
    title: string;
    start: string;
    end: string;
    synopsis: {
      __typename: 'Synopsis';
      id: string;
      brief: string | null;
      long: string | null;
      medium: string | null;
      short: string | null;
    } | null;
  }>;
};

export type ShortsSliderFieldsFragment = {
  __typename: 'ShortsSlider';
  id: string;
  title: string;
  items: Array<{
    __typename: 'ShortsSliderContent';
    id: string;
    title: string;
    metadataId: string;
    thumbnail: string;
    vimondId: string;
    duration: number | null;
    relativePublishDate: string | null;
  } | null> | null;
};

export type SponsorFieldsFragment = { __typename: 'Sponsor'; id: string; title: string; link: string; image: string };

export type SynopsisFieldsFragment = {
  __typename: 'Synopsis';
  id: string;
  brief: string | null;
  long: string | null;
  medium: string | null;
  short: string | null;
};

export type UpsellFieldsFragment = {
  __typename: 'Upsell';
  id: string;
  title: string;
  subtitle: string | null;
  backgroundColor: string | null;
  linkUrl: string;
  linkText: string;
  label: string | null;
  imageData: {
    __typename: 'UpsellImage';
    id: string;
    url: string;
    metadata: { __typename: 'UpsellImageMetadata'; blurHash: string | null; hexColor: string | null } | null;
  };
};

export type UpsellImageFieldsFragment = {
  __typename: 'UpsellImage';
  id: string;
  url: string;
  metadata: { __typename: 'UpsellImageMetadata'; blurHash: string | null; hexColor: string | null } | null;
};

export type UpsellImageMetadataFieldsFragment = {
  __typename: 'UpsellImageMetadata';
  blurHash: string | null;
  hexColor: string | null;
};

export type VideoAssetFieldsFragment = {
  __typename: 'VideoAsset';
  id: string;
  metadataId: string | null;
  slug: string | null;
  title: string;
  description: string | null;
  image: string | null;
  type: string | null;
  duration: number | null;
  humanDuration: string | null;
  publishedDateTime: string | null;
  humanPublishedDateTime: string | null;
  humanBroadcastShortDateTime: string | null;
  ratio: Ratio | null;
  live: boolean | null;
  program: { __typename: 'Program'; nid: string } | null;
};

export type VideoPlayerDataFieldsFragment = {
  __typename: 'VideoPlayerData';
  id: string;
  metadataId: string | null;
  caption: string | null;
  thumbnail: string | null;
  thumbnailByline: string | null;
  asset: {
    __typename: 'VideoAsset';
    id: string;
    metadataId: string | null;
    slug: string | null;
    title: string;
    description: string | null;
    image: string | null;
    type: string | null;
    duration: number | null;
    humanDuration: string | null;
    publishedDateTime: string | null;
    humanPublishedDateTime: string | null;
    humanBroadcastShortDateTime: string | null;
    ratio: Ratio | null;
    live: boolean | null;
    program: { __typename: 'Program'; nid: string } | null;
  } | null;
};

export type VideoUrlDataFieldsFragment = {
  __typename: 'VideoUrlData';
  id: string;
  metadataId: string;
  slug: string;
  thumbnail: string | null;
  title: string;
  description: string | null;
  duration: number | null;
  publishedAt: string;
  tags: Array<string>;
};

export type AppInfoQueryVariables = Exact<{ [key: string]: never }>;

export type AppInfoQuery = { __typename: 'Query'; appInfo: { __typename: 'AppInfo'; minimumVersion: string } };

export type ArticleUrlDataByDateQueryVariables = Exact<{
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  limit: InputMaybe<Scalars['Int']['input']>;
  skip: InputMaybe<Scalars['Int']['input']>;
}>;

export type ArticleUrlDataByDateQuery = {
  __typename: 'Query';
  articleUrlDataByDate: {
    __typename: 'ArticleUrlDataByDate';
    total: number;
    items: Array<{
      __typename: 'ArticleUrlData';
      id: string;
      title: string;
      slug: string;
      publishedAt: string;
      updatedAt: string;
    }>;
  };
};

export type ArticlesQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type ArticlesQuery = {
  __typename: 'Query';
  articles: Array<{
    __typename: 'Article';
    id: string;
    title: string;
    slug: string | null;
    publishedDateTime: string | null;
    humanPublishedDateTime: string | null;
    humanUpdatedDateTime: string | null;
    updatedDateTime: string | null;
    live: boolean | null;
    lead: string | null;
    articleLeadRichText: any | null;
    liveReportId: string | null;
    bylineName: string | null;
    bylineEmail: string | null;
    isOpinionPiece: boolean;
    body: any | null;
    metaTitle: string | null;
    metaDescription: string | null;
    ogTitle: string | null;
    ogDescription: string | null;
    isAdsHidden: boolean;
    isSponsoredArticle: boolean;
    sponsoredByBanner: boolean;
    showPobInFeed: boolean;
    showElectionMap: boolean;
    byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
    featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
    data: Array<{
      __typename: 'NodeData';
      id: string;
      data:
        | { __typename: 'BalanceOfPowerData'; id: string }
        | {
            __typename: 'EditorialBannerData';
            bannerTitle: string;
            bannerText: string;
            buttonText: string;
            buttonLink: string;
            bannerImage: {
              __typename: 'EditorialBannerImageAsset';
              title: string | null;
              description: string | null;
              url: string;
            } | null;
          }
        | {
            __typename: 'ExtendedVideoAssetData';
            headline: string | null;
            text: string | null;
            isQuote: boolean | null;
            asset: {
              __typename: 'VideoAsset';
              id: string;
              metadataId: string | null;
              slug: string | null;
              title: string;
              description: string | null;
              image: string | null;
              type: string | null;
              duration: number | null;
              humanDuration: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              humanBroadcastShortDateTime: string | null;
              ratio: Ratio | null;
              live: boolean | null;
              program: { __typename: 'Program'; nid: string } | null;
            } | null;
          }
        | {
            __typename: 'FactBoxData';
            title: string | null;
            body: any | null;
            data: Array<{
              __typename: 'NodeData';
              id: string;
              data:
                | { __typename: 'BalanceOfPowerData' }
                | {
                    __typename: 'EditorialBannerData';
                    bannerTitle: string;
                    bannerText: string;
                    buttonText: string;
                    buttonLink: string;
                    bannerImage: {
                      __typename: 'EditorialBannerImageAsset';
                      title: string | null;
                      description: string | null;
                      url: string;
                    } | null;
                  }
                | {
                    __typename: 'ExtendedVideoAssetData';
                    headline: string | null;
                    text: string | null;
                    isQuote: boolean | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | { __typename: 'FactBoxData' }
                | { __typename: 'HyperLinkData'; url: string | null }
                | {
                    __typename: 'ImageData';
                    image: {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    } | null;
                  }
                | {
                    __typename: 'ImageSliderData';
                    images: Array<{
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    }>;
                  }
                | { __typename: 'PollData'; directLink: string }
                | { __typename: 'SportStandingsTableData' }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    metadataId: string | null;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | {
                    __typename: 'YouTubeData';
                    videoId: string | null;
                    playlistId: string | null;
                    startPositionInSeconds: number | null;
                  };
            }>;
          }
        | { __typename: 'HyperLinkData'; url: string | null }
        | {
            __typename: 'ImageData';
            image: {
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            } | null;
          }
        | {
            __typename: 'ImageSliderData';
            images: Array<{
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            }>;
          }
        | { __typename: 'PollData'; directLink: string }
        | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
        | {
            __typename: 'VideoPlayerData';
            id: string;
            metadataId: string | null;
            caption: string | null;
            thumbnail: string | null;
            thumbnailByline: string | null;
            asset: {
              __typename: 'VideoAsset';
              id: string;
              metadataId: string | null;
              slug: string | null;
              title: string;
              description: string | null;
              image: string | null;
              type: string | null;
              duration: number | null;
              humanDuration: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              humanBroadcastShortDateTime: string | null;
              ratio: Ratio | null;
              live: boolean | null;
              program: { __typename: 'Program'; nid: string } | null;
            } | null;
          }
        | {
            __typename: 'YouTubeData';
            videoId: string | null;
            playlistId: string | null;
            startPositionInSeconds: number | null;
          };
    }>;
    metadata: {
      __typename: 'ArticleMetadata';
      id: string;
      wordCount: number | null;
      linkCount: number | null;
      imageCount: number | null;
      videoCount: number | null;
    } | null;
    featuredAsset:
      | {
          __typename: 'Image';
          id: string;
          url: string | null;
          byline: string | null;
          caption: string | null;
          ratio: Ratio | null;
          linkUrl: string | null;
        }
      | {
          __typename: 'VideoPlayerData';
          id: string;
          metadataId: string | null;
          caption: string | null;
          thumbnail: string | null;
          thumbnailByline: string | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
      | null;
    related: Array<
      | {
          __typename: 'RelatedArticle';
          id: string;
          title: string;
          slug: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanUpdatedDateTime: string | null;
          updatedDateTime: string | null;
          lead: string | null;
          articleLeadRichText: any | null;
          liveReportId: string | null;
          bylineName: string | null;
          bylineEmail: string | null;
          body: any | null;
          isAdsHidden: boolean;
          isSponsoredArticle: boolean;
          byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
          featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
          metadata: {
            __typename: 'ArticleMetadata';
            id: string;
            wordCount: number | null;
            linkCount: number | null;
            imageCount: number | null;
            videoCount: number | null;
          } | null;
          data: Array<{
            __typename: 'NodeData';
            id: string;
            data:
              | { __typename: 'BalanceOfPowerData'; id: string }
              | {
                  __typename: 'EditorialBannerData';
                  bannerTitle: string;
                  bannerText: string;
                  buttonText: string;
                  buttonLink: string;
                  bannerImage: {
                    __typename: 'EditorialBannerImageAsset';
                    title: string | null;
                    description: string | null;
                    url: string;
                  } | null;
                }
              | {
                  __typename: 'ExtendedVideoAssetData';
                  headline: string | null;
                  text: string | null;
                  isQuote: boolean | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | {
                  __typename: 'FactBoxData';
                  title: string | null;
                  body: any | null;
                  data: Array<{
                    __typename: 'NodeData';
                    id: string;
                    data:
                      | { __typename: 'BalanceOfPowerData' }
                      | {
                          __typename: 'EditorialBannerData';
                          bannerTitle: string;
                          bannerText: string;
                          buttonText: string;
                          buttonLink: string;
                          bannerImage: {
                            __typename: 'EditorialBannerImageAsset';
                            title: string | null;
                            description: string | null;
                            url: string;
                          } | null;
                        }
                      | {
                          __typename: 'ExtendedVideoAssetData';
                          headline: string | null;
                          text: string | null;
                          isQuote: boolean | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            metadataId: string | null;
                            slug: string | null;
                            title: string;
                            description: string | null;
                            image: string | null;
                            type: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            live: boolean | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | { __typename: 'FactBoxData' }
                      | { __typename: 'HyperLinkData'; url: string | null }
                      | {
                          __typename: 'ImageData';
                          image: {
                            __typename: 'Image';
                            id: string;
                            url: string | null;
                            byline: string | null;
                            caption: string | null;
                            ratio: Ratio | null;
                            linkUrl: string | null;
                          } | null;
                        }
                      | {
                          __typename: 'ImageSliderData';
                          images: Array<{
                            __typename: 'Image';
                            id: string;
                            url: string | null;
                            byline: string | null;
                            caption: string | null;
                            ratio: Ratio | null;
                            linkUrl: string | null;
                          }>;
                        }
                      | { __typename: 'PollData'; directLink: string }
                      | { __typename: 'SportStandingsTableData' }
                      | {
                          __typename: 'VideoPlayerData';
                          id: string;
                          metadataId: string | null;
                          caption: string | null;
                          thumbnail: string | null;
                          thumbnailByline: string | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            metadataId: string | null;
                            slug: string | null;
                            title: string;
                            description: string | null;
                            image: string | null;
                            type: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            live: boolean | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | {
                          __typename: 'YouTubeData';
                          videoId: string | null;
                          playlistId: string | null;
                          startPositionInSeconds: number | null;
                        };
                  }>;
                }
              | { __typename: 'HyperLinkData'; url: string | null }
              | {
                  __typename: 'ImageData';
                  image: {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  } | null;
                }
              | {
                  __typename: 'ImageSliderData';
                  images: Array<{
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  }>;
                }
              | { __typename: 'PollData'; directLink: string }
              | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  metadataId: string | null;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | {
                  __typename: 'YouTubeData';
                  videoId: string | null;
                  playlistId: string | null;
                  startPositionInSeconds: number | null;
                };
          }>;
          featuredAsset:
            | {
                __typename: 'Image';
                id: string;
                url: string | null;
                byline: string | null;
                caption: string | null;
                ratio: Ratio | null;
                linkUrl: string | null;
              }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | null;
          feedOrigins: Array<{
            __typename: 'FeedOrigin';
            type: FeedType;
            id: string;
            slug: string | null;
            title: string | null;
          }>;
        }
      | {
          __typename: 'VideoPlayerData';
          id: string;
          metadataId: string | null;
          caption: string | null;
          thumbnail: string | null;
          thumbnailByline: string | null;
          asset: {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          } | null;
        }
    >;
    feedOrigins: Array<{
      __typename: 'FeedOrigin';
      type: FeedType;
      id: string;
      slug: string | null;
      title: string | null;
    }>;
  }>;
};

export type BreakingNewsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BreakingNewsQuery = {
  __typename: 'Query';
  breakingNews: Array<{
    __typename: 'BreakingNews';
    id: string;
    title: string | null;
    publishedAt: string | null;
    humanPublishedAt: string | null;
    firstPublishedAt: string | null;
    humanFirstPublishedAt: string | null;
    articleId: string | null;
    bylineName: string | null;
    bylineEmail: string | null;
    body: any | null;
    byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
    data: Array<{
      __typename: 'NodeData';
      id: string;
      data:
        | { __typename: 'BalanceOfPowerData'; id: string }
        | {
            __typename: 'EditorialBannerData';
            bannerTitle: string;
            bannerText: string;
            buttonText: string;
            buttonLink: string;
            bannerImage: {
              __typename: 'EditorialBannerImageAsset';
              title: string | null;
              description: string | null;
              url: string;
            } | null;
          }
        | {
            __typename: 'ExtendedVideoAssetData';
            headline: string | null;
            text: string | null;
            isQuote: boolean | null;
            asset: {
              __typename: 'VideoAsset';
              id: string;
              metadataId: string | null;
              slug: string | null;
              title: string;
              description: string | null;
              image: string | null;
              type: string | null;
              duration: number | null;
              humanDuration: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              humanBroadcastShortDateTime: string | null;
              ratio: Ratio | null;
              live: boolean | null;
              program: { __typename: 'Program'; nid: string } | null;
            } | null;
          }
        | {
            __typename: 'FactBoxData';
            title: string | null;
            body: any | null;
            data: Array<{
              __typename: 'NodeData';
              id: string;
              data:
                | { __typename: 'BalanceOfPowerData' }
                | {
                    __typename: 'EditorialBannerData';
                    bannerTitle: string;
                    bannerText: string;
                    buttonText: string;
                    buttonLink: string;
                    bannerImage: {
                      __typename: 'EditorialBannerImageAsset';
                      title: string | null;
                      description: string | null;
                      url: string;
                    } | null;
                  }
                | {
                    __typename: 'ExtendedVideoAssetData';
                    headline: string | null;
                    text: string | null;
                    isQuote: boolean | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | { __typename: 'FactBoxData' }
                | { __typename: 'HyperLinkData'; url: string | null }
                | {
                    __typename: 'ImageData';
                    image: {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    } | null;
                  }
                | {
                    __typename: 'ImageSliderData';
                    images: Array<{
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    }>;
                  }
                | { __typename: 'PollData'; directLink: string }
                | { __typename: 'SportStandingsTableData' }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    metadataId: string | null;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | {
                    __typename: 'YouTubeData';
                    videoId: string | null;
                    playlistId: string | null;
                    startPositionInSeconds: number | null;
                  };
            }>;
          }
        | { __typename: 'HyperLinkData'; url: string | null }
        | {
            __typename: 'ImageData';
            image: {
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            } | null;
          }
        | {
            __typename: 'ImageSliderData';
            images: Array<{
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            }>;
          }
        | { __typename: 'PollData'; directLink: string }
        | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
        | {
            __typename: 'VideoPlayerData';
            id: string;
            metadataId: string | null;
            caption: string | null;
            thumbnail: string | null;
            thumbnailByline: string | null;
            asset: {
              __typename: 'VideoAsset';
              id: string;
              metadataId: string | null;
              slug: string | null;
              title: string;
              description: string | null;
              image: string | null;
              type: string | null;
              duration: number | null;
              humanDuration: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              humanBroadcastShortDateTime: string | null;
              ratio: Ratio | null;
              live: boolean | null;
              program: { __typename: 'Program'; nid: string } | null;
            } | null;
          }
        | {
            __typename: 'YouTubeData';
            videoId: string | null;
            playlistId: string | null;
            startPositionInSeconds: number | null;
          };
    } | null> | null;
  }>;
};

export type NewsFeedCategorizerForPluginQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type NewsFeedCategorizerForPluginQuery = {
  __typename: 'Query';
  newsFeedCategorizerForPlugin: {
    __typename: 'NewsFeedCategorizerForPlugin';
    categories: Array<{ __typename: 'NewsFeedForPlugin'; id: string; title: string }>;
    subcategories: Array<{ __typename: 'NewsFeedForPlugin'; id: string; title: string }>;
    tags: Array<{ __typename: 'NewsFeedForPlugin'; id: string; title: string }>;
  };
};

export type NewsFeedItemsForPluginQueryVariables = Exact<{
  autoPopulateFeedOptions: AutoPopulateFeedOptions;
  feedId: Scalars['String']['input'];
  videoSearchInput: VideoSearchInput;
  curatedFeed: Scalars['JSON']['input'];
}>;

export type NewsFeedItemsForPluginQuery = {
  __typename: 'Query';
  newsFeedItemsForPlugin: Array<{
    __typename: 'FeedItemForPlugin';
    additionReason: AdditionReason;
    newsFeedItem:
      | { __typename: 'Article'; id: string; title: string; live: boolean | null }
      | { __typename: 'ArticleGroup' }
      | { __typename: 'BreakingNewsModule'; id: string }
      | { __typename: 'LiveReport'; id: string; title: string }
      | { __typename: 'MediaSlider'; id: string; title: string }
      | { __typename: 'ShortsSlider' }
      | { __typename: 'SportResultsModule'; id: string }
      | { __typename: 'Upsell'; id: string; title: string }
      | { __typename: 'VideoAsset'; id: string; title: string }
      | { __typename: 'VideoPlayerData'; id: string; title: string };
  }>;
};

export type NewsFeedLinkHierarchyForPluginQueryVariables = Exact<{ [key: string]: never }>;

export type NewsFeedLinkHierarchyForPluginQuery = {
  __typename: 'Query';
  newsFeedLinkHierarchyForPlugin: Array<{
    __typename: 'NewsFeedLinkHierarchyForPlugin';
    id: string;
    title: string;
    category: { __typename: 'NewsFeedForPlugin'; id: string; title: string } | null;
    subcategories: Array<{ __typename: 'NewsFeedForPlugin'; id: string; title: string }>;
  }>;
};

export type NewsFeedLinkHierarchyForPluginFieldsFragment = {
  __typename: 'NewsFeedLinkHierarchyForPlugin';
  id: string;
  title: string;
  category: { __typename: 'NewsFeedForPlugin'; id: string; title: string } | null;
  subcategories: Array<{ __typename: 'NewsFeedForPlugin'; id: string; title: string }>;
};

export type NewsFeedTagSearchForPluginQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type NewsFeedTagSearchForPluginQuery = {
  __typename: 'Query';
  newsFeedTagSearchForPlugin: Array<{ __typename: 'NewsFeedForPlugin'; id: string; title: string }>;
};

export type ShortVideoSearchForPluginQueryVariables = Exact<{
  tags: InputMaybe<Scalars['String']['input']>;
}>;

export type ShortVideoSearchForPluginQuery = {
  __typename: 'Query';
  shortVideoSearch: {
    __typename: 'ShortVideoSearch';
    total_hits: number;
    from: number;
    to: number;
    hits: Array<{
      __typename: 'ShortVideoSearchItem';
      id: string;
      title: string | null;
      publishedAt: string | null;
      metadataId: string | null;
      image: string | null;
      thumbnail: string | null;
    }>;
  };
};

export type FeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type FeatureFlagsQuery = {
  __typename: 'Query';
  featureFlags: Array<{ __typename: 'FeatureFlag'; id: string; key: string; active: boolean }>;
};

export type FeedHeroQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type FeedHeroQuery = {
  __typename: 'Query';
  paginatedNewsFeed: {
    __typename: 'PaginatedNewsFeed';
    id: string;
    hero: {
      __typename: 'Hero';
      id: string;
      title: string | null;
      image: string | null;
      heroImage: string | null;
      description: string | null;
      shortDescription: string | null;
      twitterUrl: string | null;
      facebookUrl: string | null;
      instagramUrl: string | null;
      program: { __typename: 'HeroProgram'; nid: string | null; id: string | null } | null;
    } | null;
  } | null;
};

export type LatestBreakingNewsQueryVariables = Exact<{
  skip: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
}>;

export type LatestBreakingNewsQuery = {
  __typename: 'Query';
  latestBreakingNews: {
    __typename: 'LatestBreakingNews';
    total: number;
    items: Array<{
      __typename: 'BreakingNews';
      id: string;
      title: string | null;
      publishedAt: string | null;
      humanPublishedAt: string | null;
      firstPublishedAt: string | null;
      humanFirstPublishedAt: string | null;
      articleId: string | null;
      bylineName: string | null;
      bylineEmail: string | null;
      body: any | null;
      byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
      data: Array<{
        __typename: 'NodeData';
        id: string;
        data:
          | { __typename: 'BalanceOfPowerData'; id: string }
          | {
              __typename: 'EditorialBannerData';
              bannerTitle: string;
              bannerText: string;
              buttonText: string;
              buttonLink: string;
              bannerImage: {
                __typename: 'EditorialBannerImageAsset';
                title: string | null;
                description: string | null;
                url: string;
              } | null;
            }
          | {
              __typename: 'ExtendedVideoAssetData';
              headline: string | null;
              text: string | null;
              isQuote: boolean | null;
              asset: {
                __typename: 'VideoAsset';
                id: string;
                metadataId: string | null;
                slug: string | null;
                title: string;
                description: string | null;
                image: string | null;
                type: string | null;
                duration: number | null;
                humanDuration: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanBroadcastShortDateTime: string | null;
                ratio: Ratio | null;
                live: boolean | null;
                program: { __typename: 'Program'; nid: string } | null;
              } | null;
            }
          | {
              __typename: 'FactBoxData';
              title: string | null;
              body: any | null;
              data: Array<{
                __typename: 'NodeData';
                id: string;
                data:
                  | { __typename: 'BalanceOfPowerData' }
                  | {
                      __typename: 'EditorialBannerData';
                      bannerTitle: string;
                      bannerText: string;
                      buttonText: string;
                      buttonLink: string;
                      bannerImage: {
                        __typename: 'EditorialBannerImageAsset';
                        title: string | null;
                        description: string | null;
                        url: string;
                      } | null;
                    }
                  | {
                      __typename: 'ExtendedVideoAssetData';
                      headline: string | null;
                      text: string | null;
                      isQuote: boolean | null;
                      asset: {
                        __typename: 'VideoAsset';
                        id: string;
                        metadataId: string | null;
                        slug: string | null;
                        title: string;
                        description: string | null;
                        image: string | null;
                        type: string | null;
                        duration: number | null;
                        humanDuration: string | null;
                        publishedDateTime: string | null;
                        humanPublishedDateTime: string | null;
                        humanBroadcastShortDateTime: string | null;
                        ratio: Ratio | null;
                        live: boolean | null;
                        program: { __typename: 'Program'; nid: string } | null;
                      } | null;
                    }
                  | { __typename: 'FactBoxData' }
                  | { __typename: 'HyperLinkData'; url: string | null }
                  | {
                      __typename: 'ImageData';
                      image: {
                        __typename: 'Image';
                        id: string;
                        url: string | null;
                        byline: string | null;
                        caption: string | null;
                        ratio: Ratio | null;
                        linkUrl: string | null;
                      } | null;
                    }
                  | {
                      __typename: 'ImageSliderData';
                      images: Array<{
                        __typename: 'Image';
                        id: string;
                        url: string | null;
                        byline: string | null;
                        caption: string | null;
                        ratio: Ratio | null;
                        linkUrl: string | null;
                      }>;
                    }
                  | { __typename: 'PollData'; directLink: string }
                  | { __typename: 'SportStandingsTableData' }
                  | {
                      __typename: 'VideoPlayerData';
                      id: string;
                      metadataId: string | null;
                      caption: string | null;
                      thumbnail: string | null;
                      thumbnailByline: string | null;
                      asset: {
                        __typename: 'VideoAsset';
                        id: string;
                        metadataId: string | null;
                        slug: string | null;
                        title: string;
                        description: string | null;
                        image: string | null;
                        type: string | null;
                        duration: number | null;
                        humanDuration: string | null;
                        publishedDateTime: string | null;
                        humanPublishedDateTime: string | null;
                        humanBroadcastShortDateTime: string | null;
                        ratio: Ratio | null;
                        live: boolean | null;
                        program: { __typename: 'Program'; nid: string } | null;
                      } | null;
                    }
                  | {
                      __typename: 'YouTubeData';
                      videoId: string | null;
                      playlistId: string | null;
                      startPositionInSeconds: number | null;
                    };
              }>;
            }
          | { __typename: 'HyperLinkData'; url: string | null }
          | {
              __typename: 'ImageData';
              image: {
                __typename: 'Image';
                id: string;
                url: string | null;
                byline: string | null;
                caption: string | null;
                ratio: Ratio | null;
                linkUrl: string | null;
              } | null;
            }
          | {
              __typename: 'ImageSliderData';
              images: Array<{
                __typename: 'Image';
                id: string;
                url: string | null;
                byline: string | null;
                caption: string | null;
                ratio: Ratio | null;
                linkUrl: string | null;
              }>;
            }
          | { __typename: 'PollData'; directLink: string }
          | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
          | {
              __typename: 'VideoPlayerData';
              id: string;
              metadataId: string | null;
              caption: string | null;
              thumbnail: string | null;
              thumbnailByline: string | null;
              asset: {
                __typename: 'VideoAsset';
                id: string;
                metadataId: string | null;
                slug: string | null;
                title: string;
                description: string | null;
                image: string | null;
                type: string | null;
                duration: number | null;
                humanDuration: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanBroadcastShortDateTime: string | null;
                ratio: Ratio | null;
                live: boolean | null;
                program: { __typename: 'Program'; nid: string } | null;
              } | null;
            }
          | {
              __typename: 'YouTubeData';
              videoId: string | null;
              playlistId: string | null;
              startPositionInSeconds: number | null;
            };
      } | null> | null;
    }>;
  };
};

export type LatestBreakingNewsFieldsFragment = {
  __typename: 'LatestBreakingNews';
  total: number;
  items: Array<{
    __typename: 'BreakingNews';
    id: string;
    title: string | null;
    publishedAt: string | null;
    humanPublishedAt: string | null;
    firstPublishedAt: string | null;
    humanFirstPublishedAt: string | null;
    articleId: string | null;
    bylineName: string | null;
    bylineEmail: string | null;
    body: any | null;
    byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
    data: Array<{
      __typename: 'NodeData';
      id: string;
      data:
        | { __typename: 'BalanceOfPowerData'; id: string }
        | {
            __typename: 'EditorialBannerData';
            bannerTitle: string;
            bannerText: string;
            buttonText: string;
            buttonLink: string;
            bannerImage: {
              __typename: 'EditorialBannerImageAsset';
              title: string | null;
              description: string | null;
              url: string;
            } | null;
          }
        | {
            __typename: 'ExtendedVideoAssetData';
            headline: string | null;
            text: string | null;
            isQuote: boolean | null;
            asset: {
              __typename: 'VideoAsset';
              id: string;
              metadataId: string | null;
              slug: string | null;
              title: string;
              description: string | null;
              image: string | null;
              type: string | null;
              duration: number | null;
              humanDuration: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              humanBroadcastShortDateTime: string | null;
              ratio: Ratio | null;
              live: boolean | null;
              program: { __typename: 'Program'; nid: string } | null;
            } | null;
          }
        | {
            __typename: 'FactBoxData';
            title: string | null;
            body: any | null;
            data: Array<{
              __typename: 'NodeData';
              id: string;
              data:
                | { __typename: 'BalanceOfPowerData' }
                | {
                    __typename: 'EditorialBannerData';
                    bannerTitle: string;
                    bannerText: string;
                    buttonText: string;
                    buttonLink: string;
                    bannerImage: {
                      __typename: 'EditorialBannerImageAsset';
                      title: string | null;
                      description: string | null;
                      url: string;
                    } | null;
                  }
                | {
                    __typename: 'ExtendedVideoAssetData';
                    headline: string | null;
                    text: string | null;
                    isQuote: boolean | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | { __typename: 'FactBoxData' }
                | { __typename: 'HyperLinkData'; url: string | null }
                | {
                    __typename: 'ImageData';
                    image: {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    } | null;
                  }
                | {
                    __typename: 'ImageSliderData';
                    images: Array<{
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    }>;
                  }
                | { __typename: 'PollData'; directLink: string }
                | { __typename: 'SportStandingsTableData' }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    metadataId: string | null;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | {
                    __typename: 'YouTubeData';
                    videoId: string | null;
                    playlistId: string | null;
                    startPositionInSeconds: number | null;
                  };
            }>;
          }
        | { __typename: 'HyperLinkData'; url: string | null }
        | {
            __typename: 'ImageData';
            image: {
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            } | null;
          }
        | {
            __typename: 'ImageSliderData';
            images: Array<{
              __typename: 'Image';
              id: string;
              url: string | null;
              byline: string | null;
              caption: string | null;
              ratio: Ratio | null;
              linkUrl: string | null;
            }>;
          }
        | { __typename: 'PollData'; directLink: string }
        | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
        | {
            __typename: 'VideoPlayerData';
            id: string;
            metadataId: string | null;
            caption: string | null;
            thumbnail: string | null;
            thumbnailByline: string | null;
            asset: {
              __typename: 'VideoAsset';
              id: string;
              metadataId: string | null;
              slug: string | null;
              title: string;
              description: string | null;
              image: string | null;
              type: string | null;
              duration: number | null;
              humanDuration: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              humanBroadcastShortDateTime: string | null;
              ratio: Ratio | null;
              live: boolean | null;
              program: { __typename: 'Program'; nid: string } | null;
            } | null;
          }
        | {
            __typename: 'YouTubeData';
            videoId: string | null;
            playlistId: string | null;
            startPositionInSeconds: number | null;
          };
    } | null> | null;
  }>;
};

export type ListNewsFeedsQueryVariables = Exact<{ [key: string]: never }>;

export type ListNewsFeedsQuery = {
  __typename: 'Query';
  listNewsFeeds: Array<{
    __typename: 'FeedUrlData';
    id: string;
    slug: string;
    title: string;
    updatedAt: string;
    publishedAt: string;
  }>;
};

export type MenuQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MenuQuery = {
  __typename: 'Query';
  menu: {
    __typename: 'Menu';
    id: string;
    groups: Array<{
      __typename: 'Group';
      id: string;
      name: string | null;
      links: Array<
        | {
            __typename: 'Link';
            id: string;
            linkId: string;
            title: string | null;
            href: string | null;
            icon: string | null;
            targetType: string | null;
          }
        | {
            __typename: 'LinkHierarchy';
            id: string;
            icon: string | null;
            categoryTitle: string;
            parentLink: {
              __typename: 'Link';
              id: string;
              linkId: string;
              title: string | null;
              href: string | null;
              icon: string | null;
              targetType: string | null;
            } | null;
            childLinks: Array<{
              __typename: 'Link';
              id: string;
              linkId: string;
              title: string | null;
              href: string | null;
              icon: string | null;
              targetType: string | null;
            }>;
          }
      >;
    }>;
  } | null;
};

export type GroupFieldsFragment = {
  __typename: 'Group';
  id: string;
  name: string | null;
  links: Array<
    | {
        __typename: 'Link';
        id: string;
        linkId: string;
        title: string | null;
        href: string | null;
        icon: string | null;
        targetType: string | null;
      }
    | {
        __typename: 'LinkHierarchy';
        id: string;
        icon: string | null;
        categoryTitle: string;
        parentLink: {
          __typename: 'Link';
          id: string;
          linkId: string;
          title: string | null;
          href: string | null;
          icon: string | null;
          targetType: string | null;
        } | null;
        childLinks: Array<{
          __typename: 'Link';
          id: string;
          linkId: string;
          title: string | null;
          href: string | null;
          icon: string | null;
          targetType: string | null;
        }>;
      }
  >;
};

export type LinkHierarchyFieldsFragment = {
  __typename: 'LinkHierarchy';
  id: string;
  icon: string | null;
  categoryTitle: string;
  parentLink: {
    __typename: 'Link';
    id: string;
    linkId: string;
    title: string | null;
    href: string | null;
    icon: string | null;
    targetType: string | null;
  } | null;
  childLinks: Array<{
    __typename: 'Link';
    id: string;
    linkId: string;
    title: string | null;
    href: string | null;
    icon: string | null;
    targetType: string | null;
  }>;
};

export type LinkFieldsFragment = {
  __typename: 'Link';
  id: string;
  linkId: string;
  title: string | null;
  href: string | null;
  icon: string | null;
  targetType: string | null;
};

export type NordicCmsPanelQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type NordicCmsPanelQuery = {
  __typename: 'Query';
  nordicCmsPanel: {
    __typename: 'NordicCmsPanel';
    id: string;
    link: string;
    pagingMeta: {
      __typename: 'PagingMeta';
      hasNextPage: boolean;
      nextPageOffset: number | null;
      totalCount: number;
    } | null;
    items: Array<{
      __typename: 'MediaPanelItem';
      id: string;
      title: string;
      type: MediaPanelItemType;
      link: string;
      image: { __typename: 'Image'; url: string | null; ratio: Ratio | null };
    }>;
  } | null;
};

export type PaginatedFeedQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  page: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  excludeVideoAssetList: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
}>;

export type PaginatedFeedQuery = {
  __typename: 'Query';
  paginatedNewsFeed: {
    __typename: 'PaginatedNewsFeed';
    id: string;
    slug: string | null;
    redirect: string | null;
    title: string | null;
    metaTitle: string | null;
    metaDescription: string | null;
    ogTitle: string | null;
    ogDescription: string | null;
    ogImage: string | null;
    main16x9Annotated: string | null;
    category: Category | null;
    publishedAt: string | null;
    modifiedAt: string | null;
    hero: {
      __typename: 'Hero';
      id: string;
      title: string | null;
      image: string | null;
      heroImage: string | null;
      description: string | null;
      shortDescription: string | null;
      twitterUrl: string | null;
      facebookUrl: string | null;
      instagramUrl: string | null;
      program: { __typename: 'HeroProgram'; nid: string | null; id: string | null } | null;
    } | null;
    emphasizedArticleGroup: {
      __typename: 'EmphasizedArticleGroup';
      content: Array<{
        __typename: 'ArticleGroup';
        id: string;
        title: string;
        isEmphasized: boolean;
        content: Array<
          | {
              __typename: 'ArticleGroupArticle';
              id: string;
              slug: string | null;
              title: string;
              live: boolean | null;
              lead: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              updatedDateTime: string | null;
              humanUpdatedDateTime: string | null;
              liveReportId: string | null;
              bylineName: string | null;
              bylineEmail: string | null;
              isOpinionPiece: boolean;
              isSponsoredArticle: boolean;
              featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
              feedOrigins: Array<{
                __typename: 'FeedOrigin';
                type: FeedType;
                id: string;
                slug: string | null;
                title: string | null;
              }>;
              byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
              featuredAsset:
                | {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    caption: string | null;
                    byline: string | null;
                    linkUrl: string | null;
                    ratio: Ratio | null;
                  }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    title: string;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      title: string;
                      slug: string | null;
                      description: string | null;
                      live: boolean | null;
                      type: string | null;
                      image: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | null;
            }
          | {
              __typename: 'ArticleGroupBlock';
              id: string;
              layout: Layout | null;
              imagePlacement: ImagePlacement | null;
              content: Array<{
                __typename: 'ArticleGroupArticle';
                id: string;
                slug: string | null;
                title: string;
                live: boolean | null;
                lead: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                updatedDateTime: string | null;
                humanUpdatedDateTime: string | null;
                liveReportId: string | null;
                bylineName: string | null;
                bylineEmail: string | null;
                isSponsoredArticle: boolean;
                feedOrigins: Array<{
                  __typename: 'FeedOrigin';
                  type: FeedType;
                  id: string;
                  slug: string | null;
                  title: string | null;
                }>;
                featuredAsset:
                  | {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      caption: string | null;
                      byline: string | null;
                      linkUrl: string | null;
                      ratio: Ratio | null;
                    }
                  | {
                      __typename: 'VideoPlayerData';
                      id: string;
                      title: string;
                      caption: string | null;
                      thumbnail: string | null;
                      thumbnailByline: string | null;
                      asset: {
                        __typename: 'VideoAsset';
                        id: string;
                        title: string;
                        slug: string | null;
                        description: string | null;
                        live: boolean | null;
                        type: string | null;
                        image: string | null;
                        duration: number | null;
                        humanDuration: string | null;
                        publishedDateTime: string | null;
                        humanPublishedDateTime: string | null;
                        humanBroadcastShortDateTime: string | null;
                        ratio: Ratio | null;
                        program: { __typename: 'Program'; nid: string } | null;
                      } | null;
                    }
                  | null;
              } | null>;
            }
          | null
        >;
      }>;
    } | null;
    feed: {
      __typename: 'PaginatedFeed';
      pagingData: { __typename: 'PagingData'; currentPage: number | null; nextPage: number | null } | null;
      items: Array<
        | {
            __typename: 'Article';
            id: string;
            title: string;
            slug: string | null;
            live: boolean | null;
            lead: string | null;
            liveReportId: string | null;
            isOpinionPiece: boolean;
            metaTitle: string | null;
            metaDescription: string | null;
            ogTitle: string | null;
            ogDescription: string | null;
            isAdsHidden: boolean;
            isSponsoredArticle: boolean;
            showPobInFeed: boolean;
            byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
            featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
            metadata: {
              __typename: 'ArticleMetadata';
              id: string;
              wordCount: number | null;
              linkCount: number | null;
              imageCount: number | null;
              videoCount: number | null;
            } | null;
            featuredAsset:
              | {
                  __typename: 'Image';
                  id: string;
                  url: string | null;
                  byline: string | null;
                  caption: string | null;
                  ratio: Ratio | null;
                  linkUrl: string | null;
                }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  metadataId: string | null;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | null;
            related: Array<
              | {
                  __typename: 'RelatedArticle';
                  id: string;
                  title: string;
                  slug: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanUpdatedDateTime: string | null;
                  updatedDateTime: string | null;
                  lead: string | null;
                  articleLeadRichText: any | null;
                  liveReportId: string | null;
                  bylineName: string | null;
                  bylineEmail: string | null;
                  body: any | null;
                  isAdsHidden: boolean;
                  isSponsoredArticle: boolean;
                  byline: Array<{
                    __typename: 'Byline';
                    name: string;
                    email: string | null;
                    bylineImage: string | null;
                  }>;
                  featuredFeed: {
                    __typename: 'FeedOrigin';
                    id: string;
                    slug: string | null;
                    title: string | null;
                  } | null;
                  metadata: {
                    __typename: 'ArticleMetadata';
                    id: string;
                    wordCount: number | null;
                    linkCount: number | null;
                    imageCount: number | null;
                    videoCount: number | null;
                  } | null;
                  data: Array<{
                    __typename: 'NodeData';
                    id: string;
                    data:
                      | { __typename: 'BalanceOfPowerData'; id: string }
                      | {
                          __typename: 'EditorialBannerData';
                          bannerTitle: string;
                          bannerText: string;
                          buttonText: string;
                          buttonLink: string;
                          bannerImage: {
                            __typename: 'EditorialBannerImageAsset';
                            title: string | null;
                            description: string | null;
                            url: string;
                          } | null;
                        }
                      | {
                          __typename: 'ExtendedVideoAssetData';
                          headline: string | null;
                          text: string | null;
                          isQuote: boolean | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            metadataId: string | null;
                            slug: string | null;
                            title: string;
                            description: string | null;
                            image: string | null;
                            type: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            live: boolean | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | {
                          __typename: 'FactBoxData';
                          title: string | null;
                          body: any | null;
                          data: Array<{
                            __typename: 'NodeData';
                            id: string;
                            data:
                              | { __typename: 'BalanceOfPowerData' }
                              | {
                                  __typename: 'EditorialBannerData';
                                  bannerTitle: string;
                                  bannerText: string;
                                  buttonText: string;
                                  buttonLink: string;
                                  bannerImage: {
                                    __typename: 'EditorialBannerImageAsset';
                                    title: string | null;
                                    description: string | null;
                                    url: string;
                                  } | null;
                                }
                              | {
                                  __typename: 'ExtendedVideoAssetData';
                                  headline: string | null;
                                  text: string | null;
                                  isQuote: boolean | null;
                                  asset: {
                                    __typename: 'VideoAsset';
                                    id: string;
                                    metadataId: string | null;
                                    slug: string | null;
                                    title: string;
                                    description: string | null;
                                    image: string | null;
                                    type: string | null;
                                    duration: number | null;
                                    humanDuration: string | null;
                                    publishedDateTime: string | null;
                                    humanPublishedDateTime: string | null;
                                    humanBroadcastShortDateTime: string | null;
                                    ratio: Ratio | null;
                                    live: boolean | null;
                                    program: { __typename: 'Program'; nid: string } | null;
                                  } | null;
                                }
                              | { __typename: 'FactBoxData' }
                              | { __typename: 'HyperLinkData'; url: string | null }
                              | {
                                  __typename: 'ImageData';
                                  image: {
                                    __typename: 'Image';
                                    id: string;
                                    url: string | null;
                                    byline: string | null;
                                    caption: string | null;
                                    ratio: Ratio | null;
                                    linkUrl: string | null;
                                  } | null;
                                }
                              | {
                                  __typename: 'ImageSliderData';
                                  images: Array<{
                                    __typename: 'Image';
                                    id: string;
                                    url: string | null;
                                    byline: string | null;
                                    caption: string | null;
                                    ratio: Ratio | null;
                                    linkUrl: string | null;
                                  }>;
                                }
                              | { __typename: 'PollData'; directLink: string }
                              | { __typename: 'SportStandingsTableData' }
                              | {
                                  __typename: 'VideoPlayerData';
                                  id: string;
                                  metadataId: string | null;
                                  caption: string | null;
                                  thumbnail: string | null;
                                  thumbnailByline: string | null;
                                  asset: {
                                    __typename: 'VideoAsset';
                                    id: string;
                                    metadataId: string | null;
                                    slug: string | null;
                                    title: string;
                                    description: string | null;
                                    image: string | null;
                                    type: string | null;
                                    duration: number | null;
                                    humanDuration: string | null;
                                    publishedDateTime: string | null;
                                    humanPublishedDateTime: string | null;
                                    humanBroadcastShortDateTime: string | null;
                                    ratio: Ratio | null;
                                    live: boolean | null;
                                    program: { __typename: 'Program'; nid: string } | null;
                                  } | null;
                                }
                              | {
                                  __typename: 'YouTubeData';
                                  videoId: string | null;
                                  playlistId: string | null;
                                  startPositionInSeconds: number | null;
                                };
                          }>;
                        }
                      | { __typename: 'HyperLinkData'; url: string | null }
                      | {
                          __typename: 'ImageData';
                          image: {
                            __typename: 'Image';
                            id: string;
                            url: string | null;
                            byline: string | null;
                            caption: string | null;
                            ratio: Ratio | null;
                            linkUrl: string | null;
                          } | null;
                        }
                      | {
                          __typename: 'ImageSliderData';
                          images: Array<{
                            __typename: 'Image';
                            id: string;
                            url: string | null;
                            byline: string | null;
                            caption: string | null;
                            ratio: Ratio | null;
                            linkUrl: string | null;
                          }>;
                        }
                      | { __typename: 'PollData'; directLink: string }
                      | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
                      | {
                          __typename: 'VideoPlayerData';
                          id: string;
                          metadataId: string | null;
                          caption: string | null;
                          thumbnail: string | null;
                          thumbnailByline: string | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            metadataId: string | null;
                            slug: string | null;
                            title: string;
                            description: string | null;
                            image: string | null;
                            type: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            live: boolean | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | {
                          __typename: 'YouTubeData';
                          videoId: string | null;
                          playlistId: string | null;
                          startPositionInSeconds: number | null;
                        };
                  }>;
                  featuredAsset:
                    | {
                        __typename: 'Image';
                        id: string;
                        url: string | null;
                        byline: string | null;
                        caption: string | null;
                        ratio: Ratio | null;
                        linkUrl: string | null;
                      }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        metadataId: string | null;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | null;
                  feedOrigins: Array<{
                    __typename: 'FeedOrigin';
                    type: FeedType;
                    id: string;
                    slug: string | null;
                    title: string | null;
                  }>;
                }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  metadataId: string | null;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
            >;
            feedOrigins: Array<{
              __typename: 'FeedOrigin';
              type: FeedType;
              id: string;
              slug: string | null;
              title: string | null;
            }>;
          }
        | {
            __typename: 'ArticleGroup';
            id: string;
            title: string;
            isEmphasized: boolean;
            content: Array<
              | {
                  __typename: 'ArticleGroupArticle';
                  id: string;
                  slug: string | null;
                  title: string;
                  live: boolean | null;
                  lead: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  updatedDateTime: string | null;
                  humanUpdatedDateTime: string | null;
                  liveReportId: string | null;
                  bylineName: string | null;
                  bylineEmail: string | null;
                  isOpinionPiece: boolean;
                  isSponsoredArticle: boolean;
                  featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
                  feedOrigins: Array<{
                    __typename: 'FeedOrigin';
                    type: FeedType;
                    id: string;
                    slug: string | null;
                    title: string | null;
                  }>;
                  byline: Array<{
                    __typename: 'Byline';
                    name: string;
                    email: string | null;
                    bylineImage: string | null;
                  }>;
                  featuredAsset:
                    | {
                        __typename: 'Image';
                        id: string;
                        url: string | null;
                        caption: string | null;
                        byline: string | null;
                        linkUrl: string | null;
                        ratio: Ratio | null;
                      }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        title: string;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          title: string;
                          slug: string | null;
                          description: string | null;
                          live: boolean | null;
                          type: string | null;
                          image: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | null;
                }
              | {
                  __typename: 'ArticleGroupBlock';
                  id: string;
                  layout: Layout | null;
                  imagePlacement: ImagePlacement | null;
                  content: Array<{
                    __typename: 'ArticleGroupArticle';
                    id: string;
                    slug: string | null;
                    title: string;
                    live: boolean | null;
                    lead: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    updatedDateTime: string | null;
                    humanUpdatedDateTime: string | null;
                    liveReportId: string | null;
                    bylineName: string | null;
                    bylineEmail: string | null;
                    isSponsoredArticle: boolean;
                    feedOrigins: Array<{
                      __typename: 'FeedOrigin';
                      type: FeedType;
                      id: string;
                      slug: string | null;
                      title: string | null;
                    }>;
                    featuredAsset:
                      | {
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          caption: string | null;
                          byline: string | null;
                          linkUrl: string | null;
                          ratio: Ratio | null;
                        }
                      | {
                          __typename: 'VideoPlayerData';
                          id: string;
                          title: string;
                          caption: string | null;
                          thumbnail: string | null;
                          thumbnailByline: string | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            title: string;
                            slug: string | null;
                            description: string | null;
                            live: boolean | null;
                            type: string | null;
                            image: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | null;
                  } | null>;
                }
              | null
            >;
          }
        | { __typename: 'BreakingNewsModule'; id: string }
        | { __typename: 'LiveReport'; id: string; liveReportId: string | null; title: string; text: string | null }
        | { __typename: 'MediaSlider'; id: string; title: string; nordicCmsPanelId: string }
        | {
            __typename: 'ShortsSlider';
            id: string;
            title: string;
            items: Array<{
              __typename: 'ShortsSliderContent';
              id: string;
              title: string;
              metadataId: string;
              thumbnail: string;
              vimondId: string;
              duration: number | null;
              relativePublishDate: string | null;
            } | null> | null;
          }
        | { __typename: 'SportResultsModule'; id: string }
        | {
            __typename: 'Upsell';
            id: string;
            title: string;
            subtitle: string | null;
            backgroundColor: string | null;
            linkUrl: string;
            linkText: string;
            label: string | null;
            imageData: {
              __typename: 'UpsellImage';
              id: string;
              url: string;
              metadata: { __typename: 'UpsellImageMetadata'; blurHash: string | null; hexColor: string | null } | null;
            };
          }
        | {
            __typename: 'VideoAsset';
            id: string;
            metadataId: string | null;
            slug: string | null;
            title: string;
            description: string | null;
            image: string | null;
            type: string | null;
            duration: number | null;
            humanDuration: string | null;
            publishedDateTime: string | null;
            humanPublishedDateTime: string | null;
            humanBroadcastShortDateTime: string | null;
            ratio: Ratio | null;
            live: boolean | null;
            program: { __typename: 'Program'; nid: string } | null;
          }
      >;
    } | null;
    sponsors: Array<{ __typename: 'Sponsor'; id: string; title: string; link: string; image: string }>;
  } | null;
};

export type PaginatedNewsFeedByItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  type: FeedItemType;
  page: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  excludeVideoAssetList: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
}>;

export type PaginatedNewsFeedByItemQuery = {
  __typename: 'Query';
  paginatedNewsFeedByItem: {
    __typename: 'PaginatedNewsFeedByItem';
    currentCategorySlug: string | null;
    item:
      | {
          __typename: 'Article';
          id: string;
          title: string;
          slug: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanUpdatedDateTime: string | null;
          updatedDateTime: string | null;
          live: boolean | null;
          lead: string | null;
          articleLeadRichText: any | null;
          liveReportId: string | null;
          bylineName: string | null;
          bylineEmail: string | null;
          isOpinionPiece: boolean;
          body: any | null;
          metaTitle: string | null;
          metaDescription: string | null;
          ogTitle: string | null;
          ogDescription: string | null;
          isAdsHidden: boolean;
          isSponsoredArticle: boolean;
          sponsoredByBanner: boolean;
          showPobInFeed: boolean;
          showElectionMap: boolean;
          byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
          featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
          data: Array<{
            __typename: 'NodeData';
            id: string;
            data:
              | { __typename: 'BalanceOfPowerData'; id: string }
              | {
                  __typename: 'EditorialBannerData';
                  bannerTitle: string;
                  bannerText: string;
                  buttonText: string;
                  buttonLink: string;
                  bannerImage: {
                    __typename: 'EditorialBannerImageAsset';
                    title: string | null;
                    description: string | null;
                    url: string;
                  } | null;
                }
              | {
                  __typename: 'ExtendedVideoAssetData';
                  headline: string | null;
                  text: string | null;
                  isQuote: boolean | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | {
                  __typename: 'FactBoxData';
                  title: string | null;
                  body: any | null;
                  data: Array<{
                    __typename: 'NodeData';
                    id: string;
                    data:
                      | { __typename: 'BalanceOfPowerData' }
                      | {
                          __typename: 'EditorialBannerData';
                          bannerTitle: string;
                          bannerText: string;
                          buttonText: string;
                          buttonLink: string;
                          bannerImage: {
                            __typename: 'EditorialBannerImageAsset';
                            title: string | null;
                            description: string | null;
                            url: string;
                          } | null;
                        }
                      | {
                          __typename: 'ExtendedVideoAssetData';
                          headline: string | null;
                          text: string | null;
                          isQuote: boolean | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            metadataId: string | null;
                            slug: string | null;
                            title: string;
                            description: string | null;
                            image: string | null;
                            type: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            live: boolean | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | { __typename: 'FactBoxData' }
                      | { __typename: 'HyperLinkData'; url: string | null }
                      | {
                          __typename: 'ImageData';
                          image: {
                            __typename: 'Image';
                            id: string;
                            url: string | null;
                            byline: string | null;
                            caption: string | null;
                            ratio: Ratio | null;
                            linkUrl: string | null;
                          } | null;
                        }
                      | {
                          __typename: 'ImageSliderData';
                          images: Array<{
                            __typename: 'Image';
                            id: string;
                            url: string | null;
                            byline: string | null;
                            caption: string | null;
                            ratio: Ratio | null;
                            linkUrl: string | null;
                          }>;
                        }
                      | { __typename: 'PollData'; directLink: string }
                      | { __typename: 'SportStandingsTableData' }
                      | {
                          __typename: 'VideoPlayerData';
                          id: string;
                          metadataId: string | null;
                          caption: string | null;
                          thumbnail: string | null;
                          thumbnailByline: string | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            metadataId: string | null;
                            slug: string | null;
                            title: string;
                            description: string | null;
                            image: string | null;
                            type: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            live: boolean | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | {
                          __typename: 'YouTubeData';
                          videoId: string | null;
                          playlistId: string | null;
                          startPositionInSeconds: number | null;
                        };
                  }>;
                }
              | { __typename: 'HyperLinkData'; url: string | null }
              | {
                  __typename: 'ImageData';
                  image: {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  } | null;
                }
              | {
                  __typename: 'ImageSliderData';
                  images: Array<{
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  }>;
                }
              | { __typename: 'PollData'; directLink: string }
              | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
              | {
                  __typename: 'VideoPlayerData';
                  id: string;
                  metadataId: string | null;
                  caption: string | null;
                  thumbnail: string | null;
                  thumbnailByline: string | null;
                  asset: {
                    __typename: 'VideoAsset';
                    id: string;
                    metadataId: string | null;
                    slug: string | null;
                    title: string;
                    description: string | null;
                    image: string | null;
                    type: string | null;
                    duration: number | null;
                    humanDuration: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanBroadcastShortDateTime: string | null;
                    ratio: Ratio | null;
                    live: boolean | null;
                    program: { __typename: 'Program'; nid: string } | null;
                  } | null;
                }
              | {
                  __typename: 'YouTubeData';
                  videoId: string | null;
                  playlistId: string | null;
                  startPositionInSeconds: number | null;
                };
          }>;
          metadata: {
            __typename: 'ArticleMetadata';
            id: string;
            wordCount: number | null;
            linkCount: number | null;
            imageCount: number | null;
            videoCount: number | null;
          } | null;
          featuredAsset:
            | {
                __typename: 'Image';
                id: string;
                url: string | null;
                byline: string | null;
                caption: string | null;
                ratio: Ratio | null;
                linkUrl: string | null;
              }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
            | null;
          related: Array<
            | {
                __typename: 'RelatedArticle';
                id: string;
                title: string;
                slug: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                humanUpdatedDateTime: string | null;
                updatedDateTime: string | null;
                lead: string | null;
                articleLeadRichText: any | null;
                liveReportId: string | null;
                bylineName: string | null;
                bylineEmail: string | null;
                body: any | null;
                isAdsHidden: boolean;
                isSponsoredArticle: boolean;
                byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
                featuredFeed: {
                  __typename: 'FeedOrigin';
                  id: string;
                  slug: string | null;
                  title: string | null;
                } | null;
                metadata: {
                  __typename: 'ArticleMetadata';
                  id: string;
                  wordCount: number | null;
                  linkCount: number | null;
                  imageCount: number | null;
                  videoCount: number | null;
                } | null;
                data: Array<{
                  __typename: 'NodeData';
                  id: string;
                  data:
                    | { __typename: 'BalanceOfPowerData'; id: string }
                    | {
                        __typename: 'EditorialBannerData';
                        bannerTitle: string;
                        bannerText: string;
                        buttonText: string;
                        buttonLink: string;
                        bannerImage: {
                          __typename: 'EditorialBannerImageAsset';
                          title: string | null;
                          description: string | null;
                          url: string;
                        } | null;
                      }
                    | {
                        __typename: 'ExtendedVideoAssetData';
                        headline: string | null;
                        text: string | null;
                        isQuote: boolean | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | {
                        __typename: 'FactBoxData';
                        title: string | null;
                        body: any | null;
                        data: Array<{
                          __typename: 'NodeData';
                          id: string;
                          data:
                            | { __typename: 'BalanceOfPowerData' }
                            | {
                                __typename: 'EditorialBannerData';
                                bannerTitle: string;
                                bannerText: string;
                                buttonText: string;
                                buttonLink: string;
                                bannerImage: {
                                  __typename: 'EditorialBannerImageAsset';
                                  title: string | null;
                                  description: string | null;
                                  url: string;
                                } | null;
                              }
                            | {
                                __typename: 'ExtendedVideoAssetData';
                                headline: string | null;
                                text: string | null;
                                isQuote: boolean | null;
                                asset: {
                                  __typename: 'VideoAsset';
                                  id: string;
                                  metadataId: string | null;
                                  slug: string | null;
                                  title: string;
                                  description: string | null;
                                  image: string | null;
                                  type: string | null;
                                  duration: number | null;
                                  humanDuration: string | null;
                                  publishedDateTime: string | null;
                                  humanPublishedDateTime: string | null;
                                  humanBroadcastShortDateTime: string | null;
                                  ratio: Ratio | null;
                                  live: boolean | null;
                                  program: { __typename: 'Program'; nid: string } | null;
                                } | null;
                              }
                            | { __typename: 'FactBoxData' }
                            | { __typename: 'HyperLinkData'; url: string | null }
                            | {
                                __typename: 'ImageData';
                                image: {
                                  __typename: 'Image';
                                  id: string;
                                  url: string | null;
                                  byline: string | null;
                                  caption: string | null;
                                  ratio: Ratio | null;
                                  linkUrl: string | null;
                                } | null;
                              }
                            | {
                                __typename: 'ImageSliderData';
                                images: Array<{
                                  __typename: 'Image';
                                  id: string;
                                  url: string | null;
                                  byline: string | null;
                                  caption: string | null;
                                  ratio: Ratio | null;
                                  linkUrl: string | null;
                                }>;
                              }
                            | { __typename: 'PollData'; directLink: string }
                            | { __typename: 'SportStandingsTableData' }
                            | {
                                __typename: 'VideoPlayerData';
                                id: string;
                                metadataId: string | null;
                                caption: string | null;
                                thumbnail: string | null;
                                thumbnailByline: string | null;
                                asset: {
                                  __typename: 'VideoAsset';
                                  id: string;
                                  metadataId: string | null;
                                  slug: string | null;
                                  title: string;
                                  description: string | null;
                                  image: string | null;
                                  type: string | null;
                                  duration: number | null;
                                  humanDuration: string | null;
                                  publishedDateTime: string | null;
                                  humanPublishedDateTime: string | null;
                                  humanBroadcastShortDateTime: string | null;
                                  ratio: Ratio | null;
                                  live: boolean | null;
                                  program: { __typename: 'Program'; nid: string } | null;
                                } | null;
                              }
                            | {
                                __typename: 'YouTubeData';
                                videoId: string | null;
                                playlistId: string | null;
                                startPositionInSeconds: number | null;
                              };
                        }>;
                      }
                    | { __typename: 'HyperLinkData'; url: string | null }
                    | {
                        __typename: 'ImageData';
                        image: {
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        } | null;
                      }
                    | {
                        __typename: 'ImageSliderData';
                        images: Array<{
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        }>;
                      }
                    | { __typename: 'PollData'; directLink: string }
                    | { __typename: 'SportStandingsTableData'; defaultLeague: League | null; leagues: Array<League> }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        metadataId: string | null;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          metadataId: string | null;
                          slug: string | null;
                          title: string;
                          description: string | null;
                          image: string | null;
                          type: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          live: boolean | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | {
                        __typename: 'YouTubeData';
                        videoId: string | null;
                        playlistId: string | null;
                        startPositionInSeconds: number | null;
                      };
                }>;
                featuredAsset:
                  | {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      byline: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      linkUrl: string | null;
                    }
                  | {
                      __typename: 'VideoPlayerData';
                      id: string;
                      metadataId: string | null;
                      caption: string | null;
                      thumbnail: string | null;
                      thumbnailByline: string | null;
                      asset: {
                        __typename: 'VideoAsset';
                        id: string;
                        metadataId: string | null;
                        slug: string | null;
                        title: string;
                        description: string | null;
                        image: string | null;
                        type: string | null;
                        duration: number | null;
                        humanDuration: string | null;
                        publishedDateTime: string | null;
                        humanPublishedDateTime: string | null;
                        humanBroadcastShortDateTime: string | null;
                        ratio: Ratio | null;
                        live: boolean | null;
                        program: { __typename: 'Program'; nid: string } | null;
                      } | null;
                    }
                  | null;
                feedOrigins: Array<{
                  __typename: 'FeedOrigin';
                  type: FeedType;
                  id: string;
                  slug: string | null;
                  title: string | null;
                }>;
              }
            | {
                __typename: 'VideoPlayerData';
                id: string;
                metadataId: string | null;
                caption: string | null;
                thumbnail: string | null;
                thumbnailByline: string | null;
                asset: {
                  __typename: 'VideoAsset';
                  id: string;
                  metadataId: string | null;
                  slug: string | null;
                  title: string;
                  description: string | null;
                  image: string | null;
                  type: string | null;
                  duration: number | null;
                  humanDuration: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  humanBroadcastShortDateTime: string | null;
                  ratio: Ratio | null;
                  live: boolean | null;
                  program: { __typename: 'Program'; nid: string } | null;
                } | null;
              }
          >;
          feedOrigins: Array<{
            __typename: 'FeedOrigin';
            type: FeedType;
            id: string;
            slug: string | null;
            title: string | null;
          }>;
        }
      | {
          __typename: 'VideoAsset';
          id: string;
          metadataId: string | null;
          slug: string | null;
          title: string;
          description: string | null;
          image: string | null;
          type: string | null;
          duration: number | null;
          humanDuration: string | null;
          publishedDateTime: string | null;
          humanPublishedDateTime: string | null;
          humanBroadcastShortDateTime: string | null;
          ratio: Ratio | null;
          live: boolean | null;
          program: { __typename: 'Program'; nid: string } | null;
        }
      | null;
    newsFeed: {
      __typename: 'PaginatedNewsFeed';
      id: string;
      slug: string | null;
      redirect: string | null;
      title: string | null;
      metaTitle: string | null;
      metaDescription: string | null;
      ogTitle: string | null;
      ogDescription: string | null;
      ogImage: string | null;
      main16x9Annotated: string | null;
      category: Category | null;
      publishedAt: string | null;
      modifiedAt: string | null;
      hero: {
        __typename: 'Hero';
        id: string;
        title: string | null;
        image: string | null;
        heroImage: string | null;
        description: string | null;
        shortDescription: string | null;
        twitterUrl: string | null;
        facebookUrl: string | null;
        instagramUrl: string | null;
        program: { __typename: 'HeroProgram'; nid: string | null; id: string | null } | null;
      } | null;
      emphasizedArticleGroup: {
        __typename: 'EmphasizedArticleGroup';
        content: Array<{
          __typename: 'ArticleGroup';
          id: string;
          title: string;
          isEmphasized: boolean;
          content: Array<
            | {
                __typename: 'ArticleGroupArticle';
                id: string;
                slug: string | null;
                title: string;
                live: boolean | null;
                lead: string | null;
                publishedDateTime: string | null;
                humanPublishedDateTime: string | null;
                updatedDateTime: string | null;
                humanUpdatedDateTime: string | null;
                liveReportId: string | null;
                bylineName: string | null;
                bylineEmail: string | null;
                isOpinionPiece: boolean;
                isSponsoredArticle: boolean;
                featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
                feedOrigins: Array<{
                  __typename: 'FeedOrigin';
                  type: FeedType;
                  id: string;
                  slug: string | null;
                  title: string | null;
                }>;
                byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
                featuredAsset:
                  | {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      caption: string | null;
                      byline: string | null;
                      linkUrl: string | null;
                      ratio: Ratio | null;
                    }
                  | {
                      __typename: 'VideoPlayerData';
                      id: string;
                      title: string;
                      caption: string | null;
                      thumbnail: string | null;
                      thumbnailByline: string | null;
                      asset: {
                        __typename: 'VideoAsset';
                        id: string;
                        title: string;
                        slug: string | null;
                        description: string | null;
                        live: boolean | null;
                        type: string | null;
                        image: string | null;
                        duration: number | null;
                        humanDuration: string | null;
                        publishedDateTime: string | null;
                        humanPublishedDateTime: string | null;
                        humanBroadcastShortDateTime: string | null;
                        ratio: Ratio | null;
                        program: { __typename: 'Program'; nid: string } | null;
                      } | null;
                    }
                  | null;
              }
            | {
                __typename: 'ArticleGroupBlock';
                id: string;
                layout: Layout | null;
                imagePlacement: ImagePlacement | null;
                content: Array<{
                  __typename: 'ArticleGroupArticle';
                  id: string;
                  slug: string | null;
                  title: string;
                  live: boolean | null;
                  lead: string | null;
                  publishedDateTime: string | null;
                  humanPublishedDateTime: string | null;
                  updatedDateTime: string | null;
                  humanUpdatedDateTime: string | null;
                  liveReportId: string | null;
                  bylineName: string | null;
                  bylineEmail: string | null;
                  isSponsoredArticle: boolean;
                  feedOrigins: Array<{
                    __typename: 'FeedOrigin';
                    type: FeedType;
                    id: string;
                    slug: string | null;
                    title: string | null;
                  }>;
                  featuredAsset:
                    | {
                        __typename: 'Image';
                        id: string;
                        url: string | null;
                        caption: string | null;
                        byline: string | null;
                        linkUrl: string | null;
                        ratio: Ratio | null;
                      }
                    | {
                        __typename: 'VideoPlayerData';
                        id: string;
                        title: string;
                        caption: string | null;
                        thumbnail: string | null;
                        thumbnailByline: string | null;
                        asset: {
                          __typename: 'VideoAsset';
                          id: string;
                          title: string;
                          slug: string | null;
                          description: string | null;
                          live: boolean | null;
                          type: string | null;
                          image: string | null;
                          duration: number | null;
                          humanDuration: string | null;
                          publishedDateTime: string | null;
                          humanPublishedDateTime: string | null;
                          humanBroadcastShortDateTime: string | null;
                          ratio: Ratio | null;
                          program: { __typename: 'Program'; nid: string } | null;
                        } | null;
                      }
                    | null;
                } | null>;
              }
            | null
          >;
        }>;
      } | null;
      feed: {
        __typename: 'PaginatedFeed';
        pagingData: { __typename: 'PagingData'; currentPage: number | null; nextPage: number | null } | null;
        items: Array<
          | {
              __typename: 'Article';
              id: string;
              title: string;
              slug: string | null;
              live: boolean | null;
              lead: string | null;
              liveReportId: string | null;
              isOpinionPiece: boolean;
              metaTitle: string | null;
              metaDescription: string | null;
              ogTitle: string | null;
              ogDescription: string | null;
              isAdsHidden: boolean;
              isSponsoredArticle: boolean;
              showPobInFeed: boolean;
              byline: Array<{ __typename: 'Byline'; name: string; email: string | null; bylineImage: string | null }>;
              featuredFeed: { __typename: 'FeedOrigin'; id: string; slug: string | null; title: string | null } | null;
              metadata: {
                __typename: 'ArticleMetadata';
                id: string;
                wordCount: number | null;
                linkCount: number | null;
                imageCount: number | null;
                videoCount: number | null;
              } | null;
              featuredAsset:
                | {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    byline: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    linkUrl: string | null;
                  }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    metadataId: string | null;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
                | null;
              related: Array<
                | {
                    __typename: 'RelatedArticle';
                    id: string;
                    title: string;
                    slug: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    humanUpdatedDateTime: string | null;
                    updatedDateTime: string | null;
                    lead: string | null;
                    articleLeadRichText: any | null;
                    liveReportId: string | null;
                    bylineName: string | null;
                    bylineEmail: string | null;
                    body: any | null;
                    isAdsHidden: boolean;
                    isSponsoredArticle: boolean;
                    byline: Array<{
                      __typename: 'Byline';
                      name: string;
                      email: string | null;
                      bylineImage: string | null;
                    }>;
                    featuredFeed: {
                      __typename: 'FeedOrigin';
                      id: string;
                      slug: string | null;
                      title: string | null;
                    } | null;
                    metadata: {
                      __typename: 'ArticleMetadata';
                      id: string;
                      wordCount: number | null;
                      linkCount: number | null;
                      imageCount: number | null;
                      videoCount: number | null;
                    } | null;
                    data: Array<{
                      __typename: 'NodeData';
                      id: string;
                      data:
                        | { __typename: 'BalanceOfPowerData'; id: string }
                        | {
                            __typename: 'EditorialBannerData';
                            bannerTitle: string;
                            bannerText: string;
                            buttonText: string;
                            buttonLink: string;
                            bannerImage: {
                              __typename: 'EditorialBannerImageAsset';
                              title: string | null;
                              description: string | null;
                              url: string;
                            } | null;
                          }
                        | {
                            __typename: 'ExtendedVideoAssetData';
                            headline: string | null;
                            text: string | null;
                            isQuote: boolean | null;
                            asset: {
                              __typename: 'VideoAsset';
                              id: string;
                              metadataId: string | null;
                              slug: string | null;
                              title: string;
                              description: string | null;
                              image: string | null;
                              type: string | null;
                              duration: number | null;
                              humanDuration: string | null;
                              publishedDateTime: string | null;
                              humanPublishedDateTime: string | null;
                              humanBroadcastShortDateTime: string | null;
                              ratio: Ratio | null;
                              live: boolean | null;
                              program: { __typename: 'Program'; nid: string } | null;
                            } | null;
                          }
                        | {
                            __typename: 'FactBoxData';
                            title: string | null;
                            body: any | null;
                            data: Array<{
                              __typename: 'NodeData';
                              id: string;
                              data:
                                | { __typename: 'BalanceOfPowerData' }
                                | {
                                    __typename: 'EditorialBannerData';
                                    bannerTitle: string;
                                    bannerText: string;
                                    buttonText: string;
                                    buttonLink: string;
                                    bannerImage: {
                                      __typename: 'EditorialBannerImageAsset';
                                      title: string | null;
                                      description: string | null;
                                      url: string;
                                    } | null;
                                  }
                                | {
                                    __typename: 'ExtendedVideoAssetData';
                                    headline: string | null;
                                    text: string | null;
                                    isQuote: boolean | null;
                                    asset: {
                                      __typename: 'VideoAsset';
                                      id: string;
                                      metadataId: string | null;
                                      slug: string | null;
                                      title: string;
                                      description: string | null;
                                      image: string | null;
                                      type: string | null;
                                      duration: number | null;
                                      humanDuration: string | null;
                                      publishedDateTime: string | null;
                                      humanPublishedDateTime: string | null;
                                      humanBroadcastShortDateTime: string | null;
                                      ratio: Ratio | null;
                                      live: boolean | null;
                                      program: { __typename: 'Program'; nid: string } | null;
                                    } | null;
                                  }
                                | { __typename: 'FactBoxData' }
                                | { __typename: 'HyperLinkData'; url: string | null }
                                | {
                                    __typename: 'ImageData';
                                    image: {
                                      __typename: 'Image';
                                      id: string;
                                      url: string | null;
                                      byline: string | null;
                                      caption: string | null;
                                      ratio: Ratio | null;
                                      linkUrl: string | null;
                                    } | null;
                                  }
                                | {
                                    __typename: 'ImageSliderData';
                                    images: Array<{
                                      __typename: 'Image';
                                      id: string;
                                      url: string | null;
                                      byline: string | null;
                                      caption: string | null;
                                      ratio: Ratio | null;
                                      linkUrl: string | null;
                                    }>;
                                  }
                                | { __typename: 'PollData'; directLink: string }
                                | { __typename: 'SportStandingsTableData' }
                                | {
                                    __typename: 'VideoPlayerData';
                                    id: string;
                                    metadataId: string | null;
                                    caption: string | null;
                                    thumbnail: string | null;
                                    thumbnailByline: string | null;
                                    asset: {
                                      __typename: 'VideoAsset';
                                      id: string;
                                      metadataId: string | null;
                                      slug: string | null;
                                      title: string;
                                      description: string | null;
                                      image: string | null;
                                      type: string | null;
                                      duration: number | null;
                                      humanDuration: string | null;
                                      publishedDateTime: string | null;
                                      humanPublishedDateTime: string | null;
                                      humanBroadcastShortDateTime: string | null;
                                      ratio: Ratio | null;
                                      live: boolean | null;
                                      program: { __typename: 'Program'; nid: string } | null;
                                    } | null;
                                  }
                                | {
                                    __typename: 'YouTubeData';
                                    videoId: string | null;
                                    playlistId: string | null;
                                    startPositionInSeconds: number | null;
                                  };
                            }>;
                          }
                        | { __typename: 'HyperLinkData'; url: string | null }
                        | {
                            __typename: 'ImageData';
                            image: {
                              __typename: 'Image';
                              id: string;
                              url: string | null;
                              byline: string | null;
                              caption: string | null;
                              ratio: Ratio | null;
                              linkUrl: string | null;
                            } | null;
                          }
                        | {
                            __typename: 'ImageSliderData';
                            images: Array<{
                              __typename: 'Image';
                              id: string;
                              url: string | null;
                              byline: string | null;
                              caption: string | null;
                              ratio: Ratio | null;
                              linkUrl: string | null;
                            }>;
                          }
                        | { __typename: 'PollData'; directLink: string }
                        | {
                            __typename: 'SportStandingsTableData';
                            defaultLeague: League | null;
                            leagues: Array<League>;
                          }
                        | {
                            __typename: 'VideoPlayerData';
                            id: string;
                            metadataId: string | null;
                            caption: string | null;
                            thumbnail: string | null;
                            thumbnailByline: string | null;
                            asset: {
                              __typename: 'VideoAsset';
                              id: string;
                              metadataId: string | null;
                              slug: string | null;
                              title: string;
                              description: string | null;
                              image: string | null;
                              type: string | null;
                              duration: number | null;
                              humanDuration: string | null;
                              publishedDateTime: string | null;
                              humanPublishedDateTime: string | null;
                              humanBroadcastShortDateTime: string | null;
                              ratio: Ratio | null;
                              live: boolean | null;
                              program: { __typename: 'Program'; nid: string } | null;
                            } | null;
                          }
                        | {
                            __typename: 'YouTubeData';
                            videoId: string | null;
                            playlistId: string | null;
                            startPositionInSeconds: number | null;
                          };
                    }>;
                    featuredAsset:
                      | {
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          byline: string | null;
                          caption: string | null;
                          ratio: Ratio | null;
                          linkUrl: string | null;
                        }
                      | {
                          __typename: 'VideoPlayerData';
                          id: string;
                          metadataId: string | null;
                          caption: string | null;
                          thumbnail: string | null;
                          thumbnailByline: string | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            metadataId: string | null;
                            slug: string | null;
                            title: string;
                            description: string | null;
                            image: string | null;
                            type: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            live: boolean | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | null;
                    feedOrigins: Array<{
                      __typename: 'FeedOrigin';
                      type: FeedType;
                      id: string;
                      slug: string | null;
                      title: string | null;
                    }>;
                  }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    metadataId: string | null;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: {
                      __typename: 'VideoAsset';
                      id: string;
                      metadataId: string | null;
                      slug: string | null;
                      title: string;
                      description: string | null;
                      image: string | null;
                      type: string | null;
                      duration: number | null;
                      humanDuration: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      humanBroadcastShortDateTime: string | null;
                      ratio: Ratio | null;
                      live: boolean | null;
                      program: { __typename: 'Program'; nid: string } | null;
                    } | null;
                  }
              >;
              feedOrigins: Array<{
                __typename: 'FeedOrigin';
                type: FeedType;
                id: string;
                slug: string | null;
                title: string | null;
              }>;
            }
          | {
              __typename: 'ArticleGroup';
              id: string;
              title: string;
              isEmphasized: boolean;
              content: Array<
                | {
                    __typename: 'ArticleGroupArticle';
                    id: string;
                    slug: string | null;
                    title: string;
                    live: boolean | null;
                    lead: string | null;
                    publishedDateTime: string | null;
                    humanPublishedDateTime: string | null;
                    updatedDateTime: string | null;
                    humanUpdatedDateTime: string | null;
                    liveReportId: string | null;
                    bylineName: string | null;
                    bylineEmail: string | null;
                    isOpinionPiece: boolean;
                    isSponsoredArticle: boolean;
                    featuredFeed: { __typename: 'FeedOrigin'; title: string | null; slug: string | null } | null;
                    feedOrigins: Array<{
                      __typename: 'FeedOrigin';
                      type: FeedType;
                      id: string;
                      slug: string | null;
                      title: string | null;
                    }>;
                    byline: Array<{
                      __typename: 'Byline';
                      name: string;
                      email: string | null;
                      bylineImage: string | null;
                    }>;
                    featuredAsset:
                      | {
                          __typename: 'Image';
                          id: string;
                          url: string | null;
                          caption: string | null;
                          byline: string | null;
                          linkUrl: string | null;
                          ratio: Ratio | null;
                        }
                      | {
                          __typename: 'VideoPlayerData';
                          id: string;
                          title: string;
                          caption: string | null;
                          thumbnail: string | null;
                          thumbnailByline: string | null;
                          asset: {
                            __typename: 'VideoAsset';
                            id: string;
                            title: string;
                            slug: string | null;
                            description: string | null;
                            live: boolean | null;
                            type: string | null;
                            image: string | null;
                            duration: number | null;
                            humanDuration: string | null;
                            publishedDateTime: string | null;
                            humanPublishedDateTime: string | null;
                            humanBroadcastShortDateTime: string | null;
                            ratio: Ratio | null;
                            program: { __typename: 'Program'; nid: string } | null;
                          } | null;
                        }
                      | null;
                  }
                | {
                    __typename: 'ArticleGroupBlock';
                    id: string;
                    layout: Layout | null;
                    imagePlacement: ImagePlacement | null;
                    content: Array<{
                      __typename: 'ArticleGroupArticle';
                      id: string;
                      slug: string | null;
                      title: string;
                      live: boolean | null;
                      lead: string | null;
                      publishedDateTime: string | null;
                      humanPublishedDateTime: string | null;
                      updatedDateTime: string | null;
                      humanUpdatedDateTime: string | null;
                      liveReportId: string | null;
                      bylineName: string | null;
                      bylineEmail: string | null;
                      isSponsoredArticle: boolean;
                      feedOrigins: Array<{
                        __typename: 'FeedOrigin';
                        type: FeedType;
                        id: string;
                        slug: string | null;
                        title: string | null;
                      }>;
                      featuredAsset:
                        | {
                            __typename: 'Image';
                            id: string;
                            url: string | null;
                            caption: string | null;
                            byline: string | null;
                            linkUrl: string | null;
                            ratio: Ratio | null;
                          }
                        | {
                            __typename: 'VideoPlayerData';
                            id: string;
                            title: string;
                            caption: string | null;
                            thumbnail: string | null;
                            thumbnailByline: string | null;
                            asset: {
                              __typename: 'VideoAsset';
                              id: string;
                              title: string;
                              slug: string | null;
                              description: string | null;
                              live: boolean | null;
                              type: string | null;
                              image: string | null;
                              duration: number | null;
                              humanDuration: string | null;
                              publishedDateTime: string | null;
                              humanPublishedDateTime: string | null;
                              humanBroadcastShortDateTime: string | null;
                              ratio: Ratio | null;
                              program: { __typename: 'Program'; nid: string } | null;
                            } | null;
                          }
                        | null;
                    } | null>;
                  }
                | null
              >;
            }
          | { __typename: 'BreakingNewsModule'; id: string }
          | { __typename: 'LiveReport'; id: string; liveReportId: string | null; title: string; text: string | null }
          | { __typename: 'MediaSlider'; id: string; title: string; nordicCmsPanelId: string }
          | {
              __typename: 'ShortsSlider';
              id: string;
              title: string;
              items: Array<{
                __typename: 'ShortsSliderContent';
                id: string;
                title: string;
                metadataId: string;
                thumbnail: string;
                vimondId: string;
                duration: number | null;
                relativePublishDate: string | null;
              } | null> | null;
            }
          | { __typename: 'SportResultsModule'; id: string }
          | {
              __typename: 'Upsell';
              id: string;
              title: string;
              subtitle: string | null;
              backgroundColor: string | null;
              linkUrl: string;
              linkText: string;
              label: string | null;
              imageData: {
                __typename: 'UpsellImage';
                id: string;
                url: string;
                metadata: {
                  __typename: 'UpsellImageMetadata';
                  blurHash: string | null;
                  hexColor: string | null;
                } | null;
              };
            }
          | {
              __typename: 'VideoAsset';
              id: string;
              metadataId: string | null;
              slug: string | null;
              title: string;
              description: string | null;
              image: string | null;
              type: string | null;
              duration: number | null;
              humanDuration: string | null;
              publishedDateTime: string | null;
              humanPublishedDateTime: string | null;
              humanBroadcastShortDateTime: string | null;
              ratio: Ratio | null;
              live: boolean | null;
              program: { __typename: 'Program'; nid: string } | null;
            }
        >;
      } | null;
      sponsors: Array<{ __typename: 'Sponsor'; id: string; title: string; link: string; image: string }>;
    } | null;
  } | null;
};

export type ProgramFeedUrlDataQueryVariables = Exact<{ [key: string]: never }>;

export type ProgramFeedUrlDataQuery = {
  __typename: 'Query';
  programFeedsListing: {
    __typename: 'NewsFeedSelectionPage';
    newsFeeds: Array<{ __typename: 'NewsFeedData'; id: string; title: string | null; slug: string | null }>;
  } | null;
};

export type ProgramsPageQueryVariables = Exact<{ [key: string]: never }>;

export type ProgramsPageQuery = {
  __typename: 'Query';
  programFeedsListing: {
    __typename: 'NewsFeedSelectionPage';
    id: string;
    title: string | null;
    newsFeeds: Array<{
      __typename: 'NewsFeedData';
      id: string;
      title: string | null;
      slug: string | null;
      main16x9Annotated: string | null;
    }>;
  } | null;
};

export type ArticlesByDateQueryVariables = Exact<{
  date: Scalars['String']['input'];
}>;

export type ArticlesByDateQuery = {
  __typename: 'Query';
  articlesByDate: Array<{
    __typename: 'Article';
    id: string;
    slug: string | null;
    title: string;
    body: any | null;
    raw: any | null;
    publishedDateTime: string | null;
    feedOrigins: Array<{ __typename: 'FeedOrigin'; slug: string | null; title: string | null }>;
  }>;
};

export type RssPaginatedNewsFeedQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type RssPaginatedNewsFeedQuery = {
  __typename: 'Query';
  paginatedNewsFeed: {
    __typename: 'PaginatedNewsFeed';
    title: string | null;
    feed: {
      __typename: 'PaginatedFeed';
      items: Array<
        | {
            __typename: 'Article';
            id: string;
            slug: string | null;
            title: string;
            body: any | null;
            raw: any | null;
            publishedDateTime: string | null;
            feedOrigins: Array<{ __typename: 'FeedOrigin'; slug: string | null; title: string | null }>;
          }
        | { __typename: 'ArticleGroup' }
        | { __typename: 'BreakingNewsModule' }
        | { __typename: 'LiveReport' }
        | { __typename: 'MediaSlider' }
        | { __typename: 'ShortsSlider' }
        | { __typename: 'SportResultsModule' }
        | { __typename: 'Upsell' }
        | {
            __typename: 'VideoAsset';
            id: string;
            slug: string | null;
            title: string;
            description: string | null;
            publishedDateTime: string | null;
          }
      >;
    } | null;
  } | null;
};

export type ArticlePartsFragment = {
  __typename: 'ArticleGroupArticle';
  id: string;
  title: string;
  slug: string | null;
  publishedDateTime: string | null;
  lead: string | null;
  feedOrigins: Array<{ __typename: 'FeedOrigin'; slug: string | null; title: string | null }>;
  featuredAsset:
    | {
        __typename: 'Image';
        id: string;
        url: string | null;
        caption: string | null;
        ratio: Ratio | null;
        byline: string | null;
        linkUrl: string | null;
      }
    | {
        __typename: 'VideoPlayerData';
        id: string;
        title: string;
        metadataId: string | null;
        caption: string | null;
        thumbnail: string | null;
        thumbnailByline: string | null;
        asset: { __typename: 'VideoAsset'; image: string | null } | null;
      }
    | null;
};

export type RssEmphasizedArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type RssEmphasizedArticlesQuery = {
  __typename: 'Query';
  paginatedNewsFeed: {
    __typename: 'PaginatedNewsFeed';
    title: string | null;
    emphasizedArticleGroup: {
      __typename: 'EmphasizedArticleGroup';
      content: Array<{
        __typename: 'ArticleGroup';
        id: string;
        isEmphasized: boolean;
        content: Array<
          | {
              __typename: 'ArticleGroupArticle';
              id: string;
              title: string;
              slug: string | null;
              publishedDateTime: string | null;
              lead: string | null;
              feedOrigins: Array<{ __typename: 'FeedOrigin'; slug: string | null; title: string | null }>;
              featuredAsset:
                | {
                    __typename: 'Image';
                    id: string;
                    url: string | null;
                    caption: string | null;
                    ratio: Ratio | null;
                    byline: string | null;
                    linkUrl: string | null;
                  }
                | {
                    __typename: 'VideoPlayerData';
                    id: string;
                    title: string;
                    metadataId: string | null;
                    caption: string | null;
                    thumbnail: string | null;
                    thumbnailByline: string | null;
                    asset: { __typename: 'VideoAsset'; image: string | null } | null;
                  }
                | null;
            }
          | {
              __typename: 'ArticleGroupBlock';
              content: Array<{
                __typename: 'ArticleGroupArticle';
                id: string;
                title: string;
                slug: string | null;
                publishedDateTime: string | null;
                lead: string | null;
                feedOrigins: Array<{ __typename: 'FeedOrigin'; slug: string | null; title: string | null }>;
                featuredAsset:
                  | {
                      __typename: 'Image';
                      id: string;
                      url: string | null;
                      caption: string | null;
                      ratio: Ratio | null;
                      byline: string | null;
                      linkUrl: string | null;
                    }
                  | {
                      __typename: 'VideoPlayerData';
                      id: string;
                      title: string;
                      metadataId: string | null;
                      caption: string | null;
                      thumbnail: string | null;
                      thumbnailByline: string | null;
                      asset: { __typename: 'VideoAsset'; image: string | null } | null;
                    }
                  | null;
              } | null>;
            }
          | null
        >;
      }>;
    } | null;
  } | null;
};

export type ScheduleQueryVariables = Exact<{
  date: Scalars['String']['input'];
}>;

export type ScheduleQuery = {
  __typename: 'Query';
  schedule: Array<{
    __typename: 'Schedule';
    id: string;
    metadataChannelId: string;
    name: string;
    displayName: string;
    day: string | null;
    broadcasts: Array<{
      __typename: 'Broadcast';
      id: string;
      title: string;
      start: string;
      end: string;
      synopsis: {
        __typename: 'Synopsis';
        id: string;
        brief: string | null;
        long: string | null;
        medium: string | null;
        short: string | null;
      } | null;
    }>;
  }>;
};

export type ServiceMessageQueryVariables = Exact<{
  platform: PlatformParameter;
}>;

export type ServiceMessageQuery = {
  __typename: 'Query';
  serviceMessages: Array<{ __typename: 'ServiceMessage'; id: string; title: string; message: string } | null> | null;
};

export type ShortsPlaylistQueryVariables = Exact<{
  playlistId: Scalars['ID']['input'];
  selectedVideoId: Scalars['ID']['input'];
}>;

export type ShortsPlaylistQuery = {
  __typename: 'Query';
  shortsPlaylist: {
    __typename: 'ShortsPlaylist';
    content: Array<{
      __typename: 'ShortsSliderContent';
      id: string;
      title: string;
      metadataId: string;
      thumbnail: string;
      vimondId: string;
      publishedAt: string;
      duration: number | null;
      relativePublishDate: string | null;
    } | null> | null;
  };
};

export type IceHockeyResultFieldsFragment = {
  __typename: 'IceHockeyResult';
  _id: number;
  matchStatus: MatchStatus;
  eventStatus: EventStatus;
  date: string;
  tv4playLink: string;
  homeTeam: { __typename: 'Team'; id: number; displayName: string };
  awayTeam: { __typename: 'Team'; id: number; displayName: string };
  scores: { __typename: 'Scores'; score: { __typename: 'Score'; homeScore: number; awayScore: number } | null } | null;
};

export type FootballResultFieldsFragment = {
  __typename: 'FootballResult';
  _id: number;
  matchStatus: MatchStatus;
  eventStatus: EventStatus;
  date: string;
  tv4playLink: string;
  homeTeam: { __typename: 'Team'; id: number; displayName: string };
  awayTeam: { __typename: 'Team'; id: number; displayName: string };
  scores: { __typename: 'Scores'; score: { __typename: 'Score'; homeScore: number; awayScore: number } | null } | null;
};

export type SportResultsByLeagueQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  resultsFilter: InputMaybe<Array<InputMaybe<ResultsFilter>> | InputMaybe<ResultsFilter>>;
  sortDateOrder: InputMaybe<SortOrder>;
}>;

export type SportResultsByLeagueQuery = {
  __typename: 'Query';
  sportResultsByLeague:
    | {
        __typename: 'FootballResults';
        results: Array<{
          __typename: 'FootballResult';
          _id: number;
          matchStatus: MatchStatus;
          eventStatus: EventStatus;
          date: string;
          tv4playLink: string;
          homeTeam: { __typename: 'Team'; id: number; displayName: string };
          awayTeam: { __typename: 'Team'; id: number; displayName: string };
          scores: {
            __typename: 'Scores';
            score: { __typename: 'Score'; homeScore: number; awayScore: number } | null;
          } | null;
        }>;
        pagination: {
          __typename: 'PaginationInfo';
          totalCount: number;
          hasNextPage: boolean;
          currentPage: number;
          totalPages: number;
        };
      }
    | {
        __typename: 'IceHockeyResults';
        results: Array<{
          __typename: 'IceHockeyResult';
          _id: number;
          matchStatus: MatchStatus;
          eventStatus: EventStatus;
          date: string;
          tv4playLink: string;
          homeTeam: { __typename: 'Team'; id: number; displayName: string };
          awayTeam: { __typename: 'Team'; id: number; displayName: string };
          scores: {
            __typename: 'Scores';
            score: { __typename: 'Score'; homeScore: number; awayScore: number } | null;
          } | null;
        }>;
        pagination: {
          __typename: 'PaginationInfo';
          totalCount: number;
          hasNextPage: boolean;
          currentPage: number;
          totalPages: number;
        };
      };
};

export type SportStandingByLeagueQueryVariables = Exact<{
  league: League;
  gameLocationFilter: InputMaybe<Array<InputMaybe<GameLocation>> | InputMaybe<GameLocation>>;
}>;

export type SportStandingByLeagueQuery = {
  __typename: 'Query';
  sportStandingByLeague: {
    __typename: 'SportStanding';
    name: string;
    lines: {
      __typename: 'Lines';
      line: Array<{
        __typename: 'Line';
        start: number;
        end: number;
        name: { __typename: 'Description'; lang: string; value: string };
      }>;
    };
    participant: Array<{
      __typename: 'Participant';
      id: string;
      displayName: string;
      name: { __typename: 'Description'; lang: string; value: string };
      result: Array<{
        __typename: 'Result';
        id: string;
        wot: number;
        wp: number;
        lot: number;
        lp: number;
        gamesplayed: number;
        win: number;
        lost: number;
        scoreteam: number;
        goalsconceded: number;
        goaldifference: number;
        points: number;
        name: { __typename: 'ResultDescription'; lang: string; value: GameLocation };
      }>;
    }>;
    primary: { __typename: 'Primary'; id: string; name: { __typename: 'Description'; lang: string; value: string } };
    sport: { __typename: 'Sport'; id: string; name: { __typename: 'Description'; lang: string; value: string } };
  };
};

export type VideoUrlDataByDateQueryVariables = Exact<{
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  limit: InputMaybe<Scalars['Int']['input']>;
  skip: InputMaybe<Scalars['Int']['input']>;
}>;

export type VideoUrlDataByDateQuery = {
  __typename: 'Query';
  videoUrlDataByDate: {
    __typename: 'VideoUrlDataByDate';
    total: number;
    items: Array<{
      __typename: 'VideoUrlData';
      id: string;
      metadataId: string;
      slug: string;
      thumbnail: string | null;
      title: string;
      description: string | null;
      duration: number | null;
      publishedAt: string;
      tags: Array<string>;
    }>;
  };
};

export const FeedOriginFieldsFragmentDoc = gql`
  fragment FeedOriginFields on FeedOrigin {
    id
    slug
    title
  }
`;
export const VideoAssetFieldsFragmentDoc = gql`
  fragment VideoAssetFields on VideoAsset {
    id
    metadataId
    slug
    title
    description
    image
    type
    duration
    humanDuration
    publishedDateTime
    humanPublishedDateTime
    humanBroadcastShortDateTime
    ratio
    live
    program {
      nid
    }
  }
`;
export const VideoPlayerDataFieldsFragmentDoc = gql`
  fragment VideoPlayerDataFields on VideoPlayerData {
    __typename
    id
    asset {
      ...VideoAssetFields
    }
    metadataId
    caption
    thumbnail
    thumbnailByline
  }
  ${VideoAssetFieldsFragmentDoc}
`;
export const HyperLinkDataFieldsFragmentDoc = gql`
  fragment HyperLinkDataFields on HyperLinkData {
    url
  }
`;
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on Image {
    id
    url
    byline
    caption
    ratio
    linkUrl
  }
`;
export const ImageDataFieldsFragmentDoc = gql`
  fragment ImageDataFields on ImageData {
    image {
      ...ImageFields
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const ImageSliderDataFieldsFragmentDoc = gql`
  fragment ImageSliderDataFields on ImageSliderData {
    images {
      ...ImageFields
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const YouTubeDataFieldsFragmentDoc = gql`
  fragment YouTubeDataFields on YouTubeData {
    videoId
    playlistId
    startPositionInSeconds
  }
`;
export const ExtendedVideoAssetDataFieldsFragmentDoc = gql`
  fragment ExtendedVideoAssetDataFields on ExtendedVideoAssetData {
    headline
    text
    isQuote
    asset {
      ...VideoAssetFields
    }
  }
  ${VideoAssetFieldsFragmentDoc}
`;
export const PollDataFieldsFragmentDoc = gql`
  fragment PollDataFields on PollData {
    directLink
  }
`;
export const EditorialBannerDataFieldsFragmentDoc = gql`
  fragment EditorialBannerDataFields on EditorialBannerData {
    bannerTitle
    bannerText
    buttonText
    buttonLink
    bannerImage {
      title
      description
      url
    }
  }
`;
export const FactBoxNodeDataUnionFieldsFragmentDoc = gql`
  fragment FactBoxNodeDataUnionFields on NodeDataUnion {
    __typename
    ...VideoPlayerDataFields
    ...HyperLinkDataFields
    ...ImageDataFields
    ...ImageSliderDataFields
    ...YouTubeDataFields
    ...ExtendedVideoAssetDataFields
    ...PollDataFields
    ...EditorialBannerDataFields
  }
  ${VideoPlayerDataFieldsFragmentDoc}
  ${HyperLinkDataFieldsFragmentDoc}
  ${ImageDataFieldsFragmentDoc}
  ${ImageSliderDataFieldsFragmentDoc}
  ${YouTubeDataFieldsFragmentDoc}
  ${ExtendedVideoAssetDataFieldsFragmentDoc}
  ${PollDataFieldsFragmentDoc}
  ${EditorialBannerDataFieldsFragmentDoc}
`;
export const FactBoxNodeDataFieldsFragmentDoc = gql`
  fragment FactBoxNodeDataFields on NodeData {
    id
    data {
      ...FactBoxNodeDataUnionFields
    }
  }
  ${FactBoxNodeDataUnionFieldsFragmentDoc}
`;
export const FactBoxDataFieldsFragmentDoc = gql`
  fragment FactBoxDataFields on FactBoxData {
    title
    body
    data {
      ...FactBoxNodeDataFields
    }
  }
  ${FactBoxNodeDataFieldsFragmentDoc}
`;
export const SportStandingsTableFieldsFragmentDoc = gql`
  fragment SportStandingsTableFields on SportStandingsTableData {
    defaultLeague
    leagues
  }
`;
export const BalanceOfPowerDataFieldsFragmentDoc = gql`
  fragment BalanceOfPowerDataFields on BalanceOfPowerData {
    __typename
    id
  }
`;
export const NodeDataUnionFieldsFragmentDoc = gql`
  fragment NodeDataUnionFields on NodeDataUnion {
    __typename
    ...VideoPlayerDataFields
    ...HyperLinkDataFields
    ...ImageDataFields
    ...ImageSliderDataFields
    ...YouTubeDataFields
    ...ExtendedVideoAssetDataFields
    ...PollDataFields
    ...EditorialBannerDataFields
    ...FactBoxDataFields
    ...SportStandingsTableFields
    ...BalanceOfPowerDataFields
  }
  ${VideoPlayerDataFieldsFragmentDoc}
  ${HyperLinkDataFieldsFragmentDoc}
  ${ImageDataFieldsFragmentDoc}
  ${ImageSliderDataFieldsFragmentDoc}
  ${YouTubeDataFieldsFragmentDoc}
  ${ExtendedVideoAssetDataFieldsFragmentDoc}
  ${PollDataFieldsFragmentDoc}
  ${EditorialBannerDataFieldsFragmentDoc}
  ${FactBoxDataFieldsFragmentDoc}
  ${SportStandingsTableFieldsFragmentDoc}
  ${BalanceOfPowerDataFieldsFragmentDoc}
`;
export const NodeDataFieldsFragmentDoc = gql`
  fragment NodeDataFields on NodeData {
    id
    data {
      ...NodeDataUnionFields
    }
  }
  ${NodeDataUnionFieldsFragmentDoc}
`;
export const FeedOriginWithTypeFieldsFragmentDoc = gql`
  fragment FeedOriginWithTypeFields on FeedOrigin {
    ...FeedOriginFields
    type
  }
  ${FeedOriginFieldsFragmentDoc}
`;
export const RelatedArticleFieldsFragmentDoc = gql`
  fragment RelatedArticleFields on RelatedArticle {
    id
    title
    slug
    publishedDateTime
    humanPublishedDateTime
    humanUpdatedDateTime
    updatedDateTime
    lead
    articleLeadRichText
    liveReportId
    byline {
      name
      email
      bylineImage
    }
    bylineName
    bylineEmail
    featuredFeed {
      ...FeedOriginFields
    }
    metadata {
      id
      wordCount
      linkCount
      imageCount
      videoCount
    }
    body
    data {
      ...NodeDataFields
    }
    featuredAsset {
      ...VideoPlayerDataFields
      ...ImageFields
    }
    isAdsHidden
    isSponsoredArticle
    feedOrigins {
      ...FeedOriginWithTypeFields
    }
  }
  ${FeedOriginFieldsFragmentDoc}
  ${NodeDataFieldsFragmentDoc}
  ${VideoPlayerDataFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${FeedOriginWithTypeFieldsFragmentDoc}
`;
export const ArticleFieldsFragmentDoc = gql`
  fragment ArticleFields on Article {
    id
    title
    slug
    publishedDateTime
    humanPublishedDateTime
    humanUpdatedDateTime
    updatedDateTime
    live
    lead
    articleLeadRichText
    liveReportId
    bylineName
    bylineEmail
    isOpinionPiece
    byline {
      name
      email
      bylineImage
    }
    featuredFeed {
      ...FeedOriginFields
    }
    body
    data {
      ...NodeDataFields
    }
    metadata {
      id
      wordCount
      linkCount
      imageCount
      videoCount
    }
    featuredAsset {
      ...ImageFields
      ...VideoPlayerDataFields
    }
    related {
      ...RelatedArticleFields
      ...VideoPlayerDataFields
    }
    feedOrigins {
      ...FeedOriginWithTypeFields
    }
    metaTitle
    metaDescription
    ogTitle
    ogDescription
    isAdsHidden
    isSponsoredArticle
    sponsoredByBanner
    showPobInFeed
    showElectionMap
  }
  ${FeedOriginFieldsFragmentDoc}
  ${NodeDataFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${VideoPlayerDataFieldsFragmentDoc}
  ${RelatedArticleFieldsFragmentDoc}
  ${FeedOriginWithTypeFieldsFragmentDoc}
`;
export const ArticleUrlDataFieldsFragmentDoc = gql`
  fragment ArticleUrlDataFields on ArticleUrlData {
    id
    title
    slug
    publishedAt
    updatedAt
    __typename
  }
`;
export const ExternalLinkFieldsFragmentDoc = gql`
  fragment ExternalLinkFields on ExternalLink {
    id
    url
    title
  }
`;
export const FeatureFlagFieldsFragmentDoc = gql`
  fragment FeatureFlagFields on FeatureFlag {
    id
    key
    active
  }
`;
export const FeedLinkFieldsFragmentDoc = gql`
  fragment FeedLinkFields on FeedLink {
    id
    slug
    title
  }
`;
export const InternalLinkFieldsFragmentDoc = gql`
  fragment InternalLinkFields on InternalLink {
    id
    title
    url
  }
`;
export const ListAllNewsFeedsFieldsFragmentDoc = gql`
  fragment ListAllNewsFeedsFields on FeedUrlData {
    id
    slug
    title
    updatedAt
    publishedAt
  }
`;
export const MediaPanelItemFieldsFragmentDoc = gql`
  fragment MediaPanelItemFields on MediaPanelItem {
    id
    title
    type
    link
    image {
      url
      ratio
    }
  }
`;
export const HeroFieldsFragmentDoc = gql`
  fragment HeroFields on Hero {
    id
    title
    image
    heroImage
    description
    shortDescription
    program {
      nid
      id
    }
    twitterUrl
    facebookUrl
    instagramUrl
  }
`;
export const ArticleGroupArticleFieldsFragmentDoc = gql`
  fragment ArticleGroupArticleFields on ArticleGroupArticle {
    id
    slug
    title
    live
    lead
    featuredFeed {
      title
      slug
    }
    feedOrigins {
      ...FeedOriginWithTypeFields
    }
    publishedDateTime
    humanPublishedDateTime
    updatedDateTime
    humanUpdatedDateTime
    liveReportId
    bylineName
    bylineEmail
    isOpinionPiece
    byline {
      name
      email
      bylineImage
    }
    isSponsoredArticle
    featuredAsset {
      ... on VideoPlayerData {
        id
        title
        asset {
          id
          title
          slug
          description
          live
          type
          image
          duration
          humanDuration
          publishedDateTime
          humanPublishedDateTime
          humanBroadcastShortDateTime
          ratio
          program {
            nid
          }
        }
        caption
        thumbnail
        thumbnailByline
      }
      ... on Image {
        id
        url
        caption
        byline
        linkUrl
        ratio
      }
    }
  }
  ${FeedOriginWithTypeFieldsFragmentDoc}
`;
export const ArticleGroupBlockArticleFieldsFragmentDoc = gql`
  fragment ArticleGroupBlockArticleFields on ArticleGroupArticle {
    id
    slug
    title
    live
    lead
    publishedDateTime
    humanPublishedDateTime
    updatedDateTime
    humanUpdatedDateTime
    liveReportId
    bylineName
    bylineEmail
    isSponsoredArticle
    feedOrigins {
      ...FeedOriginWithTypeFields
    }
    featuredAsset {
      ... on VideoPlayerData {
        id
        title
        asset {
          id
          title
          slug
          description
          live
          type
          image
          duration
          humanDuration
          publishedDateTime
          humanPublishedDateTime
          humanBroadcastShortDateTime
          ratio
          program {
            nid
          }
        }
        caption
        thumbnail
        thumbnailByline
      }
      ... on Image {
        id
        url
        caption
        byline
        linkUrl
        ratio
      }
    }
  }
  ${FeedOriginWithTypeFieldsFragmentDoc}
`;
export const EmphasizedArticleGroupFieldsFragmentDoc = gql`
  fragment EmphasizedArticleGroupFields on ArticleGroup {
    __typename
    id
    title
    isEmphasized
    content {
      __typename
      ...ArticleGroupArticleFields
      ... on ArticleGroupBlock {
        id
        layout
        imagePlacement
        content {
          ...ArticleGroupBlockArticleFields
        }
      }
    }
  }
  ${ArticleGroupArticleFieldsFragmentDoc}
  ${ArticleGroupBlockArticleFieldsFragmentDoc}
`;
export const UpsellImageMetadataFieldsFragmentDoc = gql`
  fragment UpsellImageMetadataFields on UpsellImageMetadata {
    blurHash
    hexColor
  }
`;
export const UpsellImageFieldsFragmentDoc = gql`
  fragment UpsellImageFields on UpsellImage {
    id
    url
    metadata {
      ...UpsellImageMetadataFields
    }
  }
  ${UpsellImageMetadataFieldsFragmentDoc}
`;
export const UpsellFieldsFragmentDoc = gql`
  fragment UpsellFields on Upsell {
    id
    title
    subtitle
    imageData {
      ...UpsellImageFields
    }
    backgroundColor
    linkUrl
    linkText
    label
  }
  ${UpsellImageFieldsFragmentDoc}
`;
export const ArticleInFeedFieldsFragmentDoc = gql`
  fragment ArticleInFeedFields on Article {
    id
    title
    slug
    live
    lead
    liveReportId
    isOpinionPiece
    byline {
      name
      email
      bylineImage
    }
    featuredFeed {
      ...FeedOriginFields
    }
    metadata {
      id
      wordCount
      linkCount
      imageCount
      videoCount
    }
    featuredAsset {
      ...ImageFields
      ...VideoPlayerDataFields
    }
    related {
      ...RelatedArticleFields
      ...VideoPlayerDataFields
    }
    feedOrigins {
      ...FeedOriginWithTypeFields
    }
    metaTitle
    metaDescription
    ogTitle
    ogDescription
    isAdsHidden
    isSponsoredArticle
    showPobInFeed
  }
  ${FeedOriginFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${VideoPlayerDataFieldsFragmentDoc}
  ${RelatedArticleFieldsFragmentDoc}
  ${FeedOriginWithTypeFieldsFragmentDoc}
`;
export const LiveReportFieldsFragmentDoc = gql`
  fragment LiveReportFields on LiveReport {
    id
    liveReportId
    title
    text
  }
`;
export const MediaSliderFieldsFragmentDoc = gql`
  fragment MediaSliderFields on MediaSlider {
    id
    title
    nordicCmsPanelId
  }
`;
export const ArticleGroupFieldsFragmentDoc = gql`
  fragment ArticleGroupFields on ArticleGroup {
    __typename
    id
    title
    isEmphasized
    content {
      __typename
      ...ArticleGroupArticleFields
      ... on ArticleGroupBlock {
        id
        layout
        imagePlacement
        content {
          ...ArticleGroupBlockArticleFields
        }
      }
    }
  }
  ${ArticleGroupArticleFieldsFragmentDoc}
  ${ArticleGroupBlockArticleFieldsFragmentDoc}
`;
export const ShortsSliderFieldsFragmentDoc = gql`
  fragment ShortsSliderFields on ShortsSlider {
    __typename
    id
    title
    items {
      id
      title
      metadataId
      thumbnail
      vimondId
      duration
      relativePublishDate
    }
  }
`;
export const SponsorFieldsFragmentDoc = gql`
  fragment SponsorFields on Sponsor {
    id
    title
    link
    image
  }
`;
export const PaginatedNewsFeedFieldsFragmentDoc = gql`
  fragment PaginatedNewsFeedFields on PaginatedNewsFeed {
    id
    slug
    redirect
    title
    metaTitle
    metaDescription
    ogTitle
    ogDescription
    ogImage
    main16x9Annotated
    category
    publishedAt
    modifiedAt
    hero {
      ...HeroFields
    }
    emphasizedArticleGroup {
      __typename
      content {
        ...EmphasizedArticleGroupFields
      }
    }
    feed(page: $page, limit: $limit, excludeVideoAssetList: $excludeVideoAssetList) {
      pagingData {
        currentPage
        nextPage
      }
      items {
        ... on BreakingNewsModule {
          id
        }
        ... on Upsell {
          ...UpsellFields
        }
        ... on VideoAsset {
          ...VideoAssetFields
        }
        ... on Article {
          ...ArticleInFeedFields
        }
        ... on LiveReport {
          ...LiveReportFields
        }
        ... on MediaSlider {
          ...MediaSliderFields
        }
        ... on SportResultsModule {
          id
        }
        ... on ArticleGroup {
          ...ArticleGroupFields
        }
        ... on ShortsSlider {
          ...ShortsSliderFields
        }
      }
    }
    sponsors {
      ...SponsorFields
    }
  }
  ${HeroFieldsFragmentDoc}
  ${EmphasizedArticleGroupFieldsFragmentDoc}
  ${UpsellFieldsFragmentDoc}
  ${VideoAssetFieldsFragmentDoc}
  ${ArticleInFeedFieldsFragmentDoc}
  ${LiveReportFieldsFragmentDoc}
  ${MediaSliderFieldsFragmentDoc}
  ${ArticleGroupFieldsFragmentDoc}
  ${ShortsSliderFieldsFragmentDoc}
  ${SponsorFieldsFragmentDoc}
`;
export const SynopsisFieldsFragmentDoc = gql`
  fragment SynopsisFields on Synopsis {
    __typename
    id
    brief
    long
    medium
    short
  }
`;
export const BroadcastFieldsFragmentDoc = gql`
  fragment BroadcastFields on Broadcast {
    __typename
    id
    title
    start
    end
    synopsis {
      ...SynopsisFields
    }
  }
  ${SynopsisFieldsFragmentDoc}
`;
export const ScheduleFieldsFragmentDoc = gql`
  fragment ScheduleFields on Schedule {
    __typename
    id
    metadataChannelId
    name
    displayName
    day
    broadcasts {
      ...BroadcastFields
    }
  }
  ${BroadcastFieldsFragmentDoc}
`;
export const VideoUrlDataFieldsFragmentDoc = gql`
  fragment VideoUrlDataFields on VideoUrlData {
    __typename
    id
    metadataId
    slug
    thumbnail
    title
    description
    duration
    publishedAt
    tags
    id
    title
  }
`;
export const NewsFeedForPluginFieldsFragmentDoc = gql`
  fragment NewsFeedForPluginFields on NewsFeedForPlugin {
    id
    title
  }
`;
export const NewsFeedLinkHierarchyForPluginFieldsFragmentDoc = gql`
  fragment NewsFeedLinkHierarchyForPluginFields on NewsFeedLinkHierarchyForPlugin {
    id
    title
    category {
      ...NewsFeedForPluginFields
    }
    subcategories {
      ...NewsFeedForPluginFields
    }
  }
  ${NewsFeedForPluginFieldsFragmentDoc}
`;
export const BreakingNewsFieldsFragmentDoc = gql`
  fragment BreakingNewsFields on BreakingNews {
    id
    title
    publishedAt
    humanPublishedAt
    firstPublishedAt
    humanFirstPublishedAt
    articleId
    byline {
      name
      email
      bylineImage
    }
    bylineName
    bylineEmail
    body
    data {
      ...NodeDataFields
    }
  }
  ${NodeDataFieldsFragmentDoc}
`;
export const LatestBreakingNewsFieldsFragmentDoc = gql`
  fragment LatestBreakingNewsFields on LatestBreakingNews {
    total
    items {
      ...BreakingNewsFields
    }
  }
  ${BreakingNewsFieldsFragmentDoc}
`;
export const LinkFieldsFragmentDoc = gql`
  fragment LinkFields on Link {
    __typename
    id
    linkId
    title
    href
    icon
    targetType
  }
`;
export const LinkHierarchyFieldsFragmentDoc = gql`
  fragment LinkHierarchyFields on LinkHierarchy {
    __typename
    id
    icon
    categoryTitle
    parentLink {
      ...LinkFields
    }
    childLinks {
      ...LinkFields
    }
  }
  ${LinkFieldsFragmentDoc}
`;
export const GroupFieldsFragmentDoc = gql`
  fragment GroupFields on Group {
    id
    name
    links {
      ...LinkFields
      ...LinkHierarchyFields
    }
    __typename
  }
  ${LinkFieldsFragmentDoc}
  ${LinkHierarchyFieldsFragmentDoc}
`;
export const ArticlePartsFragmentDoc = gql`
  fragment ArticleParts on ArticleGroupArticle {
    id
    title
    slug
    feedOrigins {
      slug
      title
    }
    publishedDateTime
    lead
    featuredAsset {
      ... on VideoPlayerData {
        id
        title
        asset {
          image
        }
        metadataId
        caption
        thumbnail
        thumbnailByline
      }
      ... on Image {
        id
        url
        caption
        ratio
        byline
        linkUrl
      }
    }
  }
`;
export const IceHockeyResultFieldsFragmentDoc = gql`
  fragment IceHockeyResultFields on IceHockeyResult {
    _id
    homeTeam {
      id
      displayName
    }
    awayTeam {
      id
      displayName
    }
    matchStatus
    eventStatus
    date
    scores {
      score {
        homeScore
        awayScore
      }
    }
    tv4playLink
    __typename
  }
`;
export const FootballResultFieldsFragmentDoc = gql`
  fragment FootballResultFields on FootballResult {
    _id
    homeTeam {
      id
      displayName
    }
    awayTeam {
      id
      displayName
    }
    matchStatus
    eventStatus
    date
    scores {
      score {
        homeScore
        awayScore
      }
    }
    tv4playLink
    __typename
  }
`;
export const AppInfoDocument = gql`
  query appInfo {
    appInfo {
      minimumVersion
    }
  }
`;

/**
 * __useAppInfoQuery__
 *
 * To run a query within a React component, call `useAppInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppInfoQuery(baseOptions?: Apollo.QueryHookOptions<AppInfoQuery, AppInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppInfoQuery, AppInfoQueryVariables>(AppInfoDocument, options);
}
export function useAppInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppInfoQuery, AppInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppInfoQuery, AppInfoQueryVariables>(AppInfoDocument, options);
}
export function useAppInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AppInfoQuery, AppInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AppInfoQuery, AppInfoQueryVariables>(AppInfoDocument, options);
}
export type AppInfoQueryHookResult = ReturnType<typeof useAppInfoQuery>;
export type AppInfoLazyQueryHookResult = ReturnType<typeof useAppInfoLazyQuery>;
export type AppInfoSuspenseQueryHookResult = ReturnType<typeof useAppInfoSuspenseQuery>;
export type AppInfoQueryResult = Apollo.QueryResult<AppInfoQuery, AppInfoQueryVariables>;
export const ArticleUrlDataByDateDocument = gql`
  query articleUrlDataByDate($from: String!, $to: String!, $limit: Int, $skip: Int) {
    articleUrlDataByDate(from: $from, to: $to, limit: $limit, skip: $skip) {
      total
      items {
        ... on ArticleUrlData {
          ...ArticleUrlDataFields
        }
      }
    }
  }
  ${ArticleUrlDataFieldsFragmentDoc}
`;

/**
 * __useArticleUrlDataByDateQuery__
 *
 * To run a query within a React component, call `useArticleUrlDataByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleUrlDataByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleUrlDataByDateQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useArticleUrlDataByDateQuery(
  baseOptions: Apollo.QueryHookOptions<ArticleUrlDataByDateQuery, ArticleUrlDataByDateQueryVariables> &
    ({ variables: ArticleUrlDataByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleUrlDataByDateQuery, ArticleUrlDataByDateQueryVariables>(
    ArticleUrlDataByDateDocument,
    options
  );
}
export function useArticleUrlDataByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArticleUrlDataByDateQuery, ArticleUrlDataByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticleUrlDataByDateQuery, ArticleUrlDataByDateQueryVariables>(
    ArticleUrlDataByDateDocument,
    options
  );
}
export function useArticleUrlDataByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ArticleUrlDataByDateQuery, ArticleUrlDataByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ArticleUrlDataByDateQuery, ArticleUrlDataByDateQueryVariables>(
    ArticleUrlDataByDateDocument,
    options
  );
}
export type ArticleUrlDataByDateQueryHookResult = ReturnType<typeof useArticleUrlDataByDateQuery>;
export type ArticleUrlDataByDateLazyQueryHookResult = ReturnType<typeof useArticleUrlDataByDateLazyQuery>;
export type ArticleUrlDataByDateSuspenseQueryHookResult = ReturnType<typeof useArticleUrlDataByDateSuspenseQuery>;
export type ArticleUrlDataByDateQueryResult = Apollo.QueryResult<
  ArticleUrlDataByDateQuery,
  ArticleUrlDataByDateQueryVariables
>;
export const ArticlesDocument = gql`
  query articles($ids: [ID!]!) {
    articles(ids: $ids) {
      ...ArticleFields
    }
  }
  ${ArticleFieldsFragmentDoc}
`;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables> &
    ({ variables: ArticlesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
}
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
}
export function useArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
}
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesSuspenseQueryHookResult = ReturnType<typeof useArticlesSuspenseQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const BreakingNewsDocument = gql`
  query breakingNews($id: ID!) {
    breakingNews(id: $id) {
      ...BreakingNewsFields
    }
  }
  ${BreakingNewsFieldsFragmentDoc}
`;

/**
 * __useBreakingNewsQuery__
 *
 * To run a query within a React component, call `useBreakingNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreakingNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreakingNewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBreakingNewsQuery(
  baseOptions: Apollo.QueryHookOptions<BreakingNewsQuery, BreakingNewsQueryVariables> &
    ({ variables: BreakingNewsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BreakingNewsQuery, BreakingNewsQueryVariables>(BreakingNewsDocument, options);
}
export function useBreakingNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BreakingNewsQuery, BreakingNewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BreakingNewsQuery, BreakingNewsQueryVariables>(BreakingNewsDocument, options);
}
export function useBreakingNewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BreakingNewsQuery, BreakingNewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BreakingNewsQuery, BreakingNewsQueryVariables>(BreakingNewsDocument, options);
}
export type BreakingNewsQueryHookResult = ReturnType<typeof useBreakingNewsQuery>;
export type BreakingNewsLazyQueryHookResult = ReturnType<typeof useBreakingNewsLazyQuery>;
export type BreakingNewsSuspenseQueryHookResult = ReturnType<typeof useBreakingNewsSuspenseQuery>;
export type BreakingNewsQueryResult = Apollo.QueryResult<BreakingNewsQuery, BreakingNewsQueryVariables>;
export const NewsFeedCategorizerForPluginDocument = gql`
  query newsFeedCategorizerForPlugin($ids: [ID!]!) {
    newsFeedCategorizerForPlugin(ids: $ids) {
      categories {
        ...NewsFeedForPluginFields
      }
      subcategories {
        ...NewsFeedForPluginFields
      }
      tags {
        ...NewsFeedForPluginFields
      }
    }
  }
  ${NewsFeedForPluginFieldsFragmentDoc}
`;

/**
 * __useNewsFeedCategorizerForPluginQuery__
 *
 * To run a query within a React component, call `useNewsFeedCategorizerForPluginQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsFeedCategorizerForPluginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsFeedCategorizerForPluginQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useNewsFeedCategorizerForPluginQuery(
  baseOptions: Apollo.QueryHookOptions<NewsFeedCategorizerForPluginQuery, NewsFeedCategorizerForPluginQueryVariables> &
    ({ variables: NewsFeedCategorizerForPluginQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsFeedCategorizerForPluginQuery, NewsFeedCategorizerForPluginQueryVariables>(
    NewsFeedCategorizerForPluginDocument,
    options
  );
}
export function useNewsFeedCategorizerForPluginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewsFeedCategorizerForPluginQuery,
    NewsFeedCategorizerForPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsFeedCategorizerForPluginQuery, NewsFeedCategorizerForPluginQueryVariables>(
    NewsFeedCategorizerForPluginDocument,
    options
  );
}
export function useNewsFeedCategorizerForPluginSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NewsFeedCategorizerForPluginQuery,
    NewsFeedCategorizerForPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NewsFeedCategorizerForPluginQuery, NewsFeedCategorizerForPluginQueryVariables>(
    NewsFeedCategorizerForPluginDocument,
    options
  );
}
export type NewsFeedCategorizerForPluginQueryHookResult = ReturnType<typeof useNewsFeedCategorizerForPluginQuery>;
export type NewsFeedCategorizerForPluginLazyQueryHookResult = ReturnType<
  typeof useNewsFeedCategorizerForPluginLazyQuery
>;
export type NewsFeedCategorizerForPluginSuspenseQueryHookResult = ReturnType<
  typeof useNewsFeedCategorizerForPluginSuspenseQuery
>;
export type NewsFeedCategorizerForPluginQueryResult = Apollo.QueryResult<
  NewsFeedCategorizerForPluginQuery,
  NewsFeedCategorizerForPluginQueryVariables
>;
export const NewsFeedItemsForPluginDocument = gql`
  query newsFeedItemsForPlugin(
    $autoPopulateFeedOptions: AutoPopulateFeedOptions!
    $feedId: String!
    $videoSearchInput: VideoSearchInput!
    $curatedFeed: JSON!
  ) {
    newsFeedItemsForPlugin(
      autoPopulateFeedOptions: $autoPopulateFeedOptions
      feedId: $feedId
      videoSearchInput: $videoSearchInput
      curatedFeed: $curatedFeed
    ) {
      additionReason
      newsFeedItem {
        ... on VideoPlayerData {
          id
          title
          __typename
        }
        ... on VideoAsset {
          id
          title
          __typename
        }
        ... on Article {
          id
          title
          live
          __typename
        }
        ... on Upsell {
          id
          title
          __typename
        }
        ... on LiveReport {
          id
          title
          __typename
        }
        ... on MediaSlider {
          id
          title
          __typename
        }
        ... on BreakingNewsModule {
          id
          __typename
        }
        ... on SportResultsModule {
          id
          __typename
        }
      }
    }
  }
`;

/**
 * __useNewsFeedItemsForPluginQuery__
 *
 * To run a query within a React component, call `useNewsFeedItemsForPluginQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsFeedItemsForPluginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsFeedItemsForPluginQuery({
 *   variables: {
 *      autoPopulateFeedOptions: // value for 'autoPopulateFeedOptions'
 *      feedId: // value for 'feedId'
 *      videoSearchInput: // value for 'videoSearchInput'
 *      curatedFeed: // value for 'curatedFeed'
 *   },
 * });
 */
export function useNewsFeedItemsForPluginQuery(
  baseOptions: Apollo.QueryHookOptions<NewsFeedItemsForPluginQuery, NewsFeedItemsForPluginQueryVariables> &
    ({ variables: NewsFeedItemsForPluginQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsFeedItemsForPluginQuery, NewsFeedItemsForPluginQueryVariables>(
    NewsFeedItemsForPluginDocument,
    options
  );
}
export function useNewsFeedItemsForPluginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewsFeedItemsForPluginQuery, NewsFeedItemsForPluginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsFeedItemsForPluginQuery, NewsFeedItemsForPluginQueryVariables>(
    NewsFeedItemsForPluginDocument,
    options
  );
}
export function useNewsFeedItemsForPluginSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NewsFeedItemsForPluginQuery, NewsFeedItemsForPluginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NewsFeedItemsForPluginQuery, NewsFeedItemsForPluginQueryVariables>(
    NewsFeedItemsForPluginDocument,
    options
  );
}
export type NewsFeedItemsForPluginQueryHookResult = ReturnType<typeof useNewsFeedItemsForPluginQuery>;
export type NewsFeedItemsForPluginLazyQueryHookResult = ReturnType<typeof useNewsFeedItemsForPluginLazyQuery>;
export type NewsFeedItemsForPluginSuspenseQueryHookResult = ReturnType<typeof useNewsFeedItemsForPluginSuspenseQuery>;
export type NewsFeedItemsForPluginQueryResult = Apollo.QueryResult<
  NewsFeedItemsForPluginQuery,
  NewsFeedItemsForPluginQueryVariables
>;
export const NewsFeedLinkHierarchyForPluginDocument = gql`
  query newsFeedLinkHierarchyForPlugin {
    newsFeedLinkHierarchyForPlugin {
      ...NewsFeedLinkHierarchyForPluginFields
    }
  }
  ${NewsFeedLinkHierarchyForPluginFieldsFragmentDoc}
`;

/**
 * __useNewsFeedLinkHierarchyForPluginQuery__
 *
 * To run a query within a React component, call `useNewsFeedLinkHierarchyForPluginQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsFeedLinkHierarchyForPluginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsFeedLinkHierarchyForPluginQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewsFeedLinkHierarchyForPluginQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewsFeedLinkHierarchyForPluginQuery,
    NewsFeedLinkHierarchyForPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsFeedLinkHierarchyForPluginQuery, NewsFeedLinkHierarchyForPluginQueryVariables>(
    NewsFeedLinkHierarchyForPluginDocument,
    options
  );
}
export function useNewsFeedLinkHierarchyForPluginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewsFeedLinkHierarchyForPluginQuery,
    NewsFeedLinkHierarchyForPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsFeedLinkHierarchyForPluginQuery, NewsFeedLinkHierarchyForPluginQueryVariables>(
    NewsFeedLinkHierarchyForPluginDocument,
    options
  );
}
export function useNewsFeedLinkHierarchyForPluginSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NewsFeedLinkHierarchyForPluginQuery,
    NewsFeedLinkHierarchyForPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NewsFeedLinkHierarchyForPluginQuery, NewsFeedLinkHierarchyForPluginQueryVariables>(
    NewsFeedLinkHierarchyForPluginDocument,
    options
  );
}
export type NewsFeedLinkHierarchyForPluginQueryHookResult = ReturnType<typeof useNewsFeedLinkHierarchyForPluginQuery>;
export type NewsFeedLinkHierarchyForPluginLazyQueryHookResult = ReturnType<
  typeof useNewsFeedLinkHierarchyForPluginLazyQuery
>;
export type NewsFeedLinkHierarchyForPluginSuspenseQueryHookResult = ReturnType<
  typeof useNewsFeedLinkHierarchyForPluginSuspenseQuery
>;
export type NewsFeedLinkHierarchyForPluginQueryResult = Apollo.QueryResult<
  NewsFeedLinkHierarchyForPluginQuery,
  NewsFeedLinkHierarchyForPluginQueryVariables
>;
export const NewsFeedTagSearchForPluginDocument = gql`
  query newsFeedTagSearchForPlugin($search: String!) {
    newsFeedTagSearchForPlugin(search: $search) {
      ...NewsFeedForPluginFields
    }
  }
  ${NewsFeedForPluginFieldsFragmentDoc}
`;

/**
 * __useNewsFeedTagSearchForPluginQuery__
 *
 * To run a query within a React component, call `useNewsFeedTagSearchForPluginQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsFeedTagSearchForPluginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsFeedTagSearchForPluginQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useNewsFeedTagSearchForPluginQuery(
  baseOptions: Apollo.QueryHookOptions<NewsFeedTagSearchForPluginQuery, NewsFeedTagSearchForPluginQueryVariables> &
    ({ variables: NewsFeedTagSearchForPluginQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsFeedTagSearchForPluginQuery, NewsFeedTagSearchForPluginQueryVariables>(
    NewsFeedTagSearchForPluginDocument,
    options
  );
}
export function useNewsFeedTagSearchForPluginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewsFeedTagSearchForPluginQuery, NewsFeedTagSearchForPluginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsFeedTagSearchForPluginQuery, NewsFeedTagSearchForPluginQueryVariables>(
    NewsFeedTagSearchForPluginDocument,
    options
  );
}
export function useNewsFeedTagSearchForPluginSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NewsFeedTagSearchForPluginQuery,
    NewsFeedTagSearchForPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NewsFeedTagSearchForPluginQuery, NewsFeedTagSearchForPluginQueryVariables>(
    NewsFeedTagSearchForPluginDocument,
    options
  );
}
export type NewsFeedTagSearchForPluginQueryHookResult = ReturnType<typeof useNewsFeedTagSearchForPluginQuery>;
export type NewsFeedTagSearchForPluginLazyQueryHookResult = ReturnType<typeof useNewsFeedTagSearchForPluginLazyQuery>;
export type NewsFeedTagSearchForPluginSuspenseQueryHookResult = ReturnType<
  typeof useNewsFeedTagSearchForPluginSuspenseQuery
>;
export type NewsFeedTagSearchForPluginQueryResult = Apollo.QueryResult<
  NewsFeedTagSearchForPluginQuery,
  NewsFeedTagSearchForPluginQueryVariables
>;
export const ShortVideoSearchForPluginDocument = gql`
  query shortVideoSearchForPlugin($tags: String) {
    shortVideoSearch(tags: $tags) {
      __typename
      total_hits
      from
      to
      hits {
        id
        title
        publishedAt
        metadataId
        image
        thumbnail
        __typename
      }
    }
  }
`;

/**
 * __useShortVideoSearchForPluginQuery__
 *
 * To run a query within a React component, call `useShortVideoSearchForPluginQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortVideoSearchForPluginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortVideoSearchForPluginQuery({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useShortVideoSearchForPluginQuery(
  baseOptions?: Apollo.QueryHookOptions<ShortVideoSearchForPluginQuery, ShortVideoSearchForPluginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShortVideoSearchForPluginQuery, ShortVideoSearchForPluginQueryVariables>(
    ShortVideoSearchForPluginDocument,
    options
  );
}
export function useShortVideoSearchForPluginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShortVideoSearchForPluginQuery, ShortVideoSearchForPluginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShortVideoSearchForPluginQuery, ShortVideoSearchForPluginQueryVariables>(
    ShortVideoSearchForPluginDocument,
    options
  );
}
export function useShortVideoSearchForPluginSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ShortVideoSearchForPluginQuery, ShortVideoSearchForPluginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ShortVideoSearchForPluginQuery, ShortVideoSearchForPluginQueryVariables>(
    ShortVideoSearchForPluginDocument,
    options
  );
}
export type ShortVideoSearchForPluginQueryHookResult = ReturnType<typeof useShortVideoSearchForPluginQuery>;
export type ShortVideoSearchForPluginLazyQueryHookResult = ReturnType<typeof useShortVideoSearchForPluginLazyQuery>;
export type ShortVideoSearchForPluginSuspenseQueryHookResult = ReturnType<
  typeof useShortVideoSearchForPluginSuspenseQuery
>;
export type ShortVideoSearchForPluginQueryResult = Apollo.QueryResult<
  ShortVideoSearchForPluginQuery,
  ShortVideoSearchForPluginQueryVariables
>;
export const FeatureFlagsDocument = gql`
  query featureFlags {
    featureFlags {
      ...FeatureFlagFields
    }
  }
  ${FeatureFlagFieldsFragmentDoc}
`;

/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, options);
}
export function useFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, options);
}
export function useFeatureFlagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, options);
}
export type FeatureFlagsQueryHookResult = ReturnType<typeof useFeatureFlagsQuery>;
export type FeatureFlagsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsLazyQuery>;
export type FeatureFlagsSuspenseQueryHookResult = ReturnType<typeof useFeatureFlagsSuspenseQuery>;
export type FeatureFlagsQueryResult = Apollo.QueryResult<FeatureFlagsQuery, FeatureFlagsQueryVariables>;
export const FeedHeroDocument = gql`
  query feedHero($slug: String!) {
    paginatedNewsFeed(slug: $slug) {
      id
      hero {
        ...HeroFields
      }
    }
  }
  ${HeroFieldsFragmentDoc}
`;

/**
 * __useFeedHeroQuery__
 *
 * To run a query within a React component, call `useFeedHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedHeroQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFeedHeroQuery(
  baseOptions: Apollo.QueryHookOptions<FeedHeroQuery, FeedHeroQueryVariables> &
    ({ variables: FeedHeroQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeedHeroQuery, FeedHeroQueryVariables>(FeedHeroDocument, options);
}
export function useFeedHeroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedHeroQuery, FeedHeroQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FeedHeroQuery, FeedHeroQueryVariables>(FeedHeroDocument, options);
}
export function useFeedHeroSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FeedHeroQuery, FeedHeroQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FeedHeroQuery, FeedHeroQueryVariables>(FeedHeroDocument, options);
}
export type FeedHeroQueryHookResult = ReturnType<typeof useFeedHeroQuery>;
export type FeedHeroLazyQueryHookResult = ReturnType<typeof useFeedHeroLazyQuery>;
export type FeedHeroSuspenseQueryHookResult = ReturnType<typeof useFeedHeroSuspenseQuery>;
export type FeedHeroQueryResult = Apollo.QueryResult<FeedHeroQuery, FeedHeroQueryVariables>;
export const LatestBreakingNewsDocument = gql`
  query latestBreakingNews($skip: Int, $limit: Int) {
    latestBreakingNews(skip: $skip, limit: $limit) {
      ...LatestBreakingNewsFields
    }
  }
  ${LatestBreakingNewsFieldsFragmentDoc}
`;

/**
 * __useLatestBreakingNewsQuery__
 *
 * To run a query within a React component, call `useLatestBreakingNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestBreakingNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestBreakingNewsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLatestBreakingNewsQuery(
  baseOptions?: Apollo.QueryHookOptions<LatestBreakingNewsQuery, LatestBreakingNewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestBreakingNewsQuery, LatestBreakingNewsQueryVariables>(
    LatestBreakingNewsDocument,
    options
  );
}
export function useLatestBreakingNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestBreakingNewsQuery, LatestBreakingNewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestBreakingNewsQuery, LatestBreakingNewsQueryVariables>(
    LatestBreakingNewsDocument,
    options
  );
}
export function useLatestBreakingNewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LatestBreakingNewsQuery, LatestBreakingNewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LatestBreakingNewsQuery, LatestBreakingNewsQueryVariables>(
    LatestBreakingNewsDocument,
    options
  );
}
export type LatestBreakingNewsQueryHookResult = ReturnType<typeof useLatestBreakingNewsQuery>;
export type LatestBreakingNewsLazyQueryHookResult = ReturnType<typeof useLatestBreakingNewsLazyQuery>;
export type LatestBreakingNewsSuspenseQueryHookResult = ReturnType<typeof useLatestBreakingNewsSuspenseQuery>;
export type LatestBreakingNewsQueryResult = Apollo.QueryResult<
  LatestBreakingNewsQuery,
  LatestBreakingNewsQueryVariables
>;
export const ListNewsFeedsDocument = gql`
  query listNewsFeeds {
    listNewsFeeds {
      ...ListAllNewsFeedsFields
    }
  }
  ${ListAllNewsFeedsFieldsFragmentDoc}
`;

/**
 * __useListNewsFeedsQuery__
 *
 * To run a query within a React component, call `useListNewsFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNewsFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNewsFeedsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListNewsFeedsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListNewsFeedsQuery, ListNewsFeedsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListNewsFeedsQuery, ListNewsFeedsQueryVariables>(ListNewsFeedsDocument, options);
}
export function useListNewsFeedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListNewsFeedsQuery, ListNewsFeedsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListNewsFeedsQuery, ListNewsFeedsQueryVariables>(ListNewsFeedsDocument, options);
}
export function useListNewsFeedsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListNewsFeedsQuery, ListNewsFeedsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListNewsFeedsQuery, ListNewsFeedsQueryVariables>(ListNewsFeedsDocument, options);
}
export type ListNewsFeedsQueryHookResult = ReturnType<typeof useListNewsFeedsQuery>;
export type ListNewsFeedsLazyQueryHookResult = ReturnType<typeof useListNewsFeedsLazyQuery>;
export type ListNewsFeedsSuspenseQueryHookResult = ReturnType<typeof useListNewsFeedsSuspenseQuery>;
export type ListNewsFeedsQueryResult = Apollo.QueryResult<ListNewsFeedsQuery, ListNewsFeedsQueryVariables>;
export const MenuDocument = gql`
  query menu($id: ID!) {
    menu(id: $id) {
      id
      __typename
      groups {
        ...GroupFields
      }
    }
  }
  ${GroupFieldsFragmentDoc}
`;

/**
 * __useMenuQuery__
 *
 * To run a query within a React component, call `useMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuQuery(
  baseOptions: Apollo.QueryHookOptions<MenuQuery, MenuQueryVariables> &
    ({ variables: MenuQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
}
export function useMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuQuery, MenuQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
}
export function useMenuSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MenuQuery, MenuQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
}
export type MenuQueryHookResult = ReturnType<typeof useMenuQuery>;
export type MenuLazyQueryHookResult = ReturnType<typeof useMenuLazyQuery>;
export type MenuSuspenseQueryHookResult = ReturnType<typeof useMenuSuspenseQuery>;
export type MenuQueryResult = Apollo.QueryResult<MenuQuery, MenuQueryVariables>;
export const NordicCmsPanelDocument = gql`
  query nordicCmsPanel($id: ID!, $offset: Int!, $limit: Int!) {
    nordicCmsPanel(id: $id, offset: $offset, limit: $limit) {
      id
      link
      pagingMeta {
        hasNextPage
        nextPageOffset
        totalCount
      }
      items {
        ...MediaPanelItemFields
      }
    }
  }
  ${MediaPanelItemFieldsFragmentDoc}
`;

/**
 * __useNordicCmsPanelQuery__
 *
 * To run a query within a React component, call `useNordicCmsPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useNordicCmsPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNordicCmsPanelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNordicCmsPanelQuery(
  baseOptions: Apollo.QueryHookOptions<NordicCmsPanelQuery, NordicCmsPanelQueryVariables> &
    ({ variables: NordicCmsPanelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NordicCmsPanelQuery, NordicCmsPanelQueryVariables>(NordicCmsPanelDocument, options);
}
export function useNordicCmsPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NordicCmsPanelQuery, NordicCmsPanelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NordicCmsPanelQuery, NordicCmsPanelQueryVariables>(NordicCmsPanelDocument, options);
}
export function useNordicCmsPanelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NordicCmsPanelQuery, NordicCmsPanelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NordicCmsPanelQuery, NordicCmsPanelQueryVariables>(NordicCmsPanelDocument, options);
}
export type NordicCmsPanelQueryHookResult = ReturnType<typeof useNordicCmsPanelQuery>;
export type NordicCmsPanelLazyQueryHookResult = ReturnType<typeof useNordicCmsPanelLazyQuery>;
export type NordicCmsPanelSuspenseQueryHookResult = ReturnType<typeof useNordicCmsPanelSuspenseQuery>;
export type NordicCmsPanelQueryResult = Apollo.QueryResult<NordicCmsPanelQuery, NordicCmsPanelQueryVariables>;
export const PaginatedFeedDocument = gql`
  query paginatedFeed($slug: String!, $page: Int, $limit: Int, $excludeVideoAssetList: [ID]) {
    paginatedNewsFeed(slug: $slug) {
      ...PaginatedNewsFeedFields
    }
  }
  ${PaginatedNewsFeedFieldsFragmentDoc}
`;

/**
 * __usePaginatedFeedQuery__
 *
 * To run a query within a React component, call `usePaginatedFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedFeedQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      excludeVideoAssetList: // value for 'excludeVideoAssetList'
 *   },
 * });
 */
export function usePaginatedFeedQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedFeedQuery, PaginatedFeedQueryVariables> &
    ({ variables: PaginatedFeedQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedFeedQuery, PaginatedFeedQueryVariables>(PaginatedFeedDocument, options);
}
export function usePaginatedFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedFeedQuery, PaginatedFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedFeedQuery, PaginatedFeedQueryVariables>(PaginatedFeedDocument, options);
}
export function usePaginatedFeedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PaginatedFeedQuery, PaginatedFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaginatedFeedQuery, PaginatedFeedQueryVariables>(PaginatedFeedDocument, options);
}
export type PaginatedFeedQueryHookResult = ReturnType<typeof usePaginatedFeedQuery>;
export type PaginatedFeedLazyQueryHookResult = ReturnType<typeof usePaginatedFeedLazyQuery>;
export type PaginatedFeedSuspenseQueryHookResult = ReturnType<typeof usePaginatedFeedSuspenseQuery>;
export type PaginatedFeedQueryResult = Apollo.QueryResult<PaginatedFeedQuery, PaginatedFeedQueryVariables>;
export const PaginatedNewsFeedByItemDocument = gql`
  query paginatedNewsFeedByItem($id: ID!, $type: FeedItemType!, $page: Int, $limit: Int, $excludeVideoAssetList: [ID]) {
    paginatedNewsFeedByItem(id: $id, type: $type) {
      item {
        ... on Article {
          ...ArticleFields
        }
        ... on VideoAsset {
          ...VideoAssetFields
        }
      }
      newsFeed {
        ...PaginatedNewsFeedFields
      }
      currentCategorySlug
    }
  }
  ${ArticleFieldsFragmentDoc}
  ${VideoAssetFieldsFragmentDoc}
  ${PaginatedNewsFeedFieldsFragmentDoc}
`;

/**
 * __usePaginatedNewsFeedByItemQuery__
 *
 * To run a query within a React component, call `usePaginatedNewsFeedByItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedNewsFeedByItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedNewsFeedByItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      excludeVideoAssetList: // value for 'excludeVideoAssetList'
 *   },
 * });
 */
export function usePaginatedNewsFeedByItemQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedNewsFeedByItemQuery, PaginatedNewsFeedByItemQueryVariables> &
    ({ variables: PaginatedNewsFeedByItemQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedNewsFeedByItemQuery, PaginatedNewsFeedByItemQueryVariables>(
    PaginatedNewsFeedByItemDocument,
    options
  );
}
export function usePaginatedNewsFeedByItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedNewsFeedByItemQuery, PaginatedNewsFeedByItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedNewsFeedByItemQuery, PaginatedNewsFeedByItemQueryVariables>(
    PaginatedNewsFeedByItemDocument,
    options
  );
}
export function usePaginatedNewsFeedByItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PaginatedNewsFeedByItemQuery, PaginatedNewsFeedByItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaginatedNewsFeedByItemQuery, PaginatedNewsFeedByItemQueryVariables>(
    PaginatedNewsFeedByItemDocument,
    options
  );
}
export type PaginatedNewsFeedByItemQueryHookResult = ReturnType<typeof usePaginatedNewsFeedByItemQuery>;
export type PaginatedNewsFeedByItemLazyQueryHookResult = ReturnType<typeof usePaginatedNewsFeedByItemLazyQuery>;
export type PaginatedNewsFeedByItemSuspenseQueryHookResult = ReturnType<typeof usePaginatedNewsFeedByItemSuspenseQuery>;
export type PaginatedNewsFeedByItemQueryResult = Apollo.QueryResult<
  PaginatedNewsFeedByItemQuery,
  PaginatedNewsFeedByItemQueryVariables
>;
export const ProgramFeedUrlDataDocument = gql`
  query programFeedUrlData {
    programFeedsListing {
      newsFeeds {
        id
        title
        slug
      }
    }
  }
`;

/**
 * __useProgramFeedUrlDataQuery__
 *
 * To run a query within a React component, call `useProgramFeedUrlDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramFeedUrlDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramFeedUrlDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgramFeedUrlDataQuery(
  baseOptions?: Apollo.QueryHookOptions<ProgramFeedUrlDataQuery, ProgramFeedUrlDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramFeedUrlDataQuery, ProgramFeedUrlDataQueryVariables>(
    ProgramFeedUrlDataDocument,
    options
  );
}
export function useProgramFeedUrlDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProgramFeedUrlDataQuery, ProgramFeedUrlDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramFeedUrlDataQuery, ProgramFeedUrlDataQueryVariables>(
    ProgramFeedUrlDataDocument,
    options
  );
}
export function useProgramFeedUrlDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProgramFeedUrlDataQuery, ProgramFeedUrlDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProgramFeedUrlDataQuery, ProgramFeedUrlDataQueryVariables>(
    ProgramFeedUrlDataDocument,
    options
  );
}
export type ProgramFeedUrlDataQueryHookResult = ReturnType<typeof useProgramFeedUrlDataQuery>;
export type ProgramFeedUrlDataLazyQueryHookResult = ReturnType<typeof useProgramFeedUrlDataLazyQuery>;
export type ProgramFeedUrlDataSuspenseQueryHookResult = ReturnType<typeof useProgramFeedUrlDataSuspenseQuery>;
export type ProgramFeedUrlDataQueryResult = Apollo.QueryResult<
  ProgramFeedUrlDataQuery,
  ProgramFeedUrlDataQueryVariables
>;
export const ProgramsPageDocument = gql`
  query programsPage {
    programFeedsListing {
      id
      title
      newsFeeds {
        id
        title
        slug
        main16x9Annotated
      }
    }
  }
`;

/**
 * __useProgramsPageQuery__
 *
 * To run a query within a React component, call `useProgramsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgramsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<ProgramsPageQuery, ProgramsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramsPageQuery, ProgramsPageQueryVariables>(ProgramsPageDocument, options);
}
export function useProgramsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProgramsPageQuery, ProgramsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramsPageQuery, ProgramsPageQueryVariables>(ProgramsPageDocument, options);
}
export function useProgramsPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProgramsPageQuery, ProgramsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProgramsPageQuery, ProgramsPageQueryVariables>(ProgramsPageDocument, options);
}
export type ProgramsPageQueryHookResult = ReturnType<typeof useProgramsPageQuery>;
export type ProgramsPageLazyQueryHookResult = ReturnType<typeof useProgramsPageLazyQuery>;
export type ProgramsPageSuspenseQueryHookResult = ReturnType<typeof useProgramsPageSuspenseQuery>;
export type ProgramsPageQueryResult = Apollo.QueryResult<ProgramsPageQuery, ProgramsPageQueryVariables>;
export const ArticlesByDateDocument = gql`
  query articlesByDate($date: String!) {
    articlesByDate(date: $date) {
      id
      slug
      title
      body
      raw
      feedOrigins {
        slug
        title
      }
      publishedDateTime
      __typename
    }
  }
`;

/**
 * __useArticlesByDateQuery__
 *
 * To run a query within a React component, call `useArticlesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useArticlesByDateQuery(
  baseOptions: Apollo.QueryHookOptions<ArticlesByDateQuery, ArticlesByDateQueryVariables> &
    ({ variables: ArticlesByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticlesByDateQuery, ArticlesByDateQueryVariables>(ArticlesByDateDocument, options);
}
export function useArticlesByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArticlesByDateQuery, ArticlesByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticlesByDateQuery, ArticlesByDateQueryVariables>(ArticlesByDateDocument, options);
}
export function useArticlesByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ArticlesByDateQuery, ArticlesByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ArticlesByDateQuery, ArticlesByDateQueryVariables>(ArticlesByDateDocument, options);
}
export type ArticlesByDateQueryHookResult = ReturnType<typeof useArticlesByDateQuery>;
export type ArticlesByDateLazyQueryHookResult = ReturnType<typeof useArticlesByDateLazyQuery>;
export type ArticlesByDateSuspenseQueryHookResult = ReturnType<typeof useArticlesByDateSuspenseQuery>;
export type ArticlesByDateQueryResult = Apollo.QueryResult<ArticlesByDateQuery, ArticlesByDateQueryVariables>;
export const RssPaginatedNewsFeedDocument = gql`
  query RSSPaginatedNewsFeed($slug: String!, $page: Int!, $limit: Int!) {
    paginatedNewsFeed(slug: $slug) {
      title
      feed(page: $page, limit: $limit) {
        __typename
        items {
          ... on Article {
            id
            slug
            title
            body
            raw
            feedOrigins {
              slug
              title
            }
            publishedDateTime
            __typename
          }
          ... on VideoAsset {
            id
            slug
            title
            description
            publishedDateTime
            __typename
          }
        }
      }
    }
  }
`;

/**
 * __useRssPaginatedNewsFeedQuery__
 *
 * To run a query within a React component, call `useRssPaginatedNewsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRssPaginatedNewsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRssPaginatedNewsFeedQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRssPaginatedNewsFeedQuery(
  baseOptions: Apollo.QueryHookOptions<RssPaginatedNewsFeedQuery, RssPaginatedNewsFeedQueryVariables> &
    ({ variables: RssPaginatedNewsFeedQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RssPaginatedNewsFeedQuery, RssPaginatedNewsFeedQueryVariables>(
    RssPaginatedNewsFeedDocument,
    options
  );
}
export function useRssPaginatedNewsFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RssPaginatedNewsFeedQuery, RssPaginatedNewsFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RssPaginatedNewsFeedQuery, RssPaginatedNewsFeedQueryVariables>(
    RssPaginatedNewsFeedDocument,
    options
  );
}
export function useRssPaginatedNewsFeedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RssPaginatedNewsFeedQuery, RssPaginatedNewsFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RssPaginatedNewsFeedQuery, RssPaginatedNewsFeedQueryVariables>(
    RssPaginatedNewsFeedDocument,
    options
  );
}
export type RssPaginatedNewsFeedQueryHookResult = ReturnType<typeof useRssPaginatedNewsFeedQuery>;
export type RssPaginatedNewsFeedLazyQueryHookResult = ReturnType<typeof useRssPaginatedNewsFeedLazyQuery>;
export type RssPaginatedNewsFeedSuspenseQueryHookResult = ReturnType<typeof useRssPaginatedNewsFeedSuspenseQuery>;
export type RssPaginatedNewsFeedQueryResult = Apollo.QueryResult<
  RssPaginatedNewsFeedQuery,
  RssPaginatedNewsFeedQueryVariables
>;
export const RssEmphasizedArticlesDocument = gql`
  query RSSEmphasizedArticles {
    paginatedNewsFeed(slug: "/") {
      title
      emphasizedArticleGroup {
        content {
          id
          isEmphasized
          content {
            __typename
            ... on ArticleGroupArticle {
              ...ArticleParts
            }
            ... on ArticleGroupBlock {
              content {
                __typename
                ... on ArticleGroupArticle {
                  ...ArticleParts
                }
              }
            }
          }
        }
      }
    }
  }
  ${ArticlePartsFragmentDoc}
`;

/**
 * __useRssEmphasizedArticlesQuery__
 *
 * To run a query within a React component, call `useRssEmphasizedArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRssEmphasizedArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRssEmphasizedArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRssEmphasizedArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<RssEmphasizedArticlesQuery, RssEmphasizedArticlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RssEmphasizedArticlesQuery, RssEmphasizedArticlesQueryVariables>(
    RssEmphasizedArticlesDocument,
    options
  );
}
export function useRssEmphasizedArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RssEmphasizedArticlesQuery, RssEmphasizedArticlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RssEmphasizedArticlesQuery, RssEmphasizedArticlesQueryVariables>(
    RssEmphasizedArticlesDocument,
    options
  );
}
export function useRssEmphasizedArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RssEmphasizedArticlesQuery, RssEmphasizedArticlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RssEmphasizedArticlesQuery, RssEmphasizedArticlesQueryVariables>(
    RssEmphasizedArticlesDocument,
    options
  );
}
export type RssEmphasizedArticlesQueryHookResult = ReturnType<typeof useRssEmphasizedArticlesQuery>;
export type RssEmphasizedArticlesLazyQueryHookResult = ReturnType<typeof useRssEmphasizedArticlesLazyQuery>;
export type RssEmphasizedArticlesSuspenseQueryHookResult = ReturnType<typeof useRssEmphasizedArticlesSuspenseQuery>;
export type RssEmphasizedArticlesQueryResult = Apollo.QueryResult<
  RssEmphasizedArticlesQuery,
  RssEmphasizedArticlesQueryVariables
>;
export const ScheduleDocument = gql`
  query schedule($date: String!) {
    schedule(date: $date) {
      ...ScheduleFields
    }
  }
  ${ScheduleFieldsFragmentDoc}
`;

/**
 * __useScheduleQuery__
 *
 * To run a query within a React component, call `useScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<ScheduleQuery, ScheduleQueryVariables> &
    ({ variables: ScheduleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleQuery, ScheduleQueryVariables>(ScheduleDocument, options);
}
export function useScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleQuery, ScheduleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleQuery, ScheduleQueryVariables>(ScheduleDocument, options);
}
export function useScheduleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ScheduleQuery, ScheduleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScheduleQuery, ScheduleQueryVariables>(ScheduleDocument, options);
}
export type ScheduleQueryHookResult = ReturnType<typeof useScheduleQuery>;
export type ScheduleLazyQueryHookResult = ReturnType<typeof useScheduleLazyQuery>;
export type ScheduleSuspenseQueryHookResult = ReturnType<typeof useScheduleSuspenseQuery>;
export type ScheduleQueryResult = Apollo.QueryResult<ScheduleQuery, ScheduleQueryVariables>;
export const ServiceMessageDocument = gql`
  query ServiceMessage($platform: PlatformParameter!) {
    serviceMessages(platform: $platform) {
      id
      title
      message
    }
  }
`;

/**
 * __useServiceMessageQuery__
 *
 * To run a query within a React component, call `useServiceMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceMessageQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useServiceMessageQuery(
  baseOptions: Apollo.QueryHookOptions<ServiceMessageQuery, ServiceMessageQueryVariables> &
    ({ variables: ServiceMessageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceMessageQuery, ServiceMessageQueryVariables>(ServiceMessageDocument, options);
}
export function useServiceMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServiceMessageQuery, ServiceMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceMessageQuery, ServiceMessageQueryVariables>(ServiceMessageDocument, options);
}
export function useServiceMessageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ServiceMessageQuery, ServiceMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ServiceMessageQuery, ServiceMessageQueryVariables>(ServiceMessageDocument, options);
}
export type ServiceMessageQueryHookResult = ReturnType<typeof useServiceMessageQuery>;
export type ServiceMessageLazyQueryHookResult = ReturnType<typeof useServiceMessageLazyQuery>;
export type ServiceMessageSuspenseQueryHookResult = ReturnType<typeof useServiceMessageSuspenseQuery>;
export type ServiceMessageQueryResult = Apollo.QueryResult<ServiceMessageQuery, ServiceMessageQueryVariables>;
export const ShortsPlaylistDocument = gql`
  query shortsPlaylist($playlistId: ID!, $selectedVideoId: ID!) {
    shortsPlaylist(playlistId: $playlistId, selectedVideoId: $selectedVideoId) {
      __typename
      content {
        id
        title
        metadataId
        thumbnail
        vimondId
        publishedAt
        duration
        relativePublishDate
      }
    }
  }
`;

/**
 * __useShortsPlaylistQuery__
 *
 * To run a query within a React component, call `useShortsPlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortsPlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortsPlaylistQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      selectedVideoId: // value for 'selectedVideoId'
 *   },
 * });
 */
export function useShortsPlaylistQuery(
  baseOptions: Apollo.QueryHookOptions<ShortsPlaylistQuery, ShortsPlaylistQueryVariables> &
    ({ variables: ShortsPlaylistQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShortsPlaylistQuery, ShortsPlaylistQueryVariables>(ShortsPlaylistDocument, options);
}
export function useShortsPlaylistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShortsPlaylistQuery, ShortsPlaylistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShortsPlaylistQuery, ShortsPlaylistQueryVariables>(ShortsPlaylistDocument, options);
}
export function useShortsPlaylistSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ShortsPlaylistQuery, ShortsPlaylistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ShortsPlaylistQuery, ShortsPlaylistQueryVariables>(ShortsPlaylistDocument, options);
}
export type ShortsPlaylistQueryHookResult = ReturnType<typeof useShortsPlaylistQuery>;
export type ShortsPlaylistLazyQueryHookResult = ReturnType<typeof useShortsPlaylistLazyQuery>;
export type ShortsPlaylistSuspenseQueryHookResult = ReturnType<typeof useShortsPlaylistSuspenseQuery>;
export type ShortsPlaylistQueryResult = Apollo.QueryResult<ShortsPlaylistQuery, ShortsPlaylistQueryVariables>;
export const SportResultsByLeagueDocument = gql`
  query sportResultsByLeague(
    $leagueId: String!
    $offset: Int!
    $limit: Int!
    $resultsFilter: [ResultsFilter]
    $sortDateOrder: SortOrder
  ) {
    sportResultsByLeague(
      leagueId: $leagueId
      offset: $offset
      limit: $limit
      resultsFilter: $resultsFilter
      sortDateOrder: $sortDateOrder
    ) @connection(key: "sportResultsByLeague", filter: ["leagueId", "resultsFilter", "sortDateOrder"]) {
      ... on FootballResults {
        __typename
        results {
          ...FootballResultFields
        }
        pagination {
          totalCount
          hasNextPage
          currentPage
          totalPages
          __typename
        }
      }
      ... on IceHockeyResults {
        __typename
        results {
          ...IceHockeyResultFields
        }
        pagination {
          totalCount
          hasNextPage
          currentPage
          totalPages
          __typename
        }
      }
    }
  }
  ${FootballResultFieldsFragmentDoc}
  ${IceHockeyResultFieldsFragmentDoc}
`;

/**
 * __useSportResultsByLeagueQuery__
 *
 * To run a query within a React component, call `useSportResultsByLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportResultsByLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportResultsByLeagueQuery({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      resultsFilter: // value for 'resultsFilter'
 *      sortDateOrder: // value for 'sortDateOrder'
 *   },
 * });
 */
export function useSportResultsByLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<SportResultsByLeagueQuery, SportResultsByLeagueQueryVariables> &
    ({ variables: SportResultsByLeagueQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SportResultsByLeagueQuery, SportResultsByLeagueQueryVariables>(
    SportResultsByLeagueDocument,
    options
  );
}
export function useSportResultsByLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportResultsByLeagueQuery, SportResultsByLeagueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SportResultsByLeagueQuery, SportResultsByLeagueQueryVariables>(
    SportResultsByLeagueDocument,
    options
  );
}
export function useSportResultsByLeagueSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SportResultsByLeagueQuery, SportResultsByLeagueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SportResultsByLeagueQuery, SportResultsByLeagueQueryVariables>(
    SportResultsByLeagueDocument,
    options
  );
}
export type SportResultsByLeagueQueryHookResult = ReturnType<typeof useSportResultsByLeagueQuery>;
export type SportResultsByLeagueLazyQueryHookResult = ReturnType<typeof useSportResultsByLeagueLazyQuery>;
export type SportResultsByLeagueSuspenseQueryHookResult = ReturnType<typeof useSportResultsByLeagueSuspenseQuery>;
export type SportResultsByLeagueQueryResult = Apollo.QueryResult<
  SportResultsByLeagueQuery,
  SportResultsByLeagueQueryVariables
>;
export const SportStandingByLeagueDocument = gql`
  query sportStandingByLeague($league: League!, $gameLocationFilter: [GameLocation]) {
    sportStandingByLeague(league: $league, gameLocationFilter: $gameLocationFilter) {
      __typename
      name
      lines {
        line {
          start
          end
          name {
            lang
            value
          }
          __typename
        }
        __typename
      }
      participant {
        id
        name {
          lang
          value
        }
        displayName
        result {
          id
          wot
          wp
          lot
          lp
          gamesplayed
          win
          lost
          scoreteam
          goalsconceded
          goaldifference
          points
          name {
            lang
            value
          }
          __typename
        }
        __typename
      }
      primary {
        id
        name {
          lang
          value
        }
        __typename
      }
      sport {
        id
        name {
          lang
          value
        }
        __typename
      }
    }
  }
`;

/**
 * __useSportStandingByLeagueQuery__
 *
 * To run a query within a React component, call `useSportStandingByLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportStandingByLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportStandingByLeagueQuery({
 *   variables: {
 *      league: // value for 'league'
 *      gameLocationFilter: // value for 'gameLocationFilter'
 *   },
 * });
 */
export function useSportStandingByLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<SportStandingByLeagueQuery, SportStandingByLeagueQueryVariables> &
    ({ variables: SportStandingByLeagueQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SportStandingByLeagueQuery, SportStandingByLeagueQueryVariables>(
    SportStandingByLeagueDocument,
    options
  );
}
export function useSportStandingByLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportStandingByLeagueQuery, SportStandingByLeagueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SportStandingByLeagueQuery, SportStandingByLeagueQueryVariables>(
    SportStandingByLeagueDocument,
    options
  );
}
export function useSportStandingByLeagueSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SportStandingByLeagueQuery, SportStandingByLeagueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SportStandingByLeagueQuery, SportStandingByLeagueQueryVariables>(
    SportStandingByLeagueDocument,
    options
  );
}
export type SportStandingByLeagueQueryHookResult = ReturnType<typeof useSportStandingByLeagueQuery>;
export type SportStandingByLeagueLazyQueryHookResult = ReturnType<typeof useSportStandingByLeagueLazyQuery>;
export type SportStandingByLeagueSuspenseQueryHookResult = ReturnType<typeof useSportStandingByLeagueSuspenseQuery>;
export type SportStandingByLeagueQueryResult = Apollo.QueryResult<
  SportStandingByLeagueQuery,
  SportStandingByLeagueQueryVariables
>;
export const VideoUrlDataByDateDocument = gql`
  query videoUrlDataByDate($from: String!, $to: String!, $limit: Int, $skip: Int) {
    videoUrlDataByDate(from: $from, to: $to, limit: $limit, skip: $skip) {
      total
      items {
        ... on VideoUrlData {
          ...VideoUrlDataFields
        }
      }
    }
  }
  ${VideoUrlDataFieldsFragmentDoc}
`;

/**
 * __useVideoUrlDataByDateQuery__
 *
 * To run a query within a React component, call `useVideoUrlDataByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoUrlDataByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoUrlDataByDateQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useVideoUrlDataByDateQuery(
  baseOptions: Apollo.QueryHookOptions<VideoUrlDataByDateQuery, VideoUrlDataByDateQueryVariables> &
    ({ variables: VideoUrlDataByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VideoUrlDataByDateQuery, VideoUrlDataByDateQueryVariables>(
    VideoUrlDataByDateDocument,
    options
  );
}
export function useVideoUrlDataByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VideoUrlDataByDateQuery, VideoUrlDataByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VideoUrlDataByDateQuery, VideoUrlDataByDateQueryVariables>(
    VideoUrlDataByDateDocument,
    options
  );
}
export function useVideoUrlDataByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<VideoUrlDataByDateQuery, VideoUrlDataByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VideoUrlDataByDateQuery, VideoUrlDataByDateQueryVariables>(
    VideoUrlDataByDateDocument,
    options
  );
}
export type VideoUrlDataByDateQueryHookResult = ReturnType<typeof useVideoUrlDataByDateQuery>;
export type VideoUrlDataByDateLazyQueryHookResult = ReturnType<typeof useVideoUrlDataByDateLazyQuery>;
export type VideoUrlDataByDateSuspenseQueryHookResult = ReturnType<typeof useVideoUrlDataByDateSuspenseQuery>;
export type VideoUrlDataByDateQueryResult = Apollo.QueryResult<
  VideoUrlDataByDateQuery,
  VideoUrlDataByDateQueryVariables
>;
