import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

type PlayIconFilledType = SizeAndColor & {
  className?: string;
};

export const PlayIconFilled = ({ className, size = 24, color = 'currentColor' }: PlayIconFilledType) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="play-icon-filled"
  >
    <path
      d="M6 4.86852C6 4.06982 6.89015 3.59343 7.5547 4.03647L18.2519 11.168C18.8457 11.5638 18.8457 12.4362 18.2519 12.8321L7.5547 19.9635C6.89015 20.4066 6 19.9302 6 19.1315V4.86852Z"
      fill={color}
    />
  </svg>
);
