import { type SizeAndColor, cssSize } from './iconUtils';

export const MuteIcon = ({ size = 24, color = 'currentColor' }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="mute">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8609 16.5842C13.1497 16.4455 13.3333 16.1535 13.3333 15.8332V13.7498H11.6666V14.0993L8.85387 11.8491C8.70611 11.7309 8.52252 11.6665 8.33329 11.6665H5.83329V7.49983H4.16663V12.4998C4.16663 12.9601 4.53972 13.3332 4.99996 13.3332H8.04098L11.9794 16.4839C12.2295 16.684 12.5722 16.723 12.8609 16.5842ZM17.4219 14.0418L13.3333 10.7395V4.1665C13.3333 3.84785 13.1516 3.5571 12.8652 3.41745C12.5787 3.2778 12.2378 3.31372 11.9867 3.50997L8.12251 6.53082L5.29196 4.2446L4.24473 5.54117L16.3746 15.3384L17.4219 14.0418ZM9.45348 7.60583L11.6666 5.87571V9.39337L9.45348 7.60583Z"
        fill={color}
      />
    </g>
  </svg>
);
