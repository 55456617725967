import { type SizeAndColor, cssSize } from './iconUtils';

type PauseIconFilledType = SizeAndColor & {
  className?: string;
};

export const PauseIconFilled = ({ className, size = 24, color = 'currentColor' }: PauseIconFilledType) => (
  <svg
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    style={cssSize(size)}
    className={className}
  >
    <g id="pause-solid">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 14.6667C18.3333 12.6416 19.9749 11 22 11H36.6666C38.6917 11 40.3333 12.6416 40.3333 14.6667V73.3333C40.3333 75.3584 38.6917 77 36.6666 77H22C19.9749 77 18.3333 75.3584 18.3333 73.3333V14.6667ZM47.6666 14.6667C47.6666 12.6416 49.3083 11 51.3333 11H66C68.025 11 69.6666 12.6416 69.6666 14.6667V73.3333C69.6666 75.3584 68.025 77 66 77H51.3333C49.3083 77 47.6666 75.3584 47.6666 73.3333V14.6667Z"
        fill={color}
      />
    </g>
  </svg>
);
